import _ from 'underscore';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import { history } from 'shared/routes';
import { getPriceInUserCurrency, getCurrencySymbol, getRetailMarkup } from 'shared/utilities';
import Layout from 'shared/components/Layout';
import Diamond from 'shared/components/Diamond';
import ResultWell from 'shared/components/ResultWell';

import { isMobile, isTablet } from 'shared/utilities';


class DiamondCalculatorLayout extends Layout {
	constructor(props) {
		super(props);

		this.inputButtons = [];
		this.diamondPropInputs = {};

		this.state = {
			// Track the weight both as a string in the component and as a number in the store
			// This allows for inputting decimal points
			weightString: '',
			showRetailPrice: false,
			valueMaxHeight: 'none'
		};

		this.headerHeight = 142;
		this.footerHeight = 42;
		this.previewHeight = 70;

		this.setDiamondPricingTable = this.setDiamondPricingTable.bind(this);
	}

	setState(nextState) {
		if (nextState.weightString) {
			// `weightString` can only be a string
			nextState.weightString = String(nextState.weightString);

			// If there are multiple decmal points, don't make any change
			if (nextState.weightString.match(/.*\..*\./)) return false;
		}

		return super.setState.apply(this, arguments);
	}

	setDiamondPricingTable() {
		this.props.dispatch({
			type: 'SET_PRICING_STATE',
			props: {
				...this.props.pricing,
				presetSelection: true,
				presetActive: true,
				selection: { shape: this.props.diamond.shape },
				section: 'PricingTable',
				listType: 'Diamonds'
			}
		});

		history.push('/pricing');
	}

	setMaxHeights() {
		const winWidth = window.innerWidth;

		if (winWidth >= 752 && winWidth < 1025) {
			const valueMaxHeight = ((window.innerHeight - (this.buttonGrid.clientHeight + this.headerHeight)) / 2) + 1;
			this.setState({ valueMaxHeight })
		} else if (winWidth < 752) {
			const valueMaxHeight = ((window.innerHeight - (this.buttonGrid.clientHeight + this.footerHeight + this.previewHeight)) / 2) + 1;
			this.setState({ valueMaxHeight })
		}
	}

	componentDidResize() {
		this.props.dispatch && this.props.dispatch({
			type: 'SET_MOBILE',
			isMobile: isMobile(),
			isTablet: isTablet()
		});

		this.setMaxHeights();
	}

	componentDidInitialize() {
		super.componentDidInitialize.apply(this, arguments);

		this.setMaxHeights();

		// Reset the fields to defaults
		this.isResetting = true;
		this.props.dispatch({ type: 'RESET_DIAMOND_PROPS' });

		!this.props.display.isMobile && this.weightInput.focus();
	}

	shouldComponentUpdate(nextProps, nextState) {
		// Automatically sync the store weight number when the local weightString changes
		if (this.state.weightString !== nextState.weightString) {
			this.props.dispatch({
				type: 'SET_DIAMOND_PROPS',
				props: { weight: nextState.weightString }
			});

			return false;
		}

		// If the layout is initializing, reset the fields with the default state
		if (this.isResetting) {
			_.each(this.diamondPropInputs, (input, propName) =>
				input.value = nextProps.diamond[propName]
			);

			this.isResetting = false;
		}

		return true;
	}

	// Get a signature for comparing different diamond states that will potentially produce different prices
	getPriceInfluencerSignature(diamondInfo) {
		diamondInfo = diamondInfo || this.props.diamond;

		return JSON.stringify(Diamond.priceInfluencers.map(key => diamondInfo[key]));
	}

	componentWillReceiveProps(nextProps) {
		// Re-fetch the price if anything that affects the price changes
		const priceInfluencerSignature = this.getPriceInfluencerSignature();
		const nextPriceInfluencerSignature = this.getPriceInfluencerSignature(nextProps.diamond);

		if (priceInfluencerSignature !== nextPriceInfluencerSignature) {
			this.fetchPrice(nextProps.diamond);
		}
	}

	fetchPrice(diamondInfo) {
		if (!diamondInfo.weight) {
			this.props.dispatch({
				type: 'SET_DIAMOND_PROPS',
				props: {
					price: null
				}
			});

			return;
		}

		this.props.dispatch({ type: 'FETCH_DIAMOND_PRICE' });

		// If the request takes less than `minRequestDuration`, then artificially delay
		// display of the result so there isn't a flashy/jerky effect
		const requestTime = new Date();
		const minRequestDuration = 1000; // in milliseconds

		new Diamond().getPrice(diamondInfo).then(
			price => {
				const requestDuration = moment(requestTime).diff(new Date());
				const timeToWait = minRequestDuration - requestDuration;
				const doDispatchPrice = () => {
					// If the diamond query hasn't updated while the request was
					// being processed (eg: quickly typing in the weight input on desktop)
					if (
						this.getPriceInfluencerSignature() === this.getPriceInfluencerSignature(diamondInfo) ||
						!this.props.diamond.weight
					) {
						this.props.dispatch({
							type: 'FETCH_DIAMOND_PRICE',
							status: 'success',
							price: this.props.diamond.weight ? price : null
						});
					}
				};

				// If `timeToWait` is negative then `doDispatchPrice` will execute immediately
				setTimeout(doDispatchPrice, timeToWait);
			}
		).catch(
			priceError => this.props.dispatch({
				type: 'FETCH_DIAMOND_PRICE',
				status: 'error',
				priceError
			})
		);
	}

	getPricePerCarat() {
		const {
			diamond,
			pricing,
			loggedInUser,
			labels
		} = this.props;

		const priceInUserCurrency = getPriceInUserCurrency(diamond.price, pricing, loggedInUser);

		return priceInUserCurrency / this.props.diamond.weight || 0;
	}

	displayLayoutStateBar() {
		const {
			diamond,
			pricing,
			loggedInUser,
			labels
		} = this.props;

		let priceInUserCurrency, currencySymbol, retailMarkup;

		if (diamond.price) {
			priceInUserCurrency = getPriceInUserCurrency(diamond.price, pricing, loggedInUser);
			currencySymbol = getCurrencySymbol(pricing, loggedInUser);
			retailMarkup = getRetailMarkup(loggedInUser, 'diamonds', diamond.price);
		}

		const PriceResultWell = props => (
			<ResultWell
				valuePrefix={currencySymbol}
				isLoading={this.props.diamond.isFetchingPrice}
				isClickable={retailMarkup !== 1}
				onClick={() => retailMarkup ? this.setState({ showRetailPrice: !this.state.showRetailPrice }) : null}
				{...props}
			/>
		);

		return (
			<div className="layout-stateBar-container">
				<div className="diamondCalculator-weight">
					<i className="fa fa-diamond" />
					<input
						type="text"
						className="diamondCalculator-weight-input"
						ref={weightInput => this.weightInput = weightInput}
						placeholder={diamond.shape === 0 ? labels.lengthPlaceholder : labels.weightPlaceholder}
						defaultValue={null}
						onKeyUp={
							event => {
								this.weightInput.value = (
									// If the field input is blank or ends in a decimal then don't convert it to a number
									this.weightInput.value === '' ||
									this.weightInput.value.match(/\d*\.0*$/)
								) ? (
									this.weightInput.value
								) : (
									Number(this.weightInput.value) || 0
								)

								this.props.dispatch({
									type: 'SET_DIAMOND_PROPS',
									props: {
										weight: this.weightInput.value
									}
								});
							}
						}
					/>
				</div>
				<div className="diamondCalculator-prices">
					<PriceResultWell
						label={this.state.showRetailPrice ? labels.pricePerCaratRetail : labels.pricePerCarat}
						value={
							_.isNumber(diamond.price) && diamond.price > 0 ? (
								parseInt(this.getPricePerCarat() * (this.state.showRetailPrice ? retailMarkup : 1))
							) : (
								null
							)
						}
					/>
					<PriceResultWell
						label={this.state.showRetailPrice ? labels.stoneRetail : labels.stone}
						value={
							diamond.shape === 0 ? null :
								_.isNumber(diamond.price) && diamond.price > 0 ? (
									parseInt(priceInUserCurrency * (this.state.showRetailPrice ? retailMarkup : 1))
								) : (
									null
								)
						}
					/>
				</div>
			</div>
		);
	}

	displayLayout() {
		let defaultOption;

		return (
			<div>
				<div className="diamondCalculator-valueInputs" style={{ maxHeight: '100%' }}>
					<div className="row">
						<div className="small-12 medium-6 large-4 columns small-centered">
							<label>
								<div
									className="diamondCalculator-valueInput-label"
									children={this.props.labels.shape}
								/>
								<select
									ref={shape => this.diamondPropInputs.shape = shape}
									className="diamondCalculator-valueInput diamondCalculator-shapes"
									defaultValue={this.props.diamond.shape}
									onChange={
										event => this.props.dispatch({
											type: 'SET_DIAMOND_PROPS',
											props: { shape: event.target.value }
										})
									}
									children={
										Diamond.shapes.map(shape =>
											<option
												children={shape.label}
												value={shape.id}
												disabled={shape.isTitle}
											/>
										)
									}
								/>
							</label>
						</div>
					</div>
					<div className="row">
						<div className="small-12 medium-6 large-4 columns small-centered">
							<label>
								<div
									className="diamondCalculator-valueInput-label"
									children={this.props.labels.color}
								/>
								<select
									ref={color => this.diamondPropInputs.color = color}
									className="diamondCalculator-valueInput diamondCalculator-colors"
									defaultValue={this.props.diamond.color}
									onChange={
										event => this.props.dispatch({
											type: 'SET_DIAMOND_PROPS',
											props: { color: event.target.value }
										})
									}
									children={
										Diamond.colors.map(color =>
											<option
												children={color.label}
												value={color.id}
											/>
										)
									}
								/>
							</label>
						</div>
					</div>
					<div className="row">
						<div className="small-12 medium-6 large-4 columns small-centered">
							<label>
								<div
									className="diamondCalculator-valueInput-label"
									children={this.props.labels.clarity}
								/>
								<select
									ref={clarity => this.diamondPropInputs.clarity = clarity}
									className="diamondCalculator-valueInput diamondCalculator-clarities"
									defaultValue={this.props.diamond.clarity}
									onChange={
										event => this.props.dispatch({
											type: 'SET_DIAMOND_PROPS',
											props: { clarity: event.target.value }
										})
									}
									children={
										Diamond.clarities.map(clarity =>
											<option
												children={clarity.label}
												value={clarity.id}
											/>
										)
									}
								/>
							</label>
						</div>
					</div>
					<div className="row">
						<div className="small-12 medium-6 large-4 columns small-centered">
							<label>
								<div
									className="diamondCalculator-valueInput-label"
									children={this.props.labels.cutQuality}
								/>
								<select
									ref={cutQuality => this.diamondPropInputs.cutQuality = cutQuality}
									className="diamondCalculator-valueInput diamondCalculator-cutQualities"
									defaultValue={this.props.diamond.cutQuality}
									onChange={
										event => this.props.dispatch({
											type: 'SET_DIAMOND_PROPS',
											props: { cutQuality: event.target.value }
										})
									}
									children={
										Diamond.cutQualities.map(cutQuality =>
											<option
												children={cutQuality.label}
												value={cutQuality.id}
											/>
										)
									}
								/>
							</label>
						</div>
					</div>
					<div className="row">
						<div className="small-12 medium-6 large-4 columns small-centered">
							<label>
								<div
									className="diamondCalculator-valueInput-label diamondCalculator-valueInput-label--fluorescence"
									dangerouslySetInnerHTML={{ __html: this.props.labels.fluorescence }}
								/>
								<select
									ref={fluorescence => this.diamondPropInputs.fluorescence = fluorescence}
									className="diamondCalculator-valueInput diamondCalculator-fluorescences"
									defaultValue={this.props.diamond.fluorescence}
									onChange={
										event => this.props.dispatch({
											type: 'SET_DIAMOND_PROPS',
											props: { fluorescence: event.target.value }
										})
									}
									children={
										Diamond.fluorescences.map(fluorescence =>
											<option
												children={fluorescence.label}
												value={fluorescence.id}
											/>
										)
									}
								/>
							</label>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="small-12 columns">
						<div className="diamondCalculator-actions">
							<span
								className="diamondCalculator-action"
								children={this.props.labels.table}
								onClick={this.setDiamondPricingTable}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	displayMobileLayout() {
		const {
			diamond,
			pricing,
			loggedInUser,
			labels
		} = this.props;

		let priceInUserCurrency, currencySymbol, retailMarkup;

		if (diamond.price) {
			priceInUserCurrency = getPriceInUserCurrency(diamond.price, pricing, loggedInUser);
			currencySymbol = getCurrencySymbol(pricing, loggedInUser);
			retailMarkup = getRetailMarkup(loggedInUser, 'diamonds', diamond.price);
		}

		const PriceResultWell = props => (
			<ResultWell
				valuePrefix={currencySymbol}
				isLoading={this.props.diamond.isFetchingPrice}
				isClickable={retailMarkup !== 1}
				onClick={() => retailMarkup ? this.setState({ showRetailPrice: !this.state.showRetailPrice }) : null}
				loadingIndicatorProps={{
					segmentLength: 1,
					segmentWidth: 2,
					segments: 12,
					spacing: 1.5,
					color: {
						alpha: 0.8,
						blue: 128,
						green: 0,
						red: 128
					}
				}}
				{...props}
			/>
		);

		return (
			<div className="diamondCalculator-wrapper">
				<div className="diamondCalculator-results">
					<div className="diamondCalculator-weightDisplay" >
						<i className="fa fa-diamond" />
						{diamond.weight > 0 ? diamond.weight : diamond.shape === 0 ? labels.lengthPlaceholder : labels.weightPlaceholder}
					</div>
					<div className="diamondCalculator-prices">
						<PriceResultWell
							label={this.state.showRetailPrice ? labels.pricePerCaratRetail : labels.pricePerCarat}
							value={
								_.isNumber(diamond.price) && diamond.price > 0 ? (
									parseInt(this.getPricePerCarat() * (this.state.showRetailPrice ? retailMarkup : 1))
								) : (
									null
								)
							}
						/>
						<PriceResultWell
							label={this.state.showRetailPrice ? labels.stoneRetail : labels.stone}
							value={
								diamond.shape === 0 ? null :
									_.isNumber(diamond.price) && diamond.price > 0 ? (
										parseInt(priceInUserCurrency * (this.state.showRetailPrice ? retailMarkup : 1))
									) : (
										null
									)
							}
						/>
					</div>
				</div>
				<div className="diamondCalculator-valueInputs" style={{ maxHeight: this.state.valueMaxHeight }}>
					<div
						className="diamondCalculator-valueInput diamondCalculator-shapes"
						data-label={this.props.labels.shape}
						children={
							<div
								className="diamondCalculator-valueInput-wrapper"
								children={
									Diamond.shapes.map(shape =>
										<div
											style={{ pointerEvents: shape.id === 20 || shape.id === 21 ? 'none' : 'auto', textTransform: shape.id === 20 || shape.id === 21 ? "uppercase" : "", fontSize: shape.id === 20 || shape.id === 21 ? '11px' : '', fontWeight: shape.id === 20 || shape.id === 21 ? 'bolder' : '' }}
											children={shape.label}
											className={`
												diamondCalculator-shape
												${this.props.diamond.shape == shape.id ? 'active' : 'notActive'}
											`}
											onClick={
												() => this.props.dispatch({
													type: 'SET_DIAMOND_PROPS',
													props: { shape: shape.id }
												})
											}
										/>
									)
								}
							/>
						}
					/>
					<div
						className="diamondCalculator-valueInput diamondCalculator-colors"
						data-label={this.props.labels.color}
						children={
							<div
								className="diamondCalculator-valueInput-wrapper"
								children={
									Diamond.colors.map(color =>
										<div
											children={color.label}
											className={`
												diamondCalculator-color
												${this.props.diamond.color == color.id ? 'active' : 'notActive'}
											`}
											onClick={
												() => this.props.dispatch({
													type: 'SET_DIAMOND_PROPS',
													props: { color: color.id }
												})
											}
										/>
									)
								}
							/>
						}
					/>
					<div
						className="diamondCalculator-valueInput diamondCalculator-clarities"
						data-label={this.props.labels.clarity}
						children={
							<div
								className="diamondCalculator-valueInput-wrapper"
								children={
									Diamond.clarities.map(clarity =>
										<div
											children={clarity.label}
											className={`
												diamondCalculator-clarity
												${this.props.diamond.clarity == clarity.id ? 'active' : 'notActive'}
											`}
											onClick={
												() => this.props.dispatch({
													type: 'SET_DIAMOND_PROPS',
													props: { clarity: clarity.id }
												})
											}
										/>
									)
								}
							/>
						}
					/>
				</div>
				<div className="diamondCalculator-valueInputs" style={{ maxHeight: this.state.valueMaxHeight }}>
					<div
						className="diamondCalculator-valueInput diamondCalculator-cutQualities"
						data-label={this.props.labels.cutQuality}
						children={
							<div
								className="diamondCalculator-valueInput-wrapper"
								children={
									Diamond.cutQualities.map(cutQuality =>
										<div
											children={cutQuality.label}
											className={`
												diamondCalculator-cutQuality
												${this.props.diamond.cutQuality == cutQuality.id ? 'active' : 'notActive'}
											`}
											onClick={
												() => this.props.dispatch({
													type: 'SET_DIAMOND_PROPS',
													props: { cutQuality: cutQuality.id }
												})
											}
										/>
									)
								}
							/>
						}
					/>
					<div
						className="diamondCalculator-valueInput diamondCalculator-fluorescences"
						data-label={this.props.labels.fluorescence}
						children={
							<div
								className="diamondCalculator-valueInput-wrapper"
								children={
									Diamond.fluorescences.map(fluorescence =>
										<div
											children={fluorescence.label}
											className={`
												diamondCalculator-fluorescence
												${this.props.diamond.fluorescence == fluorescence.id ? 'active' : 'notActive'}
											`}
											onClick={
												() => this.props.dispatch({
													type: 'SET_DIAMOND_PROPS',
													props: { fluorescence: fluorescence.id }
												})
											}
										/>
									)
								}
							/>
						}
					/>
				</div>
				<div className="diamondCalculator-buttonGrid"
					ref={buttonGrid => this.buttonGrid = buttonGrid}>
					<div
						className="diamondCalculator-weightInputs"
						children={
							this.constructor.inputButtons.map(
								inputButton => (
									<div
										children={inputButton.label}
										ref={inputButton => this.inputButtons.push(inputButton)}
										className={`
											diamondCalculator-weightInput-button
											${inputButton.className || ''}
										`}
										onClick={
											() => {
												const action = inputButton.action.bind(this);

												this.setState({
													weightString: action(this.state.weightString)
												})
											}
										}
									/>
								)
							)
						}
					/>
					<div
						className="diamondCalculator-actions"
						children={
							this.constructor.actionButtons.map(
								actionButton => (
									<div
										dangerouslySetInnerHTML={{ __html: actionButton.label || `&nbsp;` }}
										className={`
											diamondCalculator-action-button
											${actionButton.className || ''}
										`}
										onClick={() => actionButton.action ? actionButton.action(this) : null}
									/>
								)
							)
						}
					/>
				</div>
			</div>
		);
	}
}

DiamondCalculatorLayout.defaultProps = {
	...DiamondCalculatorLayout.defaultProps,
	wrapperClass: 'diamondCalculator',
	diamond: {},
	labels: {
		...DiamondCalculatorLayout.defaultProps.labels,
		stone: 'Stone (WS)',
		stoneRetail: 'Stone (R)',
		pricePerCarat: 'Price/Ct (WS)',
		pricePerCaratRetail: 'Price/Ct (R)',
		shape: 'Shape',
		color: 'Color',
		clarity: 'Clarity',
		cutQuality: 'Cut',
		fluorescence: 'Fluorescence',
		weightPlaceholder: 'Enter weight (ct)',
		lengthPlaceholder: 'Enter length (mm)',
		save: 'Save diamond',
		table: 'View pricing table'
	}
};

DiamondCalculatorLayout.inputButtons = [
	{
		label: '1',
		action: prevValue => prevValue + '1'
	},
	{
		label: '2',
		action: prevValue => prevValue + '2'
	},
	{
		label: '3',
		action: prevValue => prevValue + '3'
	},
	{
		label: '4',
		action: prevValue => prevValue + '4'
	},
	{
		label: '5',
		action: prevValue => prevValue + '5'
	},
	{
		label: '6',
		action: prevValue => prevValue + '6'
	},
	{
		label: '7',
		action: prevValue => prevValue + '7'
	},
	{
		label: '8',
		action: prevValue => prevValue + '8'
	},
	{
		label: '9',
		action: prevValue => prevValue + '9'
	},
	{
		label: '.',
		className: 'bottom',
		action: prevValue => prevValue + '.'
	},
	{
		label: '0',
		className: 'bottom',
		action: prevValue => prevValue + '0'
	},
	{
		className: 'bottom backspace fa fa-long-arrow-left',
		action: prevValue => String(prevValue).substring(0, String(prevValue).length - 1)
	}
];

DiamondCalculatorLayout.actionButtons = [
	{
		label: 'Reset',
		action: diamondCalculator => diamondCalculator.setState({ weightString: '' })
	},
	{
		label: 'Table',
		action: diamondCalculator => diamondCalculator.setDiamondPricingTable()
	},
	{
		className: 'blank'
	},
	{
		className: 'blank'
	}
];

export default connect(({ diamond }) => ({ diamond }))(DiamondCalculatorLayout);