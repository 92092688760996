import React from 'react';

class ExpandingInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.defaultValue,
			width: 0
		};
	}

	componentDidMount() {
		this.sizeInput();
	}

	componentWillReceiveProps( nextProps ) {
		if( this.props.defaultValue == this.state.value ) {
			this.setState({ value: nextProps.defaultValue })
		}
	}

	componentDidUpdate( prevProps, prevState ) {
		if( prevState.value != this.state.value ) {
			this.sizeInput();
		}
	}

	sizeInput() {
		this.setState({ width: this.input.value ? this.sizer.offsetWidth : 0 });
	}

	render() {
		const { value, width } = this.state;

		return (
			<div className={`expandingInput ${this.props.className}`}>
				<div className="expandingInput-input-wrapper">
					<input
						ref={ input => this.input = input }
						className="expandingInput-input"
						type={this.props.type}
						style={{ width }}
						value={value}
						onFocus={ e => e.target.select() }
						onChange={
							() => this.setState( { value: this.input.value }, () => {
								this.props.onChange(this.input.value)
							})
						}
						onBlur={ () => this.props.onBlur( this.input.value ) }
					/>
					{
						this.props.suffix ? (
							<span
								className="expandingInput-input-suffix"
								children={this.props.suffix}
							/>
						) : (
							null
						)
					}
					{ this.props.afterInput }
				</div>
				<span
					ref={ sizer => this.sizer = sizer }
					className="expandingInput-sizer"
					children={value}
				/>
			</div>
		);
	}
}

ExpandingInput.propTypes = {
	type: React.PropTypes.string.isRequired,
	suffix: React.PropTypes.string,
	onChange: React.PropTypes.func,
	onBlur: React.PropTypes.func,
	neverBlank: React.PropTypes.bool,
	className: React.PropTypes.string,
	afterInput: React.PropTypes.node
};

ExpandingInput.defaultProps = {
	type: 'text',
	suffix: '',
	defaultValue: '',
	onChange: new Function(),
	onBlur: new Function(),
	neverBlank: false,
	className: '',
	afterInput: null
};

export default ExpandingInput;
