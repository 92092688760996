import _ from 'underscore';
import React from 'react';
import { sectorValues, absoluteHueToSector } from 'shared/utilities';

class ColorPositionPicker extends React.Component {
	render() {
		// Determine the sector that encompasses the hue
		const sectorName = absoluteHueToSector( this.props.hue );

		// Determine the hue range the sector encompasses
		const sectorRange = sectorValues[sectorName];

		// Collect all of the possible hues for this gem that are encompassed by the sector
		const possibleHues = _.filter(
			_.keys( this.props.grades ).map(
				hue => Number(hue)
			),
			hue => hue > sectorRange[0] && hue <= sectorRange[1]
		);

		// Determine the position values from the possible hues
		const colorPositions = possibleHues.map(
			possibleHue => possibleHue - sectorRange[0]
		);

		// Collect data for render
		const positions = this.props.positions.map( position => ({
			label: String(position),
			active: _.contains( colorPositions, position ),
			selected: this.props.hue == sectorRange[0] + position,
			hue: sectorRange[0] + position
		}));

		return (
			<div className="colorPositionPicker">
				{
					positions.map( position =>
						<div
							className={`
								colorPositionPicker-position
								${ position.active ? `active` : `notActive` }
								${ position.selected ? `selected` : `notSelected` }
							`}
							children={position.label}
							key={position.label}
							onClick={ () => position.active ? this.props.onChange( position.hue ) : null }
						/>
					)
				}
			</div>
		);
	}
}

ColorPositionPicker.propTypes = {
	// A gem grade tree object
	grades: React.PropTypes.object.isRequired,
	// The current hue
	hue: React.PropTypes.number.isRequired,
	// An array of all possible positions, regardless of whether they are valid for a particular gem configuration
	positions: ( props, propName, componentName ) => {
		if(
			! props[propName] ||
			! props[propName].reduce ||
			! props[propName].reduce(
				( isValid, position ) => (
					isValid !== false &&
					! _.isNaN( position ) &&
					position > 0 &&
					position <= 10
				)
			)
		) {
			return new Error(`Invalid prop ${propName} supplied to ${componentName}. Must be a an array of numbers in the range 0 < n <= 10.`);
		}
	},
	onChange: React.PropTypes.func.isRequired
}

ColorPositionPicker.defaultProps = {
	positions: [ 2.5, 5, 7.5, 10 ],
	onChange: new Function()
};

export default ColorPositionPicker;
