import _ from 'underscore';
import React from 'react';
import Gem from 'shared/components/Gem';
import Diamond from 'shared/components/Diamond';
import Opal from 'shared/components/Opal';
import PearlStrand from 'shared/components/PearlStrand';
import Pearl from 'shared/components/Pearl';
import ColoredDiamond from 'shared/components/ColoredDiamond';
import { connect } from 'react-redux';
import CurrencyExchange from 'shared/components/CurrencyExchange';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { isMobile } from 'shared/utilities';

class PricingTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ready: false,
            diamondSizeRange: false,
            diamondSizeRanges: [],
            gemSizeRanges: [],
            pricingData: {},
            tab: 'PricingTable',
            tableHeight: { height: `calc(${window.innerHeight}px - 18.25rem)` },
            tabHeight: { height: `calc(${window.innerHeight}px - 15rem)` },
            tabs: false
        };

        this.grades_coloredGem = {
            '1': { title: 'Very Low Commercial' },
            '2': { title: 'Lower Commercial' },
            '3': { title: 'Middle Commercial' },
            '4': { title: 'Upper Commercial' },
            '5': { title: 'Lower Good' },
            '6': { title: 'Upper Good' },
            '7': { title: 'Lower Fine' },
            '8': { title: 'Upper Fine' },
            '9': { title: 'Lower Extra Fine' },
            '10': { title: 'Upper Extra Fine' }
        };

        this.diamondNames = {};
        _.each( Diamond.shapes, shape => {
            this.diamondNames[ shape.id ] = shape.tableLabel ? shape.tableLabel : shape.label;
        } );

        this.diamond_clarities = {};
        _.each( Diamond.clarities, clarity => {
            this.diamond_clarities[ String( clarity.id ) ] = clarity.label;
        } );

        this.diamond_colors = {};
        _.each( Diamond.colors, color => {
            this.diamond_colors[ String( color.id ) ] = color.label;
        } );

        this.grades_opals = {
            '1': { title: 'Commercial' },
            '2': { title: 'Good' },
            '3': { title: '' },
            '4': { title: 'Fine' },
            '5': { title: '' },
            '6': { title: 'Extra Fine' },
            '7': { title: '' }
        };

        this.grades_pearlStrands = {
            '1': { title: 'Commercial (B)' },
            '2': { title: 'Good (A)' },
            '3': { title: 'Fine (AA)' },
            '4': { title: 'Extra Fine (AAA)' }
        }

        this.clarities_coloredDiamonds = {
            '1': { title: 'FL-VVS' },
            '2': { title: 'VS' },
            '3': { title: 'SI' },
            '4': { title: 'I1' }
        }

        this.backToList = () => {
            let pricingSettings = this.props.pricing;
            pricingSettings.section = 'GemList';

            this.props.dispatch( {
                type: 'SET_PRICING_STATE',
                props: pricingSettings
            } );
        }

        this.backToSelector = () => {
            let pricingSettings = this.props.pricing;
            pricingSettings.section = 'GemListSelector';

            this.props.dispatch( {
                type: 'SET_PRICING_STATE',
                props: pricingSettings
            } );
        }

        this.buildPricingData = ( data ) => {
            let sizes = {},
                gemSizeRanges = [];
            _.each( data, price => {
                let minWeightString   = price.min_weight >= 10 ? price.min_weight.toFixed(2) : price.min_weight.toFixed(3),
                    maxWeightString   = price.max_weight >= 10 ? price.max_weight.toFixed(2) : price.max_weight.toFixed(3),
                    weightRangeString = minWeightString + ' - ' + maxWeightString;
                if( gemSizeRanges.indexOf( weightRangeString ) === -1 ) gemSizeRanges.push( weightRangeString );
                if( _.has( sizes, weightRangeString ) ) {
                    if( !sizes[weightRangeString][ price.grade.toString() ] )
                        sizes[weightRangeString][ price.grade.toString() ] = [];
                    sizes[weightRangeString][ price.grade.toString() ].push(price.price);
                    sizes[weightRangeString][ price.grade.toString() ].sort( (a,b) => (a-b) );
                }
                else {
                    sizes[weightRangeString] = {};
                    sizes[weightRangeString][ price.grade.toString() ] = [];
                    sizes[weightRangeString][ price.grade.toString() ].push( price.price);
                    sizes[weightRangeString][ price.grade.toString() ].sort( (a,b) => (a-b) );
                }
            } );

            this.setState( { gemSizeRanges } );

            _.each( sizes, size => {
                const includedGrades = ['1','2','3','4','5','6','7','8','9','10'];
                _.each( includedGrades, grade => {
                    if( !size[grade] )
                        size[grade] = 0;
                } );
            } );

            return sizes;
        }

        this.buildOpalPricingData = ( data ) => {
            let sizes = {};
            _.each( data, price => {
                if( price.grade.toString() !== '2' && price.grade.toString() !== '3' && price.grade.toString() !== '4' ) {
                    let minWeightString   = price.min_weight >= 10 ? price.min_weight.toFixed(2) : price.min_weight.toFixed(3),
                        maxWeightString   = price.max_weight >= 10 ? price.max_weight.toFixed(2) : price.max_weight.toFixed(3),
                        weightRangeString = minWeightString + ' - ' + maxWeightString;
                    if( _.has( sizes, weightRangeString ) ) {
                        if( !sizes[weightRangeString][ price.grade.toString() ] )
                            sizes[weightRangeString][ price.grade.toString() ] = [];
                        sizes[weightRangeString][ price.grade.toString() ].push(price.price);
                        sizes[weightRangeString][ price.grade.toString() ].sort( (a,b) => (a-b) );
                    }
                    else {
                        sizes[weightRangeString] = {};
                        sizes[weightRangeString][ price.grade.toString() ] = [];
                        sizes[weightRangeString][ price.grade.toString() ].push( price.price);
                        sizes[weightRangeString][ price.grade.toString() ].sort( (a,b) => (a-b) );
                    }
                }
            } );
            _.each( sizes, size => {
                const includedGrades = ['1','5','6','7','8','9','10'];
                _.each( includedGrades, grade => {
                    if( !size[grade] )
                        size[grade] = 0;
                } );
            } );
            return sizes;
        }

        this.buildPearlStrandPricingData = ( data ) => {
            let sizes = {};
            _.each( data, price => {
                if( price.grade.toString() !== '2' && price.grade.toString() !== '3' && price.grade.toString() !== '4' &&
                    price.grade.toString() !== '6' && price.grade.toString() !== '8' && price.grade.toString() !== '10' ) {
                    let minWeightString   = price.min_weight >= 10 ? price.min_weight.toFixed(2) : price.min_weight.toFixed(3),
                        maxWeightString   = price.max_weight >= 10 ? price.max_weight.toFixed(2) : price.max_weight.toFixed(3),
                        weightRangeString = minWeightString + ' - ' + maxWeightString;
                    if( _.has( sizes, weightRangeString ) ) {
                        if( !sizes[weightRangeString][ price.grade.toString() ] )
                            sizes[weightRangeString][ price.grade.toString() ] = [];
                        sizes[weightRangeString][ price.grade.toString() ].push(price.price);
                        sizes[weightRangeString][ price.grade.toString() ].sort( (a,b) => (a-b) );
                    }
                    else {
                        sizes[weightRangeString] = {};
                        sizes[weightRangeString][ price.grade.toString() ] = [];
                        sizes[weightRangeString][ price.grade.toString() ].push( price.price);
                        sizes[weightRangeString][ price.grade.toString() ].sort( (a,b) => (a-b) );
                    }
                }
            } );

            _.each( sizes, size => {
                const includedGrades = ['1','5','7','9'];
                _.each( includedGrades, grade => {
                    if( !size[grade] )
                        size[grade] = 0;
                } );
            } );

            return sizes;
        }

        this.buildColoredDiamondPricingData = ( data ) => {
            let sizes = {};
            _.each( data, price => {
                if( price.diamond_clarity.toString() == '2' || price.diamond_clarity.toString() == '4' ||
                    price.diamond_clarity.toString() == '6' || price.diamond_clarity.toString() == '7' ) {
                    let minWeightString   = price.min_weight >= 10 ? price.min_weight.toFixed(2) : price.min_weight.toFixed(3),
                        maxWeightString   = price.max_weight >= 10 ? price.max_weight.toFixed(2) : price.max_weight.toFixed(3),
                        weightRangeString = minWeightString + ' - ' + maxWeightString;
                    if( _.has( sizes, weightRangeString ) ) {
                        if( !sizes[weightRangeString][ price.diamond_clarity.toString() ] )
                            sizes[weightRangeString][ price.diamond_clarity.toString() ] = [];
                        sizes[weightRangeString][ price.diamond_clarity.toString() ].push(price.price);
                        sizes[weightRangeString][ price.diamond_clarity.toString() ].sort( (a,b) => (a-b) );
                    }
                    else {
                        sizes[weightRangeString] = {};
                        sizes[weightRangeString][ price.diamond_clarity.toString() ] = [];
                        sizes[weightRangeString][ price.diamond_clarity.toString() ].push( price.price);
                        sizes[weightRangeString][ price.diamond_clarity.toString() ].sort( (a,b) => (a-b) );
                    }
               }
            } );

            _.each( sizes, size => {
                const includedGrades = ['2','4','6','7'];
                _.each( includedGrades, grade => {
                    if( !size[grade] )
                        size[grade] = 0;
                } );
            } );

            return sizes;
        }

        this.buildDiamondPricingData = ( data ) => {
            let sizes = {},
                ranges = [];
            _.each( data, price => {
                let minWeightString   = price.min_weight >= 10 ? price.min_weight.toFixed(2) : price.min_weight.toFixed(3),
                    maxWeightString   = price.max_weight >= 10 ? price.max_weight.toFixed(2) : price.max_weight.toFixed(3),
                    weightRangeString = minWeightString + ' - ' + maxWeightString;

                if( ranges.indexOf( weightRangeString ) === -1 ) ranges.push( weightRangeString );

                if( _.has( sizes, weightRangeString ) )
                    if( _.has( sizes[weightRangeString], this.diamond_colors[ String( price.diamond_color ) ] ) )
                        sizes[weightRangeString][ this.diamond_colors[ String( price.diamond_color ) ] ][ String( price.diamond_clarity ) ] =  price.price  || '-';
                    else {
                        sizes[weightRangeString][this.diamond_colors[ String( price.diamond_color ) ]] = {};
                        sizes[weightRangeString][this.diamond_colors[ String( price.diamond_color ) ]][ String( price.diamond_clarity ) ] = price.price  || '-' ;
                    }
                else {
                    sizes[weightRangeString] = {};
                    sizes[weightRangeString][this.diamond_colors[ String( price.diamond_color ) ]] = {};
                    sizes[weightRangeString][this.diamond_colors[ String( price.diamond_color ) ]][ String( price.diamond_clarity ) ] = price.price  || '-' ;
                }
            } );

            _.each( sizes, size => {
                _.each( this.diamond_colors, color => {
                    if( size[ color ] && Object.keys( size[ color ] ).length < 10 )
                        for( let i = 0; i < 10; i++ )
                            if( !size[ color ][ i ] )
                                size[ color ][ i ] = 0;
                } );
            } );

            const rangesMatch = ( last, current ) => {
                if( !last )
                    return false;

                // compare lengths - can save a lot of time
                if( current.length != last.length )
                    return false;

                for( var i = 0, l = current.length; i < l; i++ ) {
                    // Check if we have nested arrays
                    if( current[ i ] instanceof Array && last[ i ] instanceof Array ) {
                        // recurse into the nested arrays
                        if( !current[ i ].equals( last[ i ] ) )
                            return false;
                    }
                    else if( current[ i ] != last[ i ] ) {
                        // Warning - two different object instances will never be equal: {x:20} != {x:20}
                        return false;
                    }
                }
                return true;
            }

            let sortedData = {};
            let keys = Object.keys( sizes ).sort( (a,b) => { return parseFloat( a ) - parseFloat( b ); } );

            _.each( keys, key => {
                let innerSortedData = {};
                _.each( Object.keys( sizes[key] ).sort(), innerKey => {
                    innerSortedData[innerKey] = sizes[key][innerKey];
                } );
                sortedData[key] = innerSortedData;
            } );

            sizes = sortedData;

            ranges = ranges.sort( (a,b) => { return parseFloat( a ) - parseFloat( b ); } );

           if( !this.state.diamondSizeRange || !rangesMatch( this.state.diamondSizeRanges, ranges ) ) {
                let startIndex = 0;
                _.each( ranges, (range,index) => {
                    if( parseFloat( range ) == 1 ) {
                        startIndex = index;
                    }
                } );
                this.setState( { diamondSizeRange: ranges[startIndex], diamondSizeRanges: ranges } );
            }

            return sizes;
        }

        this.handleCurrencyChange = event => {
            const currency = event.target.value;
            let pricingSettings = this.props.pricing;
            pricingSettings.currency = currency;

            this.props.dispatch( {
                type: 'SET_PRICING_STATE',
                props: pricingSettings
            } );
        }

        this.handleColumnSelection = col => {
            let pricingSettings = this.props.pricing;

            if( this.props.pricing.listType === 'Diamonds' ) {
                if( !( pricingSettings.selectedColumn.clarity === col ) ) {
                    pricingSettings.selectedColumn.selected = true;
                    pricingSettings.selectedColumn.clarity = col;

                    this.props.dispatch( {
                        type: 'SET_PRICING_STATE',
                        props: pricingSettings
                    } );
                }
                else {
                    pricingSettings.selectedColumn.selected = !pricingSettings.selectedColumn.selected;

                    this.props.dispatch( {
                        type: 'SET_PRICING_STATE',
                        props: pricingSettings
                    } );
                }
            }
            else {
                if( !( pricingSettings.selectedColumn.grade == Number( col ) ) ) {
                    pricingSettings.selectedColumn.selected = true;
                    pricingSettings.selectedColumn.grade = col;

                    this.props.dispatch( {
                        type: 'SET_PRICING_STATE',
                        props: pricingSettings
                    } );
                }
                else {
                    pricingSettings.selectedColumn.selected = !pricingSettings.selectedColumn.selected;

                    this.props.dispatch( {
                        type: 'SET_PRICING_STATE',
                        props: pricingSettings
                    } );
                }
            }
        }

        this.isColumnSelected = thisCol => {
            if( this.props.pricing.listType === 'Diamonds' ) {
                const { selected, clarity } = this.props.pricing.selectedColumn;
                return ( selected && clarity === thisCol ) ? 'column-selected' : '';
            }
            else {
                const { selected, grade } = this.props.pricing.selectedColumn;
                return ( selected && grade === thisCol ) ? 'column-selected' : '';
            }
        }

        this.getSelectedColumn = () => {
            if( this.props.pricing.listType === 'Diamonds' ) {
                let selectedColumn = this.props.pricing.selectedColumn.clarity,
                    isSelected = this.props.pricing.selectedColumn.selected;
                return isSelected ? 'column-selected-' + ( parseInt( selectedColumn ) + 1 ) : 'no-column-selected';
            }
            else {
                let selectedColumn = this.props.pricing.selectedColumn.grade,
                    isSelected = this.props.pricing.selectedColumn.selected;
                return isSelected ? 'column-selected-' + selectedColumn : 'no-column-selected';
            }
        }


        this.handleRowSelection = row => {
            if( this.props.pricing.listType == 'Diamonds' ) {
                let color = row,
                    pricingSettings = this.props.pricing;
                if( !( pricingSettings.selectedRow.color === color ) ) {
                    pricingSettings.selectedRow.selected = true;
                    pricingSettings.selectedRow.color = color;

                    this.props.dispatch( {
                        type: 'SET_PRICING_STATE',
                        props: pricingSettings
                    } );
                }
                else {
                    pricingSettings.selectedRow.selected = !pricingSettings.selectedRow.selected;

                    this.props.dispatch( {
                        type: 'SET_PRICING_STATE',
                        props: pricingSettings
                    } );
                }
            }
            else {
                let minWeight = Number( row.split('-').shift() ),
                    maxWeight = Number( row.split('-').pop() ),
                    pricingSettings = this.props.pricing;

                if( !( pricingSettings.selectedRow.minWeight === minWeight ) &&
                    !( pricingSettings.selectedRow.maxWeight === maxWeight ) ) {
                    pricingSettings.selectedRow.selected = true;
                    pricingSettings.selectedRow.minWeight = minWeight;
                    pricingSettings.selectedRow.maxWeight = maxWeight;

                    this.props.dispatch( {
                        type: 'SET_PRICING_STATE',
                        props: pricingSettings
                    } );
                }
                else {
                    pricingSettings.selectedRow.selected = !pricingSettings.selectedRow.selected;

                    this.props.dispatch( {
                        type: 'SET_PRICING_STATE',
                        props: pricingSettings
                    } );
                }
            }
        }

        this.isRowSelected = row => {
            if( this.props.pricing.listType == 'Diamonds' ) {
                let thisColor = row;

                const { selected, color } = this.props.pricing.selectedRow;

                return ( selected && thisColor === color ) ? 'selected' : '';
            }
            else {
                let thisMinWeight = Number( row.split('-').shift() ),
                    thisMaxWeight = Number( row.split('-').pop() );

                const { selected, minWeight, maxWeight } = this.props.pricing.selectedRow;

                return ( selected && thisMinWeight === minWeight && thisMaxWeight === maxWeight ) ? 'selected' : '';
            }
        }

        this.handleCellSelection = ( row, col ) => {
            if( this.props.pricing.listType == 'Diamonds' ) {
                let clarity = col,
                    color = row,
                    pricingSettings = this.props.pricing;

                if( !( pricingSettings.selectedCell.clarity === clarity ) ||
                    !( pricingSettings.selectedCell.color === color ) ) {
                    pricingSettings.selectedCell.selected = true;
                    pricingSettings.selectedCell.clarity = clarity;
                    pricingSettings.selectedCell.color = color;

                    this.props.dispatch( {
                        type: 'SET_PRICING_STATE',
                        props: pricingSettings
                    } );
                }
                else {
                    pricingSettings.selectedCell.selected = !pricingSettings.selectedCell.selected;

                    this.props.dispatch( {
                        type: 'SET_PRICING_STATE',
                        props: pricingSettings
                    } );
                }
            }
            else {
                let minWeight = Number( row.split('-').shift() ),
                    maxWeight = Number( row.split('-').pop() ),
                    pricingSettings = this.props.pricing;

                if( ( !( pricingSettings.selectedCell.minWeight === minWeight ) &&
                      !( pricingSettings.selectedCell.maxWeight === maxWeight ) )  ||
                      !( pricingSettings.selectedCell.grade === col ) ) {
                    pricingSettings.selectedCell.selected = true;
                    pricingSettings.selectedCell.minWeight = minWeight;
                    pricingSettings.selectedCell.maxWeight = maxWeight;
                    pricingSettings.selectedCell.grade = col;

                    this.props.dispatch( {
                        type: 'SET_PRICING_STATE',
                        props: pricingSettings
                    } );
                }
                else {
                    pricingSettings.selectedCell.selected = !pricingSettings.selectedCell.selected;

                    this.props.dispatch( {
                        type: 'SET_PRICING_STATE',
                        props: pricingSettings
                    } );
                }
            }
        }

        this.isCellSelected = ( row, col ) => {
            if( this.props.pricing.listType == 'Diamonds' ) {
                const { selected, color, clarity } = this.props.pricing.selectedCell;

                return ( selected && color === row && clarity === col ) ? 'selected' : '';
            }
            else {
                let thisMinWeight = Number( row.split('-').shift() ),
                    thisMaxWeight = Number( row.split('-').pop() );

                const { selected, minWeight, maxWeight, grade } = this.props.pricing.selectedCell;

                return ( selected && thisMinWeight == minWeight && thisMaxWeight == maxWeight && col == grade ) ? 'selected' : '';
            }
        }

        this.resetCellAndRowSelection = () => {
            let pricingSettings = this.props.pricing;
            pricingSettings.selectedRow.selected = false;
            pricingSettings.selectedRow.minWeight = false;
            pricingSettings.selectedRow.maxWeight = false;
            pricingSettings.selectedRow.color = false;
            pricingSettings.selectedColumn.selected = false;
            pricingSettings.selectedColumn.grade = false;
            pricingSettings.selectedColumn.clarity = false;
            pricingSettings.selectedCell.selected = false;
            pricingSettings.selectedCell.minWeight = false;
            pricingSettings.selectedCell.maxWeight = false;
            pricingSettings.selectedCell.grade = false;
            pricingSettings.selectedCell.color = false;
            pricingSettings.selectedCell.clarity = false;

            this.props.dispatch( {
                type: 'SET_PRICING_STATE',
                props: pricingSettings
            } );

            setTimeout( () => {
                this.setState( { tab: 'PricingTable' } );
            }, 500 );
        }

        this.goToRange = position => {
            let lastPosition = this.state.diamondSizeRanges.indexOf( this.state.diamondSizeRange ),
                nextPosition;

            switch( position ) {
                case 'first':
                    this.setState({ diamondSizeRange: this.state.diamondSizeRanges[0] });
                    break;
                case 'last':
                    this.setState({ diamondSizeRange: this.state.diamondSizeRanges[ this.state.diamondSizeRanges.length - 1 ] });
                    break;
                case 'next':
                    if( lastPosition === this.state.diamondSizeRanges.length - 1 )
                        nextPosition = 0;
                    else
                        nextPosition = lastPosition + 1;
                    this.setState({ diamondSizeRange: this.state.diamondSizeRanges[ nextPosition ] });
                    break;
                case 'prev':
                    if( lastPosition === 0 )
                        nextPosition = this.state.diamondSizeRanges.length - 1;
                    else
                        nextPosition = lastPosition - 1;
                    this.setState({ diamondSizeRange: this.state.diamondSizeRanges[ nextPosition ] });
                    break;
            }
        }

        this.configureDiamondPreset = () => {
            let dmd = this.props.diamond,
                pricingSettings = this.props.pricing,
                state = {};

            if( dmd.weight ) {
                this.state.diamondSizeRanges.every( range => {
                    let thisMinWeight = Number( range.split('-').shift() ),
                        thisMaxWeight = Number( range.split('-').pop() );
                    if( dmd.weight >= thisMinWeight && dmd.weight <= thisMaxWeight ) {
                        state['diamondSizeRange'] = range;
                        return false;
                    }
                    else if( dmd.weight <= thisMinWeight ) {
                        state['diamondSizeRange'] = range;
                        return false;
                    }
                    return true;
                } );
            }

            pricingSettings.presetSelection = false;

            pricingSettings.selectedRow.color = this.diamond_colors[ this.props.diamond.color ];
            pricingSettings.selectedRow.selected = true;

            pricingSettings.selectedColumn.clarity = String( this.props.diamond.clarity );
            pricingSettings.selectedColumn.selected = true;

            pricingSettings.selectedCell.color = this.diamond_colors[ this.props.diamond.color ];
            pricingSettings.selectedCell.clarity = String( this.props.diamond.clarity );
            pricingSettings.selectedCell.selected = true;

            this.setState( state );

            this.props.dispatch( {
                type: 'SET_PRICING_STATE',
                props: pricingSettings
            } );

            const cell = document.getElementById(`cell-${this.props.diamond.clarity}-${this.props.diamond.color}`);
            scrollIntoViewIfNeeded( cell, false, {
                duration: 500
            } );
        }

        this.configureColoredGemPreset = () => {
            let gem = this.props.gem,
                pricingSettings = this.props.pricing,
                column, row;

            pricingSettings.presetSelection = false;
            pricingSettings.selectedRow.selected = true;
            pricingSettings.selectedColumn.selected = true;
            pricingSettings.selectedCell.selected = true;

            let isInRange = false,
                rowCount = 0;

            this.state.gemSizeRanges.every( range => {
                let thisMinWeight = Number( range.split('-').shift() ),
                    thisMaxWeight = Number( range.split('-').pop() );
                if( gem.weight >= thisMinWeight && gem.weight <= thisMaxWeight ) {
                    pricingSettings.selectedRow.minWeight = thisMinWeight;
                    pricingSettings.selectedRow.maxWeight = thisMaxWeight;
                    pricingSettings.selectedCell.minWeight = thisMinWeight;
                    pricingSettings.selectedCell.maxWeight = thisMaxWeight;
                    isInRange = true;
                    row = rowCount;
                    return false;
                }
                else if( gem.weight <= thisMinWeight ) {
                    pricingSettings.selectedRow.minWeight = thisMinWeight;
                    pricingSettings.selectedRow.maxWeight = thisMaxWeight;
                    pricingSettings.selectedCell.minWeight = thisMinWeight;
                    pricingSettings.selectedCell.maxWeight = thisMaxWeight;
                    isInRange = true;
                    row = rowCount;
                    return false;
                }
                rowCount++;
                return true;
            } );

            if( !isInRange ) {
                let thisMinWeight = Number( this.state.gemSizeRanges[this.state.gemSizeRanges.length-1].split('-').shift() ),
                    thisMaxWeight = Number( this.state.gemSizeRanges[this.state.gemSizeRanges.length-1].split('-').pop() );
                pricingSettings.selectedRow.minWeight = thisMinWeight;
                pricingSettings.selectedRow.maxWeight = thisMaxWeight;
                pricingSettings.selectedCell.minWeight = thisMinWeight;
                pricingSettings.selectedCell.maxWeight = thisMaxWeight;
                row = this.state.gemSizeRanges.length-1;
            }

            let grade = Math.ceil( Gem.getGrade( this.props.gem ) );
            pricingSettings.selectedColumn.grade = grade;
            pricingSettings.selectedCell.grade = grade;
            column = grade;

            this.props.dispatch( {
                type: 'SET_PRICING_STATE',
                props: pricingSettings
            } );

            const cell = document.getElementById(`cell-${row}-${column-1}`);
            scrollIntoViewIfNeeded( cell, false, {
                duration: 500
            } );
        }

        this.getFirstColumnName = listType => {
            switch( listType ) {
                case 'Diamonds':
                    return 'Color';
                case 'Pearl Strands':
                    return 'Millimeters';
                case 'Pearls':
                    return 'Millimeters';
                case 'Colored Diamonds':
                    return 'Carats';
                default:
                    return 'Size';
            }
        }

        this.getPricingData = (listType,id,preset) => {

            if( typeof id == 'undefined' ) return;

            if( listType === 'Diamonds' ) {
                ( new Diamond().getPrices( { shape: id } ).then( prices => {

                    ( new Diamond().getDiamondNotes( { id: id } ).then( notes => {
                        let pricingData = this.buildDiamondPricingData( prices );

                        let state = { ready: true, pricingData: pricingData, shapeQualityPrice: false }

                        if( notes ) {
                            state['info'] = notes;
                            state['tabs'] = true;
                        }
                        else {
                            state['info'] = null;
                            state['tabs'] = false;
                        }
                        this.setState( state );
                        if( preset ) this.configureDiamondPreset();
                    } ) );
                } ) );
            }
            else if( listType === 'Opals' ) {
                ( new Opal().getPrices( { type: id } ).then( prices => {
                    let pricingData = this.buildOpalPricingData( prices );

                    let sortedData = {};
                    let keys = Object.keys( pricingData ).sort( (a,b) => { return parseFloat( a ) - parseFloat( b ); } );

                    _.each( keys, key => {
                        sortedData[key] = pricingData[key];
                    } );

                    this.setState( {
                        ready: true,
                        pricingData: sortedData,
                        info: null,
                        shapeQualityPrice: null,
                        tabs: false
                    } );
                } ) );
            }
            else if( listType === 'Pearl Strands' ) {
                ( new PearlStrand().getPrices( { type: id } ).then( prices => {
                    let pricingData = this.buildPearlStrandPricingData( prices );

                    let sortedData = {};
                    let keys = Object.keys( pricingData ).sort( (a,b) => { return parseFloat( a ) - parseFloat( b ); } );

                    _.each( keys, key => {
                        sortedData[key] = pricingData[key];
                    } );

                    this.setState( {
                        ready: true,
                        pricingData: sortedData,
                        info: null,
                        shapeQualityPrice: null,
                        tabs: false
                    } );
                } ) );
            }
            else if( listType === 'Pearls' ) {
                ( new Pearl().getPrices( { type: id } ).then( prices => {
                    let pricingData = this.buildPearlStrandPricingData( prices );

                    let sortedData = {};
                    let keys = Object.keys( pricingData ).sort( (a,b) => { return parseFloat( a ) - parseFloat( b ); } );

                    _.each( keys, key => {
                        sortedData[key] = pricingData[key];
                    } );

                    this.setState( {
                        ready: true,
                        pricingData: sortedData,
                        info: null,
                        shapeQualityPrice: null,
                        tabs: false
                    } );
                } ) );
            }
            else if( listType === 'Colored Diamonds' ) {
                ( new ColoredDiamond().getPrices( { type: id } ).then( prices => {
                    let pricingData = this.buildColoredDiamondPricingData( prices );

                    let sortedData = {};
                    let keys = Object.keys( pricingData ).sort( (a,b) => { return parseFloat( a ) - parseFloat( b ); } );

                    _.each( keys, key => {
                        sortedData[key] = pricingData[key];
                    } );

                    this.setState( {
                        ready: true,
                        pricingData: sortedData,
                        info: null,
                        shapeQualityPrice: null,
                        tabs: false
                    } );
                } ) );
            }
            else {
                ( new Gem().getPrices( { gemId: id } ).then( prices => {
                    ( new Gem().getMetaFields( { id: id } ).then( fields => {
                        let pricingData = this.buildPricingData( prices );
                        let sortedData = {};
                        let keys = Object.keys( pricingData ).sort( (a,b) => { return parseFloat( a ) - parseFloat( b ); } );

                        _.each( keys, key => {
                            sortedData[key] = pricingData[key];
                        } );

                        let state = { ready: true, pricingData: sortedData };
                        if( fields.info || fields.shapeQualityPrice )
                            state['tabs'] = true;
                        else {
                            state['tabs'] = false;
                            state['tab'] = 'PricingTable';
                        }

                        if( fields.info )
                            state['info'] = fields.info;
                        if( fields.shapeQualityPrice )
                            state['shapeQualityPrice'] = fields.shapeQualityPrice;
                        this.setState( state );
                        if( preset ) this.configureColoredGemPreset();
                    } ) );
                } ) );
            }
        }

        this.setTableHeight = listType => {
            let winHeight = window.innerHeight;
            if( isMobile() && this.refs.PricingTable_ref )
                if( listType === 'Diamonds' )
                    this.setState( {
                        tableHeight: { height: `calc(${winHeight}px - ${this.state.tabs ? '12' : '9.5'}rem)` },
                        tabHeight: { height: `calc(${winHeight}px - 9rem)` }
                    } );
                else
                    this.setState( {
                        tableHeight: { height: `calc(${winHeight}px - ${this.state.tabs ? '9' : '6.5'}rem)` } ,
                        tabHeight: { height: `calc(${winHeight}px - 9rem)` }
                    } );
            else
                if( listType === 'Diamonds' )
                    this.setState( {
                        tableHeight: { height: `calc(${winHeight}px - ${this.state.tabs ? '18.25' : '15.75'}rem)` },
                        tabHeight: { height: `calc(${winHeight}px - 15rem)` }
                    } );
                else
                    this.setState( {
                        tableHeight: { height: `calc(${winHeight}px - ${this.state.tabs ? '15.25' : '12.75'}rem)` },
                        tabHeight: { height: `calc(${winHeight}px - 15rem)` }
                    } );
        }
    }

    componentWillReceiveProps(nextProps) {
        this.getPricingData( nextProps.pricing.listType,
            nextProps.pricing.listType === 'Diamonds' ? nextProps.pricing.selection.shape : nextProps.pricing.selection.id,
            nextProps.pricing.presetSelection );

        this.setTableHeight( nextProps.pricing.listType);
    }

    componentDidMount() {
        window.addEventListener("resize", ()=> {
            this.setTableHeight( this.props.pricing.listType );
        } );

        if( !this.state.ready ) {
            this.getPricingData( this.props.pricing.listType,
                this.props.pricing.listType === 'Diamonds' ? this.props.pricing.selection.shape : this.props.pricing.selection.id,
                this.props.pricing.presetSelection );

            this.setTableHeight( this.props.pricing.listType );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", ()=> {
            this.setTableHeight( this.props.pricing.listType );
        } );
    }

    render() {
        const { currency, exchangeRates } = this.props.pricing;

        let currencyOptions = [];

        _.each( exchangeRates, ( rate, currency ) => {
            currencyOptions.push(
                <option key={ 'currency-' + currency }
                        value={ currency }>{ currency }</option>
            );
        } );

        let columnHeads = [];
        if( this.props.pricing.listType === 'Diamonds' ) {
            _.each( this.diamond_clarities, ( colHead, key ) => {
                columnHeads.push(
                    <div key={ 'colHead-' + key }
                         data-column={ key }
                         onClick={ ()=> this.handleColumnSelection( key ) }
                         className={ 'pricingTable-inner-table-head-columnHead ' + this.isColumnSelected( key ) }>
                        { colHead }
                    </div>
                );
            } )
        }
        else if( this.props.pricing.listType === 'Opals' ) {
            _.each( this.grades_opals, ( colHead, key ) => {
                columnHeads.push(
                    <div key={ 'colHead-' + key }
                         data-column={ ( key === '3' || key === '5' || key === '7' ? key - 1 : key ) }
                         onClick={ ()=> this.handleColumnSelection( ( key === '3' || key === '5' || key === '7' ? key - 1 : key ) ) }
                         className={ 'pricingTable-inner-table-head-columnHead ' + this.isColumnSelected( ( key === '3' || key === '5' || key === '7' ? key - 1 : key ) ) }>
                        <div className={`pricingTable-inner-table-head-columnHead-inner`}
                             data-grade={ colHead.title.toLowerCase().replace(' ','_') } >
                            { colHead.title }
                        </div>
                    </div>
                );
            } )
        }
        else if( this.props.pricing.listType === 'Pearl Strands' || this.props.pricing.listType === 'Pearls' ) {
            _.each( this.grades_pearlStrands, ( colHead, key ) => {
                columnHeads.push(
                    <div key={ 'colHead-' + key }
                         data-column={ key }
                         onClick={ ()=> this.handleColumnSelection( key ) }
                         className={ 'pricingTable-inner-table-head-columnHead ' + this.isColumnSelected( key ) }>
                        { colHead.title }
                    </div>
                );
            } )
        }
        else if( this.props.pricing.listType === 'Colored Diamonds' ) {
            _.each( this.clarities_coloredDiamonds, ( colHead, key ) => {
                columnHeads.push(
                    <div key={ 'colHead-' + key }
                         data-column={ key }
                         onClick={ ()=> this.handleColumnSelection( key ) }
                         className={ 'pricingTable-inner-table-head-columnHead ' + this.isColumnSelected( key ) }>
                        { colHead.title }
                    </div>
                );
            } )
        }
        else {
            _.each( this.grades_coloredGem, ( colHead, key ) => {
                columnHeads.push(
                    <div key={ 'colHead-' + key }
                         data-column={ key }
                         onClick={ ()=> this.handleColumnSelection( key ) }
                         className={ 'pricingTable-inner-table-head-columnHead ' + this.isColumnSelected( key ) }>
                        { colHead.title }<br/>{ key }
                    </div>
                );
            } )
        }


        let tableRows = [];
        if( this.state.ready ) {
            if( this.props.pricing.listType === 'Diamonds' ) {
                let rowNum = 0;
                _.each( this.state.pricingData[ this.state.diamondSizeRange ], ( rows, rowIndex ) => {
                    let cols = [],
                        col = 1;
                    cols.push(
                        <div key={ 'rowHead-' + rowIndex }
                             onClick={ ()=> this.handleRowSelection( rowIndex ) }
                             className={'pricingTable-inner-table-body-row-rowHead'}>
                            { rowIndex }
                        </div>
                    );
                    let colNum = 0;
                    _.each( rows, ( price, colIndex ) => {
                        cols.push(
                            <div key={ 'rowUnit-' + rowIndex + '-' + colIndex }
                                 data-column={ col }
                                 onClick={ ()=> this.handleCellSelection( rowIndex, colIndex ) }
                                 className={ 'pricingTable-inner-table-body-row-rowUnit ' + this.isCellSelected( rowIndex, colIndex ) }
                                 id={`cell-${colNum}-${rowNum}`}>
                                <CurrencyExchange pricing={ this.props.pricing } value={ price } />
                            </div>
                        );
                        col++;
                        colNum++;
                    } );
                    tableRows.push(
                        <div key={ 'row-' + rowIndex }
                             className={ 'pricingTable-inner-table-body-row ' + this.isRowSelected( rowIndex ) }>
                            { cols }
                        </div>
                    );
                    rowNum++;
                } )
            }
            else {
                let rowNum = 0;
                _.each( this.state.pricingData, ( rows, rowIndex ) => {
                    let cols = [],
                        col = 1;
                    cols.push(
                        <div key={ 'rowHead-' + rowIndex }
                             onClick={ ()=> this.handleRowSelection( rowIndex ) }
                             className={'pricingTable-inner-table-body-row-rowHead'}>
                            { rowIndex }
                        </div>
                    );
                    let colNum = 0;
                    _.each( rows, ( price, colIndex ) => {
                        if( this.props.pricing.listType === 'Opals' ) {
                            cols.push(
                                <div key={ 'rowUnit-' + rowIndex + '-' + colIndex }
                                     data-column={ ( col === 3 || col === 5 || col === 7 ? col - 1 : col ) }
                                     onClick={ ()=> this.handleCellSelection( rowIndex, colIndex ) }
                                     className={ 'pricingTable-inner-table-body-row-rowUnit ' + this.isCellSelected( rowIndex, colIndex ) }
                                     id={`cell-${rowNum}-${colNum}`}>
                                    { this.props.pricing.section === 'PricingTable' ? <CurrencyExchange pricing={ this.props.pricing } value={ price } /> : 0 }
                                </div>
                            );
                        }
                        else {
                            cols.push(
                                <div key={ 'rowUnit-' + rowIndex + '-' + colIndex }
                                     data-column={ col }
                                     onClick={ ()=> this.handleCellSelection(rowIndex, colIndex) }
                                     className={ 'pricingTable-inner-table-body-row-rowUnit ' + this.isCellSelected(rowIndex, colIndex) }
                                     id={`cell-${rowNum}-${colNum}`}>
                                    { this.props.pricing.section === 'PricingTable' ?
                                        <CurrencyExchange pricing={ this.props.pricing } value={ price }/> : 0 }
                                </div>
                            );
                        }
                        col++;
                        colNum++;
                    } );
                    tableRows.push(
                        <div key={ 'row-' + rowIndex }
                             className={ 'pricingTable-inner-table-body-row ' + this.isRowSelected( rowIndex ) }>
                            { cols }
                        </div>
                    );
                    rowNum++;
                } )
            }
        }

        const exchangeRateOutput = ()=> {
            if( this.props.pricing.currency === 'USD' )
                return '1.0000';
            else {
                let rate = Number( this.props.pricing.exchangeRates[this.props.pricing.currency] );
                if( rate >= 10000 )
                    return Math.round( rate );
                else if( rate >= 1000 )
                    return rate.toFixed(1);
                else if( rate >= 100 )
                    return rate.toFixed(2);
                else if( rate >= 10 )
                    return rate.toFixed(3);
                else
                    return rate.toFixed(4);
            }
        }

        const caratOutput = ()=> {
            if( this.props.pricing.presetActive ) {
                if (this.props.pricing.listType === 'Diamonds' && this.props.diamond.weight) {
                    return (
                        <div className="pricingTable-header-top-carat">
                            <div className="pricingTable-header-top-carat-value">
                                { this.props.diamond.weight }
                            </div>
                            <div className="pricingTable-header-top-carat-label">
                                { this.props.diamond.shape === 0 ? 'mm' : 'Carat' }
                            </div>
                        </div>
                    );
                }
                else if (this.props.pricing.listType === 'Colored Gemstones' && this.props.gem.weight) {
                    return (
                        <div className="pricingTable-header-top-carat">
                            <div className="pricingTable-header-top-carat-value">
                                { this.props.gem.weight }
                            </div>
                            <div className="pricingTable-header-top-carat-label">
                                Carat
                            </div>
                        </div>
                    );
                }
            }
        }

        const gemInfoOutput = info => {
            if( this.props.pricing.section === 'PricingTable' ) {
                if( this.props.pricing.listType === 'Diamonds' ) {
                    let lines = typeof info == 'string' ? info.split('\n') : info,
                        containers = [];
                    _.each( lines, (text,index) => {
                        containers.push(
                            <div className="diamondInfo-line"
                                 key={`diamondInfo-line-${index}`}>
                                { text }
                            </div>
                        );
                    });
                    return(
                        <div className="diamondInfo">
                            { containers }
                        </div>
                    );
                }
                else if( this.props.pricing.listType === 'Colored Gemstones' ) {
                    let containers = [];
                    const properties = [
                        { key: 'color', label: 'Color' },
                        { key: 'clarity', label: 'Clarity' },
                        { key: 'cut', label: 'Cut' },
                        { key: 'caratWgt', label: 'Carat Weight' },
                        { key: 'hMin', label: 'Hardness' },
                        { key: 'birMin', label: 'Birefringence Min' },
                        { key: 'birMax', label: 'Birefringence Max' },
                        { key: 'flrsc', label: 'Fluorescence' },
                        { key: 'spectrum', label: 'Spectrum' },
                        { key: 'treatment', label: 'Treatment' },
                        { key: 'specificGravity', label: 'Specific Gravity' },
                        { key: 'refractiveIndex', label: 'Refractive Index' },
                        { key: 'gPSpecies', label: 'Species' }
                    ];
                    _.each( properties, (prop,index) => {
                          if( info[prop.key] )
                              containers.push(
                                  <div className="gemInfo-section"
                                       key={`gemInfo-section-${index}`}>
                                      <div className="gemInfo-section-title">
                                          { prop.label }
                                      </div>
                                      <div className="gemInfo-section-info">
                                          { info[prop.key] }
                                      </div>
                                  </div>
                              )
                    });
                    return(
                        <div className="gemInfo">
                            { containers }
                        </div>
                    );
                }
            }
        };

        const shapeQualityPriceOutput = data => {
            if( this.props.pricing.section === 'PricingTable' ) {
                if( this.props.pricing.listType === 'Colored Gemstones' ) {
                    let shape = { label: 'Shape', values: [] },
                        commercial = { label: 'Commercial', values: [] },
                        good = { label: 'Good', values: [] },
                        fine = { label: 'Fine', values: [] },
                        extraFine = { label: 'Extra Fine', values: [] };

                    _.each( data, (values,index) => {
                        shape.values.push(
                            <div className={`shapeQuality-column-row
                                   ${ index%2 ? 'shapeQuality-column-row--even' : 'shapeQuality-column-row--odd' }`}
                                 key={`shape-row-${index}`}>
                                { values['Shape'] }
                            </div>
                        );
                        commercial.values.push(
                            <div className={`shapeQuality-column-row
                                   ${ index%2 ? 'shapeQuality-column-row--even' : 'shapeQuality-column-row--odd' }`}
                                 key={`commercial-row-${index}`}>
                                { values['Commercial'] }
                            </div>
                        );
                        good.values.push(
                            <div className={`shapeQuality-column-row
                                   ${ index%2 ? 'shapeQuality-column-row--even' : 'shapeQuality-column-row--odd' }`}
                                 key={`good-row-${index}`}>
                                { values['Good'] }
                            </div>
                        );
                        fine.values.push(
                            <div className={`shapeQuality-column-row
                                   ${ index%2 ? 'shapeQuality-column-row--even' : 'shapeQuality-column-row--odd' }`}
                                 key={`fine-row-${index}`}>
                                { values['Fine'] }
                            </div>
                        );
                        extraFine.values.push(
                            <div className={`shapeQuality-column-row
                                   ${ index%2 ? 'shapeQuality-column-row--even' : 'shapeQuality-column-row--odd' }`}
                                 key={`extraFine-row-${index}`}>
                                { values['ExtraFine'] }
                            </div>
                        );
                    });

                    let columns = [];
                    _.each( [ shape, commercial, good, fine, extraFine ], (column,index) => {
                        columns.push(
                            <div className="shapeQuality-column" key={`shapeQuality-column-${index}`}>
                                <div className="shapeQuality-column-title">
                                    { column.label }
                                </div>
                                <div className="shapeQuality-column-values">
                                    { column.values }
                                </div>
                            </div>
                        )
                    } );

                    return(
                        <div className="shapeQuality">
                            { columns }
                        </div>
                    )
                }
            }
        };


        return (
            <div ref="PricingTable_ref" className={`pricingTable pricingTable--type-${ this.props.pricing.listType.toLowerCase().replace(' ','_') }`}>
                <header className="pricingTable-header">
                    <div className="pricingTable-header-top">
                        <div className="pricingTable-header-top-back">
                            <button className="pricingTable-header-top-back-btn pricingTable-header-top-back-btn--double"
                                    onClick={ ()=> { this.backToSelector(); this.resetCellAndRowSelection(); } }>
                                <span className="fa fa-chevron-left"></span>
                            </button>
                            <button className="pricingTable-header-top-back-btn"
                                    onClick={ ()=> { this.backToList(); this.resetCellAndRowSelection(); } }>
                                <span className="fa fa-chevron-left"></span>
                            </button>
                        </div>
                        <div className="pricingTable-header-top-gem">
                            <div className="pricingTable-header-top-gem-info">
                                <div className="pricingTable-header-top-gem-info-exchangeRate">
                                    {this.props.labels.exchangeRate}: { exchangeRateOutput() }
                                </div>
                                <div className="pricingTable-header-top-gem-info-name">
                                    { this.props.pricing.listType === 'Diamonds' ?
                                      <span dangerouslySetInnerHTML={ { __html: this.diamondNames[this.props.pricing.selection.shape] } }/> :
                                      this.props.pricing.selection.name }
                                </div>
                            </div>
                        </div>
                        { caratOutput() }
                        <div className="pricingTable-header-top-currency">
                            <label className="pricingTable-header-top-currency-label">
                                <div className="currencySelect-currency">
                                    <div className="currencySelect-currency-top">
                                        <div className="currencySelect-currency-top-symbol">
                                            { this.props.pricing.currencySymbols[ currency ] }
                                        </div>
                                        <div className="currencySelect-currency-top-abbrv">
                                            { currency }
                                        </div>
                                        <div className="currencySelect-currency-top-icon">
                                            <span className="fa fa-chevron-down"></span>
                                        </div>
                                    </div>
                                    <div className="currencySelect-currency-bottom">
                                        Currency
                                    </div>
                                </div>
                                <select className="currencySelect"
                                        defaultValue={ currency }
                                        onChange={ this.handleCurrencyChange }>
                                    { currencyOptions }
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className="pricingTable-header-bottom">
                        <div className="pricingTable-header-bottom-tabs"
                             style={ { display: this.state.tabs ? 'flex' : 'none' } }>
                            <div className={'pricingTable-header-bottom-tabs-tab ' +
                                            ( this.state.tab === 'PricingTable' ? 'selected' : '' ) }
                                 onClick={ ()=> this.setState({tab:'PricingTable'}) }>
                                Price Table
                            </div>
                            <div className={'pricingTable-header-bottom-tabs-tab ' +
                                            ( this.state.tab === 'GemInfo' ? 'selected' : '' )}
                                 onClick={ ()=> this.setState({tab:'GemInfo'}) }
                                 style={ { display: this.state.info ? 'flex' : 'none' } }>
                                { this.props.pricing.listType === 'Diamonds' ? 'Notes' : 'Gem Info' }
                            </div>
                            <div className={'pricingTable-header-bottom-tabs-tab ' +
                                            ( this.state.tab === 'ShapeQuality' ? 'selected' : '' )}
                                 onClick={ ()=> this.setState({tab:'ShapeQuality'}) }
                                 style={ { display: this.state.shapeQualityPrice ? 'flex' : 'none' } }>
                                Shape/Quality
                            </div>
                        </div>
                        {
                            this.state.ready && this.state.tab === 'PricingTable' && this.props.pricing.listType === 'Diamonds' ? (
                                <div className="pricingTable-header-bottom-range">
                                    <button className="pricingTable-header-bottom-range-btnFirst"
                                            onClick={ ()=> this.goToRange('first') }>
                                        <span className="fa fa-chevron-left"></span>
                                    </button>
                                    <button className="pricingTable-header-bottom-range-btnPrev"
                                            onClick={ ()=> this.goToRange('prev') }>
                                        <span className="fa fa-chevron-left"></span>
                                    </button>
                                    <div className="pricingTable-header-bottom-range-value">{ this.state.diamondSizeRange }</div>
                                    <button className="pricingTable-header-bottom-range-btnNext"
                                            onClick={ ()=> this.goToRange('next') }>
                                        <span className="fa fa-chevron-right"></span>
                                    </button>
                                    <button className="pricingTable-header-bottom-range-btnLast"
                                            onClick={ ()=> this.goToRange('last') }>
                                        <span className="fa fa-chevron-right"></span>
                                    </button>
                                </div>
                            ) : null
                        }
                    </div>
                </header>
                <main className="pricingTable-wrapper">
                    <div className="pricingTable-inner" data-tab={ this.state.tab }>

                        <section data-tab="PricingTable"
                                 className={`pricingTable-inner-tab pricingTable-inner-table ${this.getSelectedColumn()}`}
                                 style={this.state.tableHeight}>
                            <div className="pricingTable-inner-table-table">
                                <div className="pricingTable-inner-table-head">
                                    <div className="pricingTable-inner-table-head-columnHead pricingTable-inner-table-head-columnHead--first">
                                        { this.getFirstColumnName( this.props.pricing.listType ) }
                                    </div>
                                    { columnHeads }
                                </div>
                                <div className={ 'pricingTable-inner-table-body ' }>
                                    { this.state.ready && tableRows }
                                </div>
                            </div>
                        </section>

                        <section data-tab="GemInfo"
                                 className="pricingTable-inner-tab pricingTable-inner-gemInfo"
                                 style={this.state.tabHeight}>
                            <div className="pricingTable-inner-gemInfo-content">
                                { this.state.info && gemInfoOutput( this.state.info ) }
                            </div>
                        </section>

                        <section data-tab="ShapeQuality"
                                 className="pricingTable-inner-tab pricingTable-inner-shapeQuality"
                                 style={this.state.tabHeight}>
                            <div className="pricingTable-inner-shapeQuality-content">
                                { this.state.shapeQualityPrice && shapeQualityPriceOutput( this.state.shapeQualityPrice ) }
                            </div>
                        </section>

                    </div>
                </main>
            </div>
        );
    }
}

PricingTable.propTypes = {
    gems: React.PropTypes.array
};

PricingTable.defaultProps = {
    ...PricingTable.defaultProps,
    wrapperClass: 'login',
    gems: [],
    labels: {
        exchangeRate: 'Exch Rate'
    }
};


export default connect()(PricingTable);