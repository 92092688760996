import React from 'react';
import { Link } from 'react-router';
import Layout from 'shared/components/Layout';

class HomeLayout extends Layout {
    displayLayout() {
        return (
            <div>
                <div className="top-bar">
                    <div className="row">
                        <div className="small-12 columns">
                            <img
                                src="/static/images/logo.png"
                                alt="Gemguide Logo"
                                className="home-logo hide-for-medium"
                            />
                            <div
                                className="home-welcomeMessage"
                                children={ `Welcome back, ${this.props.loggedInUser.name}!` }
                            />
                        </div>
                    </div>
                </div>

                <div className="home-navItems">
                    <div className="row">
                        <div className="small-12 medium-6 large-4 small-centered columns">
                            {
                                this.props.globalNavItems.map( (globalNavItem, i) => {
                                    if( globalNavItem.label === 'News/Archives' ) {
                                        return (
                                            <a
                                                className={ `home-navItem` }
                                                title={ globalNavItem.label }
                                                href={ globalNavItem.value }
                                                key={`nav-option-${i}`}
                                            >
                                                <i className={ `fa ${globalNavItem.icon}` }/>
                                                { globalNavItem.label }
                                            </a>
                                        );
                                    } else {
                                        return (
                                            <Link
                                                className="home-navItem"
                                                to={ globalNavItem.value }
                                                key={ globalNavItem.value }
                                            >
                                                <i className={ `fa ${globalNavItem.icon}` }/>
                                                { globalNavItem.label }
                                            </Link>
                                        );
                                    }
                                } )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HomeLayout.defaultProps = {
    ...HomeLayout.defaultProps,
    wrapperClass: 'home'
};

export default HomeLayout;
