import React from 'react';
import { Link } from 'react-router';
import _ from 'underscore';

import { getWordpressOAuth, wordpressApiRequest } from 'shared/utilities';
import settings from 'shared/settings';
import {
	isServer,
	apiRequest,
	isApiRequest,
} from 'shared/utilities';

class User extends React.Component {
	constructor(props) {
		super();

		// Instantiate with "artificial" props for DB queries
		if(props) this.props = props;

		this.apiRequest = (method, data) =>
			apiRequest( 'User', method, data );
	}

	getApiCallbackUrl() {
		if( isServer() ) {
			const { client_key, oauth_verifier } = arguments[0];
			return new Promise( resolve => {
				global.DB.API_User.findOne( {
					raw: true,
					where: {
						api_key: client_key
					}
				} ).then( user => {
					if( user.callback.indexOf( '?' ) !== -1 ) {
                        resolve( user.callback + `&user=${oauth_verifier}` );
                    } else {
                        resolve( user.callback + `?user=${oauth_verifier}` );
                    }
				} );
			} );
		} else {
			return this.apiRequest( 'getApiCallbackUrl',
				{ client_key: arguments[0], oauth_verifier: arguments[1] } );
		}
	}

	getApiFailureRedirect() {
        if( isServer() ) {
            const { client_key } = arguments[0];
            return new Promise( resolve => {
                global.DB.API_User.findOne( {
                    raw: true,
                    where: {
                        api_key: client_key
                    }
                } ).then( user => {
                    resolve( user.failure_redirect );
                } );
            } );
        } else {
            return this.apiRequest( 'getApiFailureRedirect', { client_key: arguments[0] } );
        }
	}

	setWordpressProfile() {
		// Filter out irrelevant settings
		const allowedProperies = [
			'currency',
			/multiplier_.+/,
			/diamond_markup_\d+/,
			/stone_markup_\d+/
		];

		const body = arguments[0];

		const params = Object.keys( arguments[0] ).reduce( ( reducingParams, key ) => {
			const isAllowedProperty = _.some( allowedProperies, allowedProperty =>
				key.match( allowedProperty )
			);

			if( isAllowedProperty ) {
				return {
					...reducingParams,
					[key]: body[key]
				}
			} else {
				return reducingParams;
			}
		}, {});

		if( isServer() ) {
			const req = arguments[1];
			const route = '/lamembership/v1.0.0/member/profile/';

			return wordpressApiRequest( req, route, params, 'PUT' );
		} else {
			return this.apiRequest( 'setWordpressProfile', params );
		}
	}

	getWordpressProfile() {
		if( isServer() ) {
			const req = arguments[1];
			const route = '/lamembership/v1.0.0/member/profile/';

			return wordpressApiRequest( req, route );
		} else {
			return this.apiRequest( 'getWordpressProfile' );
		}
	}

	getOAuthAccessToken() {
		const { publicToken, oAuthVerifier, isAPI } = arguments[0];

		if( isServer() ) {
			const wordpressOAuth = getWordpressOAuth({
				credentials: {
					token: {
						public: publicToken,
						secret: global.OAUTH_REQUEST_TOKENS[publicToken]
					}
				}
			}, isAPI );

			if( oAuthVerifier && wordpressOAuth ) {
				return new Promise( ( resolve, reject ) =>
					wordpressOAuth.getAccessToken( oAuthVerifier )
						.then( result => {
							resolve(result);
						})
						.catch( error => reject( error ) )
				);
			} else {
				return Promise.reject();
			}
		} else {
			return this.apiRequest( 'getOAuthAccessToken', { publicToken, oAuthVerifier, isAPI } );
		}
	}

	getOAuthAuthorizeUrl() {
		if(!isServer() ) {
			return this.apiRequest( 'getOAuthAuthorizeUrl', { api: arguments[0], client_key: arguments[1] } );
		} 

		const { api, client_key } = arguments[0];
		const wordpressOAuth = getWordpressOAuth({}, api);

		if (!wordpressOAuth) {
			return Promise.reject();
		} 

		return new Promise((resolve, reject) =>
			wordpressOAuth.getRequestToken(client_key) // wtf is this
				.then(({ redirectURL, token }) => {
					// Store the OAuth token secret based on the public token for use while getting the access token
					global.OAUTH_REQUEST_TOKENS[token.oauth_token] = token.oauth_token_secret;
					return redirectURL ? resolve(redirectURL) : reject(`Error in User#getOAuthAuthorizeUrl: Unexpected response format`)
				})
				.catch(error => {
					reject(error)
				})
		);

	}

	setMeta(key, value) {
		if( isServer() ) {
			const key = arguments[0].key;
			const user_wordpress_id = arguments[0].id || this.getProp('id');
			const isThisApiRequest = isApiRequest(arguments);

			const requestingUser = isThisApiRequest ? arguments[1].user : null;

			return new Promise( resolve => {
				global.DB.Meta.upsert({
					key,
					user_wordpress_id,
					value: isThisApiRequest ? arguments[0].value : JSON.stringify(arguments[0].value)
				}).then( resolve )
			});
		} else {
			return this.apiRequest( 'setMeta', { id: this.getProp('id'), key, value: JSON.stringify(value) } );
		}
	}

	updateTopGems(gem) {
		if( isServer() ) {
			const { userId, gem } = arguments[0];

			return new Promise( resolve => {
				global.DB.Meta.findOne({
					where: {
						user_wordpress_id: userId,
						key: 'top_gems'
					}
				}).then( result => {
                    if( ! result ) {
						const topGems = [ { data: gem, count: 1 } ];

						global.DB.Meta.upsert({
							key: 'top_gems',
							user_wordpress_id: userId,
							value: JSON.stringify(topGems)
						}).then( ()=> {
                            resolve( topGems );
						});
					} else {
                        const metaId = result ? result.id : null;

                        let gems = JSON.parse( result.value );
						let newGem = true;

                        _.each( gems, ( topGem, index ) => {
							if( topGem.data.id === gem.id ) {
								newGem = false;
								topGem.count = topGem.count + 1;

                                global.DB.Meta.upsert({
                                    id: metaId,
									key: 'top_gems',
									user_wordpress_id: userId,
									value: JSON.stringify(gems)
								}).then( newTopGems => {
                                    resolve( newTopGems );
								});
							}
						});

                        if( newGem ) {
							gems.push({ data: gem, count: 1 });

							global.DB.Meta.upsert({
                                id: metaId,
								key: 'top_gems',
                                user_wordpress_id: userId,
								value: JSON.stringify(gems)
							}).then( newTopGems => {
                                resolve( newTopGems );
							});
						}
					}
				});
			} );
		} else {
			return this.apiRequest( 'updateTopGems', { userId: this.props.id, gem } );
		}
	}

	getTopGems() {
		if( isServer() ) {
			const { userId } = arguments[0];

			return new Promise( resolve => {
				global.DB.Meta.findOne({
					where: {
                        user_wordpress_id: userId,
                        key: 'top_gems'
					}
				}).then( results => {
					if( results ) {
						let parsed = JSON.parse(results.value);
						let topGems = Array.isArray( parsed ) ? JSON.parse(results.value).sort( ( a, b ) => {
							return b.count - a.count;
						} ) : [];
						resolve( topGems.length ? topGems.slice(0,3) : [] );
					} else {
						resolve([]);
					}
				});
			} );
		}
		else {
			return this.apiRequest( 'getTopGems', { userId: this.props.id } );
		}
	}

	render() {
		const { image_url, image_small, first_name, last_name, is_expert, username, short_desc, id, meta } = this.props;

		return (
			<div className={`user ${is_expert ? 'user--expert' : '' }`}>
				<Link
					className="user-image"
					to={`/user/${id}`}
				>
					<img
						src={image_small || settings('DEFAULT_PROFILE_IMAGE')}
						alt={`${first_name} ${last_name} profile image`}
					/>
				</Link>
				<div className="user-info">
					<Link
						className="user-name"
						to={`/user/${id}`}
					>
						{first_name} {last_name}
					</Link>

					<img
						className={'user-icon'}
						src={ this.getAchievementLevel( meta[0].value || 0 ).icon }
						alt=""
					/>

					<div className="user-shortDesc">(@{username})<br/>{short_desc}</div>
				</div>
			</div>
		);
	}
};

export default User;
