import _ from 'underscore';
import React from 'react';
import { connect } from 'react-redux';
import Gem from 'shared/components/Gem';
import Panel from 'shared/components/color-calculator-panels/Panel';

class ProportionPanel extends Panel {
	displayPanel() {
		return (
			<div className={`${this.constructor.className}-wrapper`}>
				<div className="panel-group panel-block">
					<div className="crownHeight panel-group-field">
						<label>
							<span
								className="label"
								children={this.props.labels.crownHeight}
							/>
							<div className="panel-group-input">
								<select
									ref={ crownHeightsSelector => this.crownHeightsSelector = crownHeightsSelector }
									value={ this.props.gem.crownHeight }
									onChange={
										() => this.props.dispatch({
											type: 'SET_GEM_PROPS',
											props: { crownHeight: this.crownHeightsSelector.value }
										})
									}
									children={
										Gem.crownHeights.map(
											crownHeight => (
												<option
													value={crownHeight.id}
													children={crownHeight.label}
												/>
											)
										)
									}
								/>
							</div>
						</label>
					</div>
					<div className="pavillionDepth panel-group-field">
						<label>
							<span
								className="label"
								children={this.props.labels.pavillionDepth}
							/>
							<div className="panel-group-input">
								<select
									ref={ pavillionDepthSelector => this.pavillionDepthSelector = pavillionDepthSelector }
									value={ this.props.gem.pavillionDepth }
									onChange={
										() => this.props.dispatch({
											type: 'SET_GEM_PROPS',
											props: { pavillionDepth: this.pavillionDepthSelector.value }
										})
									}
									children={
										Gem.pavillionDepths.map(
											pavillionDepth => (
												<option
													value={pavillionDepth.id}
													children={pavillionDepth.label}
												/>
											)
										)
									}
								/>
							</div>
						</label>
					</div>
				</div>

				<div className="panel-block">
					<label className="bulges">
						<span
							className="label"
							children={this.props.labels.bulge}
						/>
						<div
							className="button-group"
							children={
								Gem.bulges.map( bulge =>
									<div
										children={bulge.label}
										className={`
											button
											bulge
											${ bulge.id == this.props.gem.bulge ? 'active' : 'notActive' }
										`}
										onClick={
											() => {
												this.props.dispatch({
													type: 'SET_GEM_PROPS',
													props: { bulge: bulge.id }
												})
											}
										}
									/>
								)
							}
						/>
					</label>
				</div>

				<div className="panel-block">
					<label className="girdles">
						<span
							className="label"
							children={this.props.labels.girdle}
						/>
						<div
							className="button-group"
							children={
								Gem.girdles.map( girdle =>
									<div
										children={girdle.label}
										className={`
											button
											girdle
											${ girdle.id == this.props.gem.girdle ? 'active' : 'notActive' }
										`}
										onClick={
											() => {
												this.props.dispatch({
													type: 'SET_GEM_PROPS',
													props: { girdle: girdle.id }
												})
											}
										}
									/>
								)
							}
						/>
					</label>
				</div>

				<div className="panel-block">
					<label className="tableSizes">
						<span
							className="label"
							children={this.props.labels.tableSize}
						/>
						<div
							className="button-group"
							children={
								Gem.tableSizes.map( tableSize =>
									<div
										children={tableSize.label}
										className={`
											button
											tableSize
											${ tableSize.id == this.props.gem.tableSize ? 'active' : 'notActive' }
										`}
										onClick={
											() => {
												this.props.dispatch({
													type: 'SET_GEM_PROPS',
													props: { tableSize: tableSize.id }
												})
											}
										}
									/>
								)
							}
						/>
					</label>
				</div>

				<div className="panel-block">
					<label className="symmetries">
						<span
							className="label"
							children={this.props.labels.symmetry}
						/>
						<div
							className="button-group"
							children={
								Gem.symmetries.map( symmetry =>
									<div
										children={symmetry.label}
										className={`
											button
											square
											symmetry
											${ symmetry.id == this.props.gem.symmetry ? 'active' : 'notActive' }
										`}
										onClick={
											() => {
												this.props.dispatch({
													type: 'SET_GEM_PROPS',
													props: { symmetry: symmetry.id }
												})
											}
										}
									/>
								)
							}
						/>
					</label>
				</div>
			</div>
		);
	}
}

ProportionPanel.label = 'Proportion';
ProportionPanel.className = 'proportion';

ProportionPanel.defaultProps = {
	...ProportionPanel.defaultProps,
	labels: {
		crownHeight: 'Crown Height',
		pavillionDepth: 'Pavillion Depth',
		bulge: 'Bulge',
		girdle: 'Girdle',
		tableSize: 'Table Size',
		symmetry: 'Symmetry'
	}
};

ProportionPanel.drawerItems = [
	{
		label: 'Crown Height',
		valueCallback: gem => _.find( Gem.crownHeights, crownHeight => gem.crownHeight == crownHeight.id ).label
	},
	{
		label: 'Pavillion Depth',
		valueCallback: gem => _.find( Gem.pavillionDepths, pavillionDepth => gem.pavillionDepth == pavillionDepth.id ).label
	}
];

export default connect( ({ gem }) => ({ gem }) )(ProportionPanel);
