import _ from 'underscore';
import React from 'react';
import { connect } from 'react-redux';
import User from 'shared/components/User';
import { absoluteUrl } from 'shared/utilities';
import Isvg from 'react-inlinesvg';

class GemListSelector extends React.Component {

    constructor( props ) {
        super( props );

        this.state = {
            ...this.state
        }

        this.gemTypes = [
            {
                name: 'Colored Gemstones',
                icon: absoluteUrl('static/images/icon-coloredGemstone.svg')
            },
            {
                name: 'Diamonds',
                icon: absoluteUrl('static/images/icon-diamonds.svg')
            },
            {
                name: 'Opals',
                icon: absoluteUrl('static/images/icon-opals.svg')
            },
            {
                name: 'Pearl Strands',
                icon: absoluteUrl('static/images/icon-pearlStrands.svg')
            },
            {
                name: 'Pearls',
                icon: absoluteUrl('static/images/icon-pearls.svg')
            },
            {
                name: 'Colored Diamonds',
                icon: absoluteUrl('static/images/icon-coloredDiamonds.svg')
            }
        ];

        this.setListType = type => {
            let pricingSettings = this.props.pricing;
            pricingSettings.listType = type;
            pricingSettings.section = 'GemList';

            this.props.dispatch({
                type: 'SET_PRICING_STATE',
                props: pricingSettings
            });
        }
    }

    render() {

        return (
            <div className={`gemListSelector-wrap`}>
                <div className={`gemListSelector-title`}>Pricing Tables Type</div>
                <div className={`gemListSelector-types`}>
                    {
                        this.gemTypes.map( (type,index) =>
                            <div key={index} className={`gemListSelector-types-type`}
                                 onClick={ () => this.setListType( type.name ) }>
                                <div className="gemListSelector-types-type-icon">
                                    <Isvg src={type.icon}/>
                                </div>
                                <div className="gemListSelector-types-type-name">
                                    { type.name }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

GemListSelector.propTypes = {};

GemListSelector.defaultProps = {};


export default connect()(GemListSelector);
