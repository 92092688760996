import React from 'react';
import { connect } from 'react-redux';

import Color from './Color';
import Clarity from './Clarity';
import Brilliance from './Brilliance';
import Proportion from './Proportion';
import Polish from './Polish';

export default {
	Color,
	Clarity,
	Brilliance,
	Proportion,
	Polish
};
