import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';
import { routerReducer } from 'react-router-redux';
import reducers from 'shared/reducers';

const logger = createLogger();

const configureStore = ( initialState = {} ) => {
	const rootReducer = combineReducers({
		...reducers,
		routing: routerReducer
	});
	
	const store = createStore( rootReducer, initialState, compose(
		applyMiddleware(
			thunkMiddleware,
			logger
		),
		// Enable redux devtools
		typeof window === 'object' && typeof window.devToolsExtension !== 'undefined' ?
				window.devToolsExtension() : f => f
	) );

	return store;
};

export default configureStore;
