import $ from 'jquery';
import _ from 'underscore';
import JsonPrune from 'json-prune';
import WordpressOAuth from 'shared/classes/wordpress-oauth';

import settings from 'shared/settings';

export const isInternetExplorer = ( userAgent=null ) => {
	userAgent = ( userAgent && userAgent.toLowerCase() ) || ( navigator && navigator.userAgent && navigator.userAgent.toLowerCase());

	return ( userAgent.indexOf('msie') != -1 ) ? parseInt( userAgent.split('msie')[1] ) : false;
}

export const isServer = () =>
	Boolean( typeof process == 'object' && typeof window === 'undefined' );

export const isClient = () => ! isServer();

export const isCordova = () => Boolean( isClient() && window.IS_CORDOVA );

export const getDevice = () => (navigator.userAgent.match(/iPad/i))  == "iPad" ? "ipad" : (navigator.userAgent.match(/iPhone/i))  == "iPhone" ? "iphone" : (navigator.userAgent.match(/Android/i)) == "Android" ? "android" : (navigator.userAgent.match(/BlackBerry/i)) == "BlackBerry" ? "blackberry" : null;

export const isHeroku = () => Boolean( settings('DYNO') );

export const setCookie = (cname, cvalue, exsecs) => {
	const d = new Date();
	d.setTime(d.getTime() + ((exsecs || 1)*1000));
	let expires = "expires="+ d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for(let i = 0; i <ca.length; i++) {
	  let c = ca[i];
	  while (c.charAt(0) == ' ') {
		c = c.substring(1);
	  }
	  if (c.indexOf(name) == 0) {
		return c.substring(name.length, c.length);
	  }
	}
	return "";
}

export const setClientKey = (client_key) => {
	setCookie('client_key', client_key, 300)
}

export const setOAuthToken = (oauth_token) => {
	setCookie('oauth_token', oauth_token, 300)
}

export const setOAuthVerifier = (oauth_verifier) => {
	setCookie('oauth_verifier', oauth_verifier, 300)
}

export const getClientKey = () => {	
	return getCookie('client_key');
}

export const getOAuthToken = (oauth_token) => {
	return getCookie('oauth_token');
}

export const getOAuthVerifier = () => {
	return getCookie('oauth_verifier');
}

export const clearClientKey = () => {
	setCookie('client_key', '', 0)
}

export const clearOAuthVerifier = () => {
	setCookie('oauth_verifier', '', 0)
}

export const clearOAuthToken = () => {
	setCookie('oauth_token', '', 0)
}

export const clearOAuth = () => {
	clearClientKey();
	clearOAuthToken();
	clearOAuthVerifier();
}

export const toCamel = string =>
	String(string).replace(/\s/g, '').replace( /(^[A-Z])|(\-[a-z])/g, ($1, $2) => {
		if( $1.slice(0, 1) == '-' ) return $1.toUpperCase().replace('-','');
		if( $1.toUpperCase() == $1 ) return $1.toLowerCase();
	} );

export const toCss = (string, suffix) =>
	`${ toCamel( String(string) ) }${ suffix ? ('-'+suffix) : '' }`;

export const toClassName = string =>
	toCamel( String(string) ).replace( /^[a-z]/, ($1) => $1.toUpperCase() );

export const toKebob = string =>
	String(string).replace( /(^[A-Z])|([A-Z])/g, ($1, $2) => {
			if($2) {
				return $2.toLowerCase()
			}

			if ($1) {
				return `-${$1.toLowerCase()}`
			}

			return `-`
		}
	);

export const toTitleCase = string =>
	String(string).replace( /\w\S*/g, $1 => {
		let output = $1.charAt(0).toUpperCase()

		if ($1.length > 1){
			output += $1.substr(1).toLowerCase()
		}

		return output
	}
	);

export const absoluteUrl = (suffix='') =>
	`${settings('PROTOCOL')}://${settings('API_HOST')}${ isHeroku() || ! settings('PORT') ? '' : `:${settings('PORT')}` }/${ suffix.replace( /^\//, '' ) }`;

export const apiRoute = (className, method) =>
	`${settings('PROTOCOL')}://${settings('API_HOST')}${ isHeroku() || ! settings('PORT') ? '' : ':'+settings('PORT') }${settings('API_PREFIX')}/${toKebob(className)}/${toKebob(method)}`;

export const apiRequest = ( className, method, data={} ) =>
	$.ajax({
		contentType: 'application/json',
		url: apiRoute(className, method),
		type: 'POST',
		data: JSON.stringify(data),
		statusCode: {
			403: () => window.location.reload() // Reload the page when an API request is denied
		}
	})

// Pass arguments array in isomorphic methods
export const isApiRequest = args => ( args[1] ? ( args[1].url ? true : false ) : false );

// Pass arguments array in isomorphic methods
export const getRequestingUser = args => isApiRequest(args) ? args[1].user : null;

export const s3Route = ( method ) =>
	`${settings('PROTOCOL')}://${settings('API_HOST')}${ isHeroku() ? '' : ':'+settings('PORT') }${settings('S3_PREFIX')}/${toKebob(method)}`;

export const s3Request = ( method, data={} ) => {
	if( method === 'upload' )
		return $.ajax( {
			url: s3Route(method),
			type: 'POST',
			processData: false,
			contentType: false,
			data
		} );
	else if( method === 'delete' )
		return $.ajax( {
			url: s3Route(method),
			type: 'POST',
			data
		} );
	else {
		console.log( 's3Request - Error – Invalid Method : ' + method );
		return false;
	}
};

export const uniqId = ( length=10 ) => {
		let text = "";
		const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

		for( let i=0; i < length; i++ )
			text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
}

export const validateEmail = (email) => {
	// regex from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return re.test(email);
}

export const validateURL = (url) => {
	return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
}

export const getQueryVariable = (variable,url=false) => {
	if( url ) {
		return getQueryVariableFromString( variable, url );
	} else {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
        }
        return(false);
	}
};

export const getQueryVariableFromString = ( name, url ) => {
    if (!url) url = location.href;
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+name+"=([^&#]*)";
    var regex = new RegExp( regexS );


    var results = regex.exec( url );
    return results == null ? null : results[1];
}

export const documentTitle = prefix => {
	return `${ prefix ? `${prefix} - ` : '' }${settings('TITLE')}`;
}

export const deparam = () => {
	var params = {}, queries, temp, i, l;

		// Split into key/value pairs
		queries = window.location.hash.replace(/\#/,'').split('&');

		// Convert the array of strings into an object
		for ( i = 0, l = queries.length; i < l; i++ ) {
				temp = queries[i].split('=');
				params[temp[0]] = temp[1];
		}

		return params;
}

export const stringify = object => console.log( JsonPrune( object ) );

export const createCookie = (name,value,days) => {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
}

export const readCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export const getWordpressOAuth = (config={},pricesAPI=false) => {
	const url = settings('WORDPRESS_URL');

    if( pricesAPI ) {
        const api_oAuthAuthEndpointPath = settings('API_OAUTH_AUTH_ENDPOINT_PATH');
        const api_callbackURL = absoluteUrl( api_oAuthAuthEndpointPath );
        const apiKey = settings('API_OAUTH_CONSUMER_KEY');
        const apiSecret = settings('API_OAUTH_CONSUMER_SECRET');

		console.log('[getWordPressOAuth][API][INFO]', api_oAuthAuthEndpointPath, api_callbackURL, apiKey, apiSecret)

		if( url && api_callbackURL && apiKey && apiSecret ) {
            return new WordpressOAuth({
                ...config,
                url,
                callbackURL: api_callbackURL,
                credentials: {
                    ...config.credentials,
                    client: {
                        public: apiKey,
                        secret: apiSecret
                    }
                }
            });
        } else {
			console.log('[getWordPressOAuth][API][ERROR] config values are null', api_oAuthAuthEndpointPath, api_callbackURL, apiKey, apiSecret)

			return false;
        }
	} else {
        const oAuthAuthEndpointPath = settings('OAUTH_AUTH_ENDPOINT_PATH');
        const callbackURL = absoluteUrl( oAuthAuthEndpointPath );
        const key = settings('OAUTH_CONSUMER_KEY');
        const secret = settings('OAUTH_CONSUMER_SECRET');

		console.log('[getWordPressOAuth][INFO]', oAuthAuthEndpointPath, callbackURL, key, secret)

        if( url && callbackURL && key && secret ) {
            return new WordpressOAuth({
                ...config,
                url,
                callbackURL,
                credentials: {
                    ...config.credentials,
                    client: {
                        public: key,
                        secret
                    }
                }
            });
        } else {
			console.log('[getWordPressOAuth][ERROR] config values are null', api_oAuthAuthEndpointPath, api_callbackURL, apiKey, apiSecret)

			return false;
        }
	}

};

export const wordpressApiRequest = ( req, route, data, method='GET' ) => {
	try {
		const { user } = req;

        const isAPI = req.headers && req.headers.referer && req.headers.referer.indexOf( 'api_oauth_auth_endpoint' ) !== -1;

		if( user ) {
			const { oAuthVerifier } = user;

			console.log("[wordpressApiRequest][INFO] config", oAuthVerifier, user, route, data, method)

			if( oAuthVerifier ) {
				const token = global.OAUTH_AUTH_TOKENS[oAuthVerifier];

				console.log("[wordpressApiRequest][INFO] token", token)

				if( token ) {
					const wordpressOAuth = getWordpressOAuth( {
						credentials: { token }
					}, isAPI );


					return new Promise( ( resolve, reject ) =>
						wordpressOAuth.request( method, route, data )
							.then( result => resolve( result ) )
							.catch( error => {
								console.log("[wordpressApiRequest][ERROR]", error, method, route, data, result)

								if( typeof( error ) === 'object' ) {
									const { code, message } = error;

									if( code && message ) {
										reject(`Wordpress rejected this request with the error code ${code} and the message: ${message}`);
									} else {
										reject( JSON.stringify( error ) );
									}
								} else {
									reject(`Network error when trying to connect the the Wordpress API`);
								}
							})
					);
				} else {
					console.log("[wordpressApiRequest][ERROR]", "OAuth access token is not available on the server. The process may have restarted just before the user made this request?")

					throw new Error(`OAuth access token is not available on the server. The process may have restarted just before the user made this request?`);
				}
			} else {
				console.log("[wordpressApiRequest][ERROR]", "OAuth verifier is not defined in user object provided by the requester")

				throw new Error(`OAuth verifier is not defined in user object provided by the requester`);
			}
		} else {
			console.log("[wordpressApiRequest][ERROR]", "User is not defined in request object")
			
			throw new Error(`User is not defined in request object`);
		}
	}
	catch( error ) {
		console.log(error);

		return false;
	}
}

export const oAuthCredentialsKeyName = 'gemguideOAuthCredentials';

export const saveOAuthCredentials = ( credentials ) => {
	if( isServer() ) {
		console.log("[saveOAuthCredentials][INFO]", oAuthVerifier, credentials)

		return oAuthVerifier => global.OAUTH_AUTH_TOKENS[oAuthVerifier] = credentials;
	} else {
		return rememberMe => {
			const storage = rememberMe ? window.localStorage : window.sessionStorage;

			storage.setItem( oAuthCredentialsKeyName, JSON.stringify( credentials ) );
		}
	}
};

export const getOAuthCredentials = () => {
	if( isServer() ) {
		console.log("[getOAuthCredentials][INFO]", oAuthVerifier, global.OAUTH_AUTH_TOKENS[oAuthVerifier])

		return oAuthVerifier => global.OAUTH_AUTH_TOKENS[oAuthVerifier];
	} else {
		return (
			window.sessionStorage.getItem( oAuthCredentialsKeyName )
		) ? (
			window.sessionStorage.getItem( oAuthCredentialsKeyName )
		) : (
			window.localStorage.getItem( oAuthCredentialsKeyName )
		);
	}
};

export const eraseCookie = (name) => {
    createCookie(name,"",-1);
}

// Corresponds to Foundation breakpoints in ~/source/scss/main.scss
export const breakpoints = {
	small: 0,
	medium: 751,
	large: 1025,
	xlarge: 1200,
	xxlarge: 1440
};

export const isMobile = () => window.innerWidth <= breakpoints.medium;

export const isTablet = () => window.innerWidth < breakpoints.large;

// The value in position [0] is exclusive
// The value in position [1] is inclusive
// Eg: An absolute value of 10 is in the R sector but and absolute value of 20 is in the YR sector
// The absolute values 0 and 100 are the same hue value
export const sectorValues = {
	'R': [ 0, 10 ],
	'YR': [ 10, 20 ],
	'Y': [ 20, 30 ],
	'GY': [ 30, 40 ],
	'G': [ 40, 50 ],
	'BG': [ 50, 60 ],
	'B': [ 60, 70 ],
	'PB': [ 70, 80 ],
	'P': [ 80, 90 ],
	'RP': [ 90, 100 ]
};

export const saturationRange = [ 2, 18 ];

export const toneRange = [ 2, 9 ];

// Conversions

export const relativeHueToAbsolute = hue => {
	const colorSector = hue.match(/(\d+\.*\d*)(\w+)/)[2];
	const colorSection = Number( hue.match(/(\d+\.*\d*)(\w+)/)[1] );

	return sectorValues[colorSector][0] + colorSection;
}

export const absoluteHueToSector = hue => {
	if( _.isNaN(hue) || hue < 0 || hue > 100 )
		throw new Error(`\`hue\` must be a valid Munsell hue`);

	// The absolute values 0 and 100 are the same hue value
	hue = hue == 0 ? 100 : hue;

	return _.findKey( sectorValues, sectorRange =>
		hue > sectorRange[0] && hue <= sectorRange[1]
	);
}

export const absoluteHueToNotation = hue => {
	const sectorName = absoluteHueToSector(hue);

	const position = hue - sectorValues[sectorName][0];

	return `${position}${sectorName}`;
}

export const munsellToAngle = munsellHue => munsellHue * 3.6 + 72;

export const angleToRadians = angle => angle * Math.PI / 180;

export const angleToCssHue = angle => angle - 90;

export const munsellToCssHue = munsellHue => angleToCssHue( munsellToAngle( munsellHue ) );

export const giaSaturationToCssSaturation = giaSaturation => ( giaSaturation - saturationRange[0] ) * 80 / ( saturationRange[1] - saturationRange[0] ) + 10;

export const giaToneToCssLightness = giaTone => ( giaTone - toneRange[0] ) * 80 / ( toneRange[1] - toneRange[0] ) + 10;

// Store getters

export const getColorFromStore = ( colors, id ) => _.find( colors, color => id == color.id ) || {};

export const getPriceInUserCurrency = ( price, pricing, user ) => {
	let priceInUserCurrency;

	try {
		const userCurrency = user.profile.currency;

		if( userCurrency ) {
			priceInUserCurrency = price * pricing.exchangeRates[userCurrency];
		} else {
			throw new Error(`User does not have a currency set`);
		}
	}
	catch( error ) {
		console.log( error );

		priceInUserCurrency = price;
	}

	return priceInUserCurrency;
};

export const getCurrencySymbol = ( pricing, user ) => {
	let currencySymbol;

	try {
		const userCurrency = user.profile.currency;

		if( userCurrency ) {
			currencySymbol = pricing.currencySymbols[userCurrency] || userCurrency;
		} else {
			throw new Error(`User does not have a currency set`);
		}
	}
	catch( error ) {
		console.log( error );

		currencySymbol = null;
	}

	return currencySymbol;
}

export const getRetailMarkup = ( user, gemType, wholesalePrice ) => {
	let retailMarkup, markupRangeIndex;

	try {
		if( ! gemType ) {
			throw new Error(`Gem type must be defined`);
		}

		if(
			! user.profile[`multiplier_${gemType}`] &&
			gemType !== 'diamonds' &&
			gemType !== 'colored_gemstones'
		) {
			throw new Error(`${gemType} is not a valid gem type`);
		}

		if( ! wholesalePrice && ( gemType === 'diamonds' || gemType === 'colored_gemstones' ) ) {
			throw new Error(`${gemType} requires the wholesale price to be passed as the third argument`);
		}

		if( wholesalePrice ) {
			markupRangeIndex = _.findKey( priceBasedMarkupRanges, range =>
				range.from <= wholesalePrice && range.to >= wholesalePrice
			);
		}

		if( gemType === 'diamonds' ) {
			retailMarkup = Number( user.profile[`diamond_markup_${markupRangeIndex}`] );
		} else if( gemType === 'colored_gemstones' ) {
			retailMarkup = Number( user.profile[`stone_markup_${markupRangeIndex}`] );
		} else {
			retailMarkup = Number( user.profile[`multiplier_${gemType}`] );
		}
	}
	catch( error ) {
		console.log( error );

		retailMarkup = 1;
	}

	return retailMarkup;
}

export const priceBasedMarkupRanges = {
	0: {
		from: 1,
		to: 249
	},
	1: {
		from: 250,
		to: 499
	},
	2: {
		from: 500,
		to: 999
	},
	3: {
		from: 1000,
		to: 1499
	},
	4: {
		from: 1500,
		to: 2999
	},
	5: {
		from: 3000,
		to: 4999
	},
	6: {
		from: 5000,
		to: 7499
	},
	7: {
		from: 7500,
		to: 9999
	},
	8: {
		from: 10000,
		to: 14999
	},
	9: {
		from: 15000,
		to: 19999
	},
	10: {
		from: 20000,
		to: 24999
	},
	11: {
		from: 25000,
		to: 49999
	},
	12: {
		from: 50000,
		to: 74999
	},
	13: {
		from: 75000,
		to: 99999
	},
	14: {
		from: 100000,
		to: 499999
	}
};

export const savePricingLogs = ( result, prices, url, status_code,data) => {console.log("in saving")
    const event = new global.DB.Pricing_Request_Logs({
        user_id: result.id || 0,
        user_name: result.name ||'',
        user_wordpress_id: result.user_wordpress_id || 0,
        request_url: url,
        response_status_code: status_code,
        api_response: prices,
		arguments:data
        // name:data.name,
        // weight:data.weight,
        // color:data.color,
        // clarity:data.clarity,
        // cut:data.cut

    });
    event
    .save()
    .then(savedEvent => {
        console.log("Event has been recorded")
        return savedEvent
    })
    .catch(err => {
        console.log("err",err) 
        return err;
    })
}