// Tracks gem info globally

import _ from 'underscore';

export default ( state = {}, { type, props, status, price, priceError } ) => {
	switch(type) {
		case 'SET_GEM_PROPS' :
			// Sanitize props
			if( props.hue ) props.hue = Number( props.hue );
			if( props.tone ) props.tone = Number( props.tone );
			if( props.saturation ) props.saturation = Number( props.saturation );
			if( props.color ) props.color = Number(props.color);
			if( props.inclusions ) props.inclusions = Number(props.inclusions);
			if( props.texture ) props.texture = Number(props.texture);
			if( props.crownHeight ) props.crownHeight = Number(props.crownHeight);
			if( props.pavillionDepth ) props.pavillionDepth = Number(props.pavillionDepth);

			if( props.customGrade ) {
				props.customGrade = Number( props.customGrade );

				if( props.customGrade < 1 ) props.customGrade = 1;
				if( props.customGrade > 10 ) props.customGrade = 10;
			}

			const newState = {
				...state,
				...props
			};

			// Brilliance props must always sum to 100
			// The order of the array represents priority in the case where multiple
			// brilliance props change simultaneously and also for rounding
			const brilliancePropNames = [ 'brilliance', 'windowing', 'extinction' ];
			const equalizingPropNames = _.difference( brilliancePropNames, _.keys( props ) );
			const changingPropNames = _.intersection( brilliancePropNames, _.keys( props ) );
			const minBrillianceSum = 0;
			const maxBrillianceSum = 100;
			const roundTo = 5;

			let brillianceSum = 0;

			// Bring the brilliance props into equilibrium
			changingPropNames.forEach( changingPropName => {
				while ( brillianceSum < maxBrillianceSum || brillianceSum > maxBrillianceSum ) {
					brillianceSum = brilliancePropNames.reduce(
						( brillianceSum, brilliancePropName ) => (
							brillianceSum + newState[brilliancePropName]
						),
						0
					);

					if( brillianceSum !== maxBrillianceSum ) {
						const brillianceDifference = maxBrillianceSum - brillianceSum;

						let otherPropNames = _.without( equalizingPropNames, changingPropName );

						const numPropsThatWillChange = otherPropNames.reduce(
							( numPropsThatWillChange, otherPropName ) => {
								if(
									(
										brillianceDifference > 0 &&
										newState[otherPropName] < maxBrillianceSum
									) ||
									(
										brillianceDifference < 0 &&
										newState[otherPropName] > minBrillianceSum
									)
								) {
									numPropsThatWillChange = numPropsThatWillChange + 1;
								}

								return numPropsThatWillChange;
							},
							0
						);


						otherPropNames.forEach( otherPropName => {
							let adjustmentAmount;

							if(
								_.contains( equalizingPropNames, 'brilliance' ) &&
								newState['brilliance'] + brillianceDifference >= minBrillianceSum &&
								newState['brilliance'] + brillianceDifference <= maxBrillianceSum
							) {
								if( otherPropName === 'brilliance' ) {
									adjustmentAmount = brillianceDifference;
								} else {
									adjustmentAmount = 0;
								}
							} else {
								adjustmentAmount = brillianceDifference / numPropsThatWillChange;
							}

							let otherPropValue = newState[otherPropName] + adjustmentAmount;

							if( otherPropValue < minBrillianceSum ) otherPropValue = minBrillianceSum;
							if( otherPropValue > maxBrillianceSum ) otherPropValue = maxBrillianceSum;

							newState[otherPropName] = otherPropValue;
						});
					}
				}
			});

			// Round the brilliance props
			const changingPropTotal = changingPropNames.reduce(
				( changingPropTotal, changingPropName ) => (
					changingPropTotal + newState[changingPropName]
				),
				0
			);

			equalizingPropNames.reverse().forEach(
				( equalizingPropName, index ) => {
					const currentTotal = equalizingPropNames.reduce(
						( currentTotal, equalizingPropName, _index ) => (
							_index < index ? currentTotal + newState[equalizingPropName] : currentTotal
						),
						changingPropTotal
					);

					let roundedValue;

					const modulus = newState[equalizingPropName] % roundTo;
					const roundAction = modulus < roundTo / 2 ? (
						index % 2 ? Math.floor : Math.ceil
					) : (
						index % 2 ? Math.ceil : Math.floor
					);

					roundedValue = roundAction( newState[equalizingPropName] / roundTo ) * roundTo;

					if(
						index + 1 === equalizingPropNames.length &&
						currentTotal + roundedValue !== maxBrillianceSum
					) {
						roundedValue = maxBrillianceSum - currentTotal;
					}

					newState[equalizingPropName] = roundedValue;
				}
			);

			// Brilliance value cannot be less than to value rounded to
			if( newState['brilliance'] < roundTo ) {
				newState['brilliance'] = roundTo;

				let isRedistributed = false;

				_.without( brilliancePropNames, 'brilliance' ).forEach( brilliancePropName => {
					if( ! isRedistributed && newState[brilliancePropName] + roundTo <= maxBrillianceSum ) {
						newState[brilliancePropName] = newState[brilliancePropName] - roundTo;

						isRedistributed = true;
					}
				});
			}

			return newState;

		case 'FETCH_GEM_PRICE' :
			switch(status) {
				case 'error' :
					return {
						...state,
						isFetchingPrice: false,
						priceError
					};

				case 'success' :
					return {
						...state,
						isFetchingPrice: false,
						priceError: null,
						price
					};

				default :
					return {
						...state,
						isFetchingPrice: true
					};
			}

		default :
			return state;
	}
};