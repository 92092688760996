import _ from 'underscore';
import React from 'react';
import { connect } from 'react-redux';
import Layout from 'shared/components/Layout';
import GemList from 'shared/components/GemList';
import GemListSelector from 'shared/components/GemListSelector';
import PricingTable from 'shared/components/PricingTable';
import { isMobile } from 'shared/utilities';

class PricingLayout extends Layout {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ready: false
        }

        this.getGems = type => {
            if( type === 'Colored Gemstones' )
                return this.props.gems;
            else if( type === 'Diamonds' )
                return this.props.pricing.diamondShapes;
            else if( type === 'Opals' )
                return this.props.pricing.opals;
            else if( type === 'Pearl Strands' )
                return this.props.pricing.pearlStrands;
            else if( type === 'Pearls' )
                return this.props.pricing.pearls;
            else if( type === 'Colored Diamonds' )
                return this.props.pricing.coloredDiamonds;
        };
    }

    componentDidInitialize() {
        this.setState({ ready: true });
    }

    displayLayout() {
        return (
            this.state.ready ? (
                <div className={`pricingWrap ${ isMobile() ? 'pricingWrap--mobile' : '' }`}>
                    <div className={ 'pricingWrap-inner ' + this.props.pricing.section }>
                        <section className="pricingWrap-inner-gemListSelector">
                            <GemListSelector
                                userId={ this.props.loggedInUser.id }
                                pricing={ this.props.pricing }/>
                        </section>
                        <section className="pricingWrap-inner-gemList">
                            <GemList
                                userId={ this.props.loggedInUser.id }
                                pricing={ this.props.pricing }
                                gems={ this.getGems( this.props.pricing.listType ) } />
                        </section>
                        <section className="pricingWrap-inner-pricingTable">
                            <PricingTable
                                pricing={ this.props.pricing }
                                gem={ this.props.gem }
                                diamond={ this.props.diamond }/>
                        </section>
                    </div>
                </div>
            ) : null
        );
    }
}

PricingLayout.propTypes = {
    gems: React.PropTypes.array,
};

PricingLayout.defaultProps = {
    ...PricingLayout.defaultProps,
    gems: []
};

export default connect()(PricingLayout);
