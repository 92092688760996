// Tracks settings for pricing sections
import Diamond from 'shared/components/Diamond';
import Opal from 'shared/components/Opal';
import PearlStrand from 'shared/components/PearlStrand';
import ColoredDiamond from 'shared/components/ColoredDiamond';
import Pearl from 'shared/components/Pearl';

export default ( state = {}, { type, props } ) => {
	switch(type) {
		case 'SET_PRICING_STATE' :
			return { ...props };
		default :
			if( ! state || ! state.initialized ) {
				state = state || {};
				
				state = {
					initialized: true,
					currency: 'USD',
					exchangeRates: false,
					section: 'GemListSelector',
					listType: 'Colored Gemstones',
					diamondShapes: Diamond.shapes.map( ({ id, label }) => ({ id, name: label }) ),
					opals: Opal.types.map( ({ id, label }) => ({ id, name: label }) ),
					pearlStrands: PearlStrand.types.map( ({ id, label }) => ({ id, name: label }) ),
					coloredDiamonds: ColoredDiamond.types.map( ({ id, label }) => ({ id, name: label }) ),
					pearls: Pearl.types.map( ({ id, label }) => ({ id, name: label }) ),
					presetSelection: false,
					presetActive: false,
					selection: false,
					selectedRow: {
						selected: false,
						minWeight: false,
						maxWeight: false,
						color: false
					},
					selectedColumn: {
						selected: false,
						grade: false,
						clarity: false
					},
					selectedCell: {
						selected: false,
						minWeight: false,
						maxWeight: false,
						grade: false,
						color: false,
						clarity: false
					},
					currencySymbols: {
						USD: '$',
						AUD: 'A$',
						BGN: 'лв',
						BRL: 'R$',
						CAD: 'C$',
						CHF: 'Fr',
						CNY: '¥',
						CZK: 'Kč',
						DKK: 'kr',
						GBP: '£',
						HKD: 'HK$',
						HRK: 'kn',
						HUF: 'Ft',
						IDR: 'Rp',
						ILS: '₪',
						INR: '₹',
						JPY: '¥',
						KRW: '₩',
						MXN: '$',
						MYR: 'RM',
						NOK: 'kr',
						NZD: '$',
						PHP: '₱',
						PLN: 'zł',
						RON: 'L',
						RUB: '₽',
						SEK: 'kr',
						SGD: '$',
						THB: '฿',
						TRY: '₺',
						ZAR: 'R',
						EUR: '€',
					},
					...state
				};
			}
			return state;
	}
};