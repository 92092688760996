import React from 'react';
import _ from 'underscore';
import User from 'shared/components/User';
import { isServer, apiRequest, uniqId, isApiRequest, getRequestingUser } from 'shared/utilities';

class DbComponent extends React.Component {
	constructor(props) {
		super();

		// Instantiate with "artificial" props for DB queries
		if(props) this.props = props;

		this.apiRequest = (method, data) =>
			apiRequest( this.constructor.name, method, data );

		if( isServer() ) {
			this.model = global.DB[this.constructor.name];
		}
	}

	// Associations that are eagerly loaded by default
	// Array of model names as strings
	// If the include key is defined then these will not be applied
	defaultInclude() {
		return [];
	}

	// Merges the default includes with the input
	include(include=[]) {
		const mergedIncludes = this.defaultInclude().concat( include );

		let i = 0;

		return _.reject( mergedIncludes, (item) => {
			i++;

			for(let _i = i; _i < mergedIncludes.length; _i++) {
				if( item.model === mergedIncludes[_i].model ) return true
			}
		});
	}

	unscoped() {
		if( isServer() ) this.model = this.model.unscoped();

		return this;
	}

	// Getter method that takes into account the possibility that render
	// props may be contained in a 'where' key if the component was
	// initialized for some database queries
	getProp(propName) {
		if( this.props ) {
			if( typeof this.props[propName] != 'undefined' ) {
				return this.props[propName];
			} else if( this.props.where && typeof this.props.where[propName] != 'undefined' ) {
				return this.props.where[propName];
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	// Attempt to find the meta locally in the current object
	getMetaProp(key) {
		const metasProp = this.getProp('meta');

		const metaProp = _.findWhere( metasProp, { key } );

		return metaProp ? JSON.parse(metaProp.value) : null;
	}

	// Override in specific model implentations
	// Returns a promise that resolves to the id of the user who is the owner
	// of the meta data with the matching meta key/foreign key pair
	getMetaOwner(id, key) {
		return new Promise( resolve => resolve(id) );
	}

	// Attempt to find the meta in the database
	getMeta(key) {
		if( isServer() ) {
			if( ! this.model.associations.meta.foreignKey )
				throw new Error(`Exception in ${this.constructor.name}#getMeta: ${this.constructor.name} does not support meta`);

			var key = arguments[0].key || key;
			const id = arguments[0].id || this.getProp('id');
			const isThisApiRequest = isApiRequest(arguments);

			const requestingUser = isThisApiRequest ? arguments[1].user : null;

			const isPrivate = _.contains( this.constructor.privateMeta, key );

			return new Promise( (resolve, reject) => {
				const doGetMeta = () => {
					this.findByPk( { id } ).then( model =>
						model ? model.getMeta({ where: { key } }).then( meta =>
							resolve( meta[0] ? JSON.parse(meta[0].value) : null )
						) : resolve(null)
					)
				};

				this.getMetaOwner(id, key).then( metaOwner => {
					// Only a meta value's "owner" or an admin/moderator can access private meta
					if( ! isThisApiRequest || ! isPrivate || isPrivate && requestingUser == metaOwner ) {
						doGetMeta();
					} else if( requestingUser ) {
						doGetMeta();
					} else {
						reject({
							response: 403,
								error: new Error(`Requester must be identified`)
						})
					}
				})
			});
		} else {
			return this.apiRequest( 'getMeta', { id: this.getProp('id'), key } );
		}
	}

	setMeta(key, value) {
		if( isServer() ) {
			if( ! this.model.associations.meta.foreignKey )
				throw new Error(`Exception in ${this.constructor.name}#setMeta: ${this.constructor.name} does not support meta`);

			const key = arguments[0].key;
			const id = arguments[0].id || this.getProp('id');
			const isThisApiRequest = isApiRequest(arguments);

			const requestingUser = isThisApiRequest ? arguments[1].user : null;

			const isPrivate = _.contains( this.constructor.privateMeta, key );

			return new Promise( resolve => {
				const doSetMeta = () => {
					global.DB.Meta.upsert({
						key,
						value: isThisApiRequest ? arguments[0].value : JSON.stringify(arguments[0].value),
						[ this.model.associations.meta.foreignKey ]: id
					}).then( resolve )
				};

				this.getMetaOwner(id, key).then( metaOwner => {
					// Only a meta value's "owner" or an admin/moderator can access private meta
					if( ! isThisApiRequest || ! isPrivate || isPrivate && requestingUser == metaOwner ) {
						doSetMeta();
					} else if( requestingUser ) {
						doSetMeta();
					} else {
						reject({
							response: 403,
								error: new Error(`Requester must be identified`)
						})
					}
				});
			});
		} else {
			return this.apiRequest( 'setMeta', { id: this.getProp('id'), key, value: JSON.stringify(value) } );
		}
	}

	removeMeta(key) {
		if( isServer() ) {
			if( ! this.model.associations.meta.foreignKey )
				throw new Error(`Exception in ${this.constructor.name}#removeMeta: ${this.constructor.name} does not support meta`);

			const key = arguments[0].key || key;
			const id = arguments[0].id || this.getProp('id');
			const isThisApiRequest = isApiRequest(arguments);

			const where = {
				key,
				[ this.model.associations.meta.foreignKey ]: id
			};

			const requestingUser = isThisApiRequest ? arguments[1].user : null;

			const isPrivate = _.contains( this.constructor.privateMeta, key );

			return new Promise( resolve => {
				const doRemoveMeta = () => {
					global.DB.Meta.destroy({ where }).then( numRemoved =>
						resolve( numRemoved )
					)
				};

				this.getMetaOwner(id, key).then( metaOwner => {
					// Only a meta value's "owner" or an admin/moderator can access private meta
					if( ! isThisApiRequest || ! isPrivate || isPrivate && requestingUser == metaOwner ) {
						doRemoveMeta();
					} else if( requestingUser ) {
						doRemoveMeta();
					} else {
						reject({
							response: 403,
								error: new Error(`Requester must be identified`)
						})
					}
				});
			});
		} else {
			return this.apiRequest( 'removeMeta', { id: this.getProp('id'), key } );
		}
	}

	// No security checks required for this method
	count(options={}) {
		if( isServer() ) {
			return this.model.count( arguments[0] );
		} else {
			return this.apiRequest( 'count', options );
		}
	}

	findAll(options={}) {
		if( isServer() ) {
			this.anonymousLimit || 10; // Anonymous users will be limited to querying this many rows

			const isThisApiRequest = isApiRequest(arguments);
			const requestingUser = getRequestingUser(arguments);

			let options = arguments[0] && arguments[0].options ? arguments[0].options : arguments[0];

			options = options || {};
			options.include = options.include === undefined ? this.defaultInclude() : options.include;
			options.limit = isThisApiRequest && ! requestingUser ? this.anonymousLimit : options.limit;

			return this.model.findAll( options );
		} else {
			options.include = options.include || this.defaultInclude();

			return this.apiRequest( 'findAll', options );
		}
	}

	// Note that all arguments are passed as an object on the server
	findByPk(id, options={}) {
		if( isServer() ) {
			arguments[0].options = arguments[0].options || {};
			arguments[0].options.include = arguments[0].options.include || this.defaultInclude();

			return this.model.findByPk( arguments[0].id, arguments[0].options );
		} else {
			return this.apiRequest( 'findByPk', { id, options } );
		}
	}

	// Search for a single instance that matches the props.
	// This applies LIMIT 1, so the listener will always be called with a single instance.
	find(options={}) {
		options.where = _.extend( ( this.props || {} ), options.where );
		options.include = options.include || this.defaultInclude();

		if( isServer() ) {
			return this.model.find( options );
		} else {
			return this.apiRequest( 'find', options );
		}
	}

	// Requester must be a valid logged in user
	create(values, options={}) {
		if( isServer() ) {
			const isThisApiRequest = isApiRequest(arguments);
			const requestingUser = getRequestingUser(arguments);

			// Solve SequelizeUniqueConstraintError
			arguments[0].values.unique = uniqId();

			return new Promise( (resolve, reject) => {
				const doCreate = () => {
					this.model.create(
						arguments[0].values,
						arguments[0].options
					).then( instance =>
						resolve(instance)
					);
				};

				if( ! isThisApiRequest ) {
					doCreate();
				} else if( requestingUser ) {
					global.DB.User.findByPk(requestingUser).then( user => {
						if( user ) {
							doCreate();
						} else {
							reject({
								response: 403,
									error: new Error('Requester is not a valid user')
							})
						}
					});
				} else {
					reject({
						response: 403,
							error: new Error('Requester must be identified')
					})
				}
			});
		} else {
			return this.apiRequest( 'create', { values, options } );
		}
	}

	// Requester must be a valid logged in user
	update(values, options={}) {
		if( isServer() ) {
			const isThisApiRequest = isApiRequest(arguments);
			const requestingUser = getRequestingUser(arguments);

			if( ! arguments[0].options.where )
				throw new Error('Update method options requires where key');

			return new Promise( (resolve, reject) => {
				const doUpdate = () => {
					this.model.update( arguments[0].values, arguments[0].options ).then( resolve )
				};

				if( ! isThisApiRequest ) {
					doUpdate();
				} else if( requestingUser ) {
					if(
						arguments[0].options &&
						arguments[0].options.where &&
						arguments[0].options.where.id
					) {
						global.DB.User.findByPk(requestingUser).then( user => {
							if( user ) {
								doUpdate();
							} else {
								reject({
									response: 403,
										error: new Error('Requester is not a valid user')
								})
							}
						});
					} else {
						reject({
							status: 403,
							error: new Error(`API update requests must specify a UUID`)
						});
					}
				} else {
					reject({
						response: 403,
						error: new Error('Requester must be identified')
					});
				}
			});
		} else {
			return this.apiRequest( 'update', { values, options } );
		}
	}

	// Requester must be a valid logged in user
	upsert(values, options={}) {
		if( isServer() ) {
			const isThisApiRequest = isApiRequest(arguments);
			const requestingUser = getRequestingUser(arguments);

			const { values, options } = arguments[0]

			return new Promise( (resolve, reject) => {
				const doUpsert = () => {
					this.model.upsert( values, options ).then( resolve )
				};

				if( ! isThisApiRequest ) {
					doUpsert();
				} else if( requestingUser ) {
					global.DB.User.findByPk(requestingUser).then( user => {
						if( user ) {
							doUpsert();
						} else {
							reject({
								response: 403,
								error: new Error('Requester is not a valid user')
							})
						}
					});
				} else {
					reject({
						response: 403,
						error: new Error('Requester must be identified')
					})
				}
			});

			return
		} else {
			return this.apiRequest( 'upsert', { values, options } );
		}
	}

	// Requester must be a valid logged in user
	destroy(options={}) {
		options.where = options.where || _.extend( ( this.props && this.props.id ? { id: this.props.id } : {} ), options.where );
		options.limit = typeof options.limit == 'undefined' || isApiRequest(arguments) ? 1 : options.limit;

		if( isServer() ) {
			const isThisApiRequest = isApiRequest(arguments);
			const requestingUser = getRequestingUser(arguments);

			return new Promise( (resolve, reject) => {
				const doDestroy = () => {
					this.model.destroy( options ).then( resolve )
				};

				if( ! isThisApiRequest ) {
					doDestroy();
				} else if( requestingUser ) {
					global.DB.User.findByPk(requestingUser).then( user => {
						if( user ) {
							doDestroy();
						} else {
							reject({
								response: 403,
								error: new Error('Requester is not a valid user')
							})
						}
					});
				} else {
					reject({
						response: 403,
						error: new Error('Requester must be identified')
					})
				}
			});
		} else {
			return this.apiRequest( 'destroy', options );
		}
	}

	// Iterates over requiredFields to determine if this user has all required database fields
	// Returns true if all fields are valid
	// Returns a route to redirect to if a field is found to be invalid
	getMissingRequiredFieldRoute() {
		const id = this.getProp('id') || arguments[0].id;
		const requiredFields = this.constructor.requiredFields;

		if( ! id )
			throw new Error(`${this.constructor.name}#getMissingRequiredFieldRoute requires the id to be set in props`);

		if( ! requiredFields ) return null;

		if( isServer() ) {
			return new Promise( resolve =>
				this.findByPk({ id }).then( user => {
					// Check that the required fields are formatted properly and
					// wrap them all in promises if they aren't already promises
					const requiredFieldsPromises = requiredFields.map( value =>
						value.then ? value(user) : new Promise( resolve => resolve( value(user) ) )
					);

					Promise.all(requiredFieldsPromises).then( results =>
						results.some( route => {
							// If the value is invalid, resolve to provided route
							if( route ) return resolve( route );
						} ) ? null : resolve(false)
					);
				} )
			);
		} else {
			return this.apiRequest( 'getMissingRequiredFieldRoute', { id } )
		}
	}
};

// An array of meta keys that only the user and moderators can access
DbComponent.privateMeta = [
	'last_login'
];

export default DbComponent;
