import _ from 'underscore';
import DbComponent from 'shared/components/DbComponent';
import {
	isServer,
	apiRequest,
	isApiRequest,
	getRequestingUser,
} from 'shared/utilities';

class Color extends DbComponent {
	constructor(props) {
		super();

		// Instantiate with "artificial" props for DB queries
		if(props) this.props = props;

		this.apiRequest = (method, data) =>
			apiRequest( 'Color', method, data );

		if( isServer() ) {
			this.model = global.DB['Color'];
		}
	}
	// findColors() {
	// 	const { hue, tone, saturation } = arguments[0];

	// 	if( isServer() ) {
  //    const {gte, lt, in: opIn} = global.DB.Sequelize.Op;

	// 		return new Promise( (resolve, reject) => {
	// 			const doFindColors = () => {
	// 				this.model.findAll({
	// 					where: {
	// 						min_hue: { [gte]: hue },
	// 						max_hue: { [lt]: hue },
	// 						min_tone: { [gte]: tone },
	// 						max_tone: { [lt]: tone },
	// 						min_saturation: { [gte]: saturation },
	// 						max_saturation: { [lt]: saturation }
	// 					}
	// 				}).then(
	// 					colors => resolve(colors)
	// 				);
	// 			}
	// 		});
	// 	} else {
	// 		if( ! hue || ! tone || ! saturation ) {
	// 			throw new Error(`Invalid argument passed to Color#findColors: object must define hue, tone and saturation properties`);
	// 		}

	// 		return this.apiRequest( 'findColors', arguments[0] );
	// 	}
	// }
}

// Returns an array of color objects that encompass the passed color
Color.getColorOptions = (colors=[], colorValues) => {
	const { hue, tone, saturation } = colorValues;

	let colorOptions = colors.filter(
		color => (
			color.min_hue <= hue &&
			color.max_hue >= hue &&
			color.min_tone <= tone &&
			color.max_tone >= tone &&
			color.min_saturation <= saturation &&
			color.max_saturation >= saturation
		)
	);

	colorOptions.push({
		id: 0,
		name: 'Other'
	});

	return colorOptions;
}

Color.toneRange = [ 2, 9 ];
Color.saturationRange = [ 2, 16 ]

export default Color;
