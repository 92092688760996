// Tracks which user is currently logged in

export default ( state = 0, { type, user } ) => {
	switch(type) {
		case 'UPDATE_USER' :
			return {
				...state,
				...user
			};
		default :
			return state;
	}
};