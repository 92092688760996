import React from 'react';
import _ from 'underscore';
import { isServer, isApiRequest, getRequestingUser, apiRequest,savePricingLogs } from 'shared/utilities';

class Opal extends React.Component {
	apiRequest(method, data) {
		return apiRequest( 'Opal', method, data );
	}

	getPrices() {
		const isThisApiRequest = isApiRequest(arguments);
		const requestingUser = getRequestingUser(arguments);

		let { type } = arguments[0];

		if( isServer() ) {
			return new Promise( ( resolve, reject ) => {
				const doGetPrices = () => {
					global.DB.Price.findAll({
						attributes: [
							'id',
							'price',
							'min_weight',
							'max_weight',
							'grade',
							'opal_type',
							'created_at',
							'updated_at'
						],
						where: {
							opal_type: type
						}
					}).then( prices => {
						if( ! prices ){
							savePricingLogs(arguments[1].user, null, arguments[1].url, 200 , arguments[0])
							return resolve( null );
						} 

						return resolve( prices );
					}).catch( error =>
						reject({ status: 500, error })
					)
				};

				if( isThisApiRequest ) {
					if( requestingUser ) {
						doGetPrices();
					} else {
						reject({
							status: 403,
							error: new Error(`Requester must be identified`)
						});
					}
				} else {
					doGetPrices();
				}
			})
		} else {
			return this.apiRequest( 'getPrices', arguments[0] );
		}
	}
}

Opal.types = [
	{
		id: 1,
		label: 'Black Opal Green-Blue',
		importKey: 'BLACK OPAL GREEN-BLUE'
	},
	{
		id: 2,
		label: 'Black Opal Red-Orange',
		importKey: 'BLACK OPAL RED-ORANGE'
	},
	{
		id: 3,
		label: 'Boulder Opal',
		importKey: 'BOULDER BLACK OPAL'
	},
	{
		id: 4,
		label: 'Crystal Black Opal',
		importKey: 'CRYSTAL BLACK OPAL'
	},
	{
		id: 5,
		label: 'Fire Opal',
		importKey: 'FIRE OPAL'
	},
	{
		id: 6,
		label: 'Jelly Opal',
		importKey: 'JELLY OPAL'
	},
	{
		id: 7,
		label: 'Semi Crystal Opal',
		importKey: 'SEMI CRYSTAL OPAL'
	},
	{
		id: 8,
		label: 'White Opal',
		importKey: 'WHITE OPAL'
	}
]

export default Opal;
