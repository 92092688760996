import _ from 'underscore'
import React from 'react';
import LoadingIndicator from 'react-loading-indicator';

import { priceBasedMarkupRanges, isCordova } from 'shared/utilities';
import Layout from 'shared/components/Layout';
import User from 'shared/components/User';

class SettingsLayout extends Layout {
	constructor( props ) {
		super( props );

		this.state = {
			isSaving: false,
			wasSavingError: false
		};

		this.saveSettings = this.saveSettings.bind(this);
	}

	saveSettings() {
		this.setState({ isSaving: true, wasSavingError: false });

		const checkedCurrencyInput = _.find( this.currencyInputs, currencyInput => currencyInput && currencyInput.checked );
		const currency = checkedCurrencyInput && checkedCurrencyInput.value ? checkedCurrencyInput.value : null;

		const multipliers = this.multiplierInputs.reduce( (reducingMultipliers, multiplierInput) => ( multiplierInput ? {
			...reducingMultipliers,
			[multiplierInput.name]: multiplierInput.value
		} : reducingMultipliers), {});

		const diamondMarkups = this.diamondMarkupInputs.reduce( (reducingDiamondMarkups, diamondMarkupInput) => ( diamondMarkupInput ? {
			...reducingDiamondMarkups,
			[diamondMarkupInput.name]: diamondMarkupInput.value
		} : reducingDiamondMarkups), {});

		const stoneMarkups = this.stoneMarkupInputs.reduce( (reducingStoneMarkups, stoneMarkupInput) => ( stoneMarkupInput ? {
			...reducingStoneMarkups,
			[stoneMarkupInput.name]: stoneMarkupInput.value
		} : reducingStoneMarkups), {});

		const willSaveSettings = new User().setWordpressProfile({
			currency,
			...multipliers,
			...diamondMarkups,
			...stoneMarkups
		});

		willSaveSettings
			.then( profile => {
				if( profile ) {
					this.props.dispatch({
						type: 'UPDATE_USER',
						user: { profile }
					});

					this.props.dispatch({
						type: 'SET_PRICING_STATE',
						props: {
							...this.props.pricing,
							currency: profile.currency
						}
					});
				}
			})
			.catch( error => this.setState({ wasSavingError: true }) )
			.then( () => this.setState({ isSaving: false }) );

		return willSaveSettings;
	}

	displayLayout() {
		const { pricing, loggedInUser, labels } = this.props;

		this.currencyInputs = [];
		this.multiplierInputs = [];
		this.diamondMarkupInputs = [];
		this.stoneMarkupInputs = [];

		let currentDefaultCurrency, exchangeRates, multipliers, diamondMarkups, stoneMarkups;

		try {
			currentDefaultCurrency = loggedInUser.profile.currency;

			exchangeRates = pricing.exchangeRates;

			// !!! Hide "Custom Markup Multipliers" by client request 12/14/17 !!!
			// multipliers = Object.keys( loggedInUser.profile ).reduce( (reducingMultipliers, key) => {
			// 	const match = key.match(/multiplier_(.+)/);

			// 	if( match ) {
			// 		const name = match[1];

			// 		return {
			// 			...reducingMultipliers,
			// 			[name]: loggedInUser.profile[key]
			// 		};
			// 	} else {
			// 		return reducingMultipliers;
			// 	}
			// }, {});

			diamondMarkups = Object.keys( loggedInUser.profile ).reduce( (reducingDiamondMarkups, key) => {
				const match = key.match(/diamond_markup_(\d+)/);

				if( match ) {
					const name = match[1];

					return {
						...reducingDiamondMarkups,
						[name]: loggedInUser.profile[key]
					};
				} else {
					return reducingDiamondMarkups;
				}
			}, {});

			stoneMarkups = Object.keys( loggedInUser.profile ).reduce( (reducingStoneMarkups, key) => {
				const match = key.match(/stone_markup_(\d+)/);

				if( match ) {
					const name = match[1];

					return {
						...reducingStoneMarkups,
						[name]: loggedInUser.profile[key]
					};
				} else {
					return reducingStoneMarkups;
				}
			}, {});
		}
		catch( error ) {
			console.log( error );

			currentDefaultCurrency = currentDefaultCurrency || null;
			exchangeRates = exchangeRates || null;
			multipliers = multipliers || null;
			diamondMarkups = diamondMarkups || null;
			stoneMarkups = stoneMarkups || null;
		}

		return (
			<div className="row">
				<div className="small-12 columns">
					<form onSubmit={ event => { event.preventDefault(); this.saveSettings() } }>
						{
							exchangeRates ? (
								<div className="settings-defaultExchangeRate">
									<h2>{ labels.defaultExchangeRate }</h2>

									<table>
										<thead>
											<tr>
												<th>{ labels.currency }</th>
												<th>{ labels.rate }</th>
											</tr>
										</thead>
										<tbody>
											{
												_.map( exchangeRates, ( exchangeRate, currencyAbbreviation ) => (
													labels.currencies[currencyAbbreviation] ? (
														<tr key={ currencyAbbreviation }>
															<td className="settings-defaultExchangeRate-currencyName">
																<label>
																	<input
																		type="radio"
																		name="currency"
																		value={ currencyAbbreviation }
																		defaultChecked={ currentDefaultCurrency ===  currencyAbbreviation}
																		ref={ currencyInput => this.currencyInputs = this.currencyInputs.concat([ currencyInput ]) }
																	/>
																	{ labels.currencies[currencyAbbreviation] } ({ currencyAbbreviation })
																</label>
															</td>
															<td className="settings-defaultExchangeRate-currencyExchangeRate">
																{ exchangeRate }
															</td>
														</tr>
													) : ( null )
												))
											}
										</tbody>
									</table>
								</div>
							) : (
								null
							)
						}

						{
							multipliers ? (
								<div className="settings-customMarkupMultipliers">
									<h2>{ labels.customMarkupMultipliers }</h2>

									<table>
										<thead>
											<tr>
												<th>{ labels.stoneType }</th>
												<th>{ labels.multiplier }</th>
											</tr>
										</thead>
										<tbody>
											{
												_.map( multipliers, ( multiplier, gemType ) => (
													<tr key={ gemType }>
														<td className="settings-customMarkupMultipliers-multiplierName">
															{ labels.gemTypes[gemType] }
														</td>
														<td className="settings-customMarkupMultipliers-multiplierValue">
															<input
																type={ isCordova() ? 'number' : 'text' }
																step="0.01"
																min="0"
																defaultValue={ multiplier }
																name={`multiplier_${gemType}`}
																ref={ multiplierInput => this.multiplierInputs = this.multiplierInputs.concat([ multiplierInput ]) }
															/>
														</td>
													</tr>
												))
											}
										</tbody>
									</table>
								</div>
							) : (
								null
							)
						}

						{
							diamondMarkups ? (
								<div className="settings-diamondMarkup">
									<h2>{ labels.diamondMarkup }</h2>

									<table>
										<thead>
											<tr>
												<th>{ labels.rangeFrom }</th>
												<th>{ labels.rangeTo }</th>
												<th>{ labels.multiplier }</th>
											</tr>
										</thead>
										<tbody>
											{
												_.map( diamondMarkups, ( markup, index ) => (
													<tr key={`diamondMarkup-${index}`}>
														<td className="settings-diamondMarkup-priceFrom">
															{ priceBasedMarkupRanges[index].from }
														</td>
														<td className="settings-diamondMarkup-priceTo">
															{ priceBasedMarkupRanges[index].to }
														</td>
														<td className="settings-diamondMarkup-multiplierValue">
															<input
																type={ isCordova() ? 'number' : 'text' }
																step="0.01"
																min="0"
																defaultValue={ markup }
																name={ `diamond_markup_${index}` }
																ref={ diamondMarkupInput => this.diamondMarkupInputs = this.diamondMarkupInputs.concat([ diamondMarkupInput ]) }
															/>
														</td>
													</tr>
												))
											}
										</tbody>
									</table>
								</div>
							) : (
								null
							)
						}

						{
							stoneMarkups ? (
								<div className="settings-stoneMarkup">
									<h2>{ labels.coloredStonesMarkup }</h2>

									<table>
										<thead>
											<tr>
												<th>{ labels.rangeFrom }</th>
												<th>{ labels.rangeTo }</th>
												<th>{ labels.multiplier }</th>
											</tr>
										</thead>
										<tbody>
											{
												_.map( stoneMarkups, ( markup, index ) => (
													<tr key={`stoneMarkup-${index}`}>
														<td className="settings-stoneMarkup-priceFrom">
															{ priceBasedMarkupRanges[index].from }
														</td>
														<td className="settings-stoneMarkup-priceTo">
															{ priceBasedMarkupRanges[index].to }
														</td>
														<td className="settings-stoneMarkup-multiplierValue">
															<input
																type={ isCordova() ? 'number' : 'text' }
																step="0.01"
																min="0"
																defaultValue={ markup }
																name={ `stone_markup_${index}` }
																ref={ stoneMarkupInput => this.stoneMarkupInputs = this.stoneMarkupInputs.concat([ stoneMarkupInput ]) }
															/>
														</td>
													</tr>
												))
											}
										</tbody>
									</table>
								</div>
							) : (
								null
							)
						}

						<div className="button-wrapper">
							<input
								type="submit"
								value={ labels.saveChanges }
								className="button"
							/>
							{
								this.state.isSaving ? (
									<LoadingIndicator />
								) : (
									this.state.wasSavingError ? (
										this.props.labels.saveError
									) : (
										null
									)
								)
							}
						</div>
					</form>
				</div>
			</div>
		);
	}

	displayMobileLayout() {
		return null;
	}
}

SettingsLayout.propTypes = {
	...SettingsLayout.propTypes,
	labels: React.PropTypes.object
};

SettingsLayout.defaultProps = {
	...SettingsLayout.defaultProps,
	wrapperClass: 'settings',
	labels: {
		...SettingsLayout.defaultProps.labels,
		defaultExchangeRate: `Default Exchange Rate`,
		currency: `Currency`,
		rate: `Rate`,
		customMarkupMultipliers: `Custom Markup Multipliers`,
		stoneType: `Stone Type`,
		multiplier: `Multiplier`,
		saveChanges: `Save Changes`,
		rangeFrom: `Range From (USD)`,
		rangeTo: `Range To (USD)`,
		diamondMarkup: `Diamonds Markup`,
		coloredStonesMarkup: `Colored Stones Markup`,
		saveError: `There was an error saving. Try again.`,
		currencies: {
			USD: `United States Dollar`,
			EUR: `Euro`,
			CAD: `Canadian Dollar`,
			GBP: `British Pound`,
			AUD: `Australian Dollar`,
			BGN: `Bulgarian Lev`,
			BRL: `Brazilian Real`,
			CHF: `Swiss Franc`,
			CNY: `Chinese Yuan`,
			CZK: `Czech Koruna`,
			DKK: `Danish Krone`,
			HKD: `Hong Kong Dollar`,
			HRK: `Croatian Kuna`,
			HUF: `Hungarian Forint`,
			IDR: `Indonesian Rupiah`,
			ILS: `Israeli New Shekel`,
			INR: `Indian Rupee`,
			JPY: `Japanese Yen`,
			KRW: `South Korean Won`,
			MXN: `Mexican Peso`,
			MYR: `Malaysian Ringgit`,
			NOK: `Norwegian Krone`,
			NZD: `New Zealand Dollar`,
			PHP: `Philippine Peso`,
			PLN: `Polish Zloty`,
			RON: `Romanian Leu`,
			RUB: `Russian Ruble`,
			SEK: `Swedish Krona`,
			SGD: `Singapore Dollar`,
			THB: `Thai Baht`,
			TRY: `Turkish Lira`,
			ZAR: `South African Rand`
		},
		gemTypes: {
			colored_gemstones: `Colored Gemstones`,
			diamonds: `Diamonds`,
			opals: `Opals`,
			pearl_strands: `Pearl Strands`,
			pearls: `Pearls`,
			colored_diamonds: `Colored Diamonds`,
		}
	}
};

export default SettingsLayout;
