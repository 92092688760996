// Tracks which menu is currently open

export default ( state = false, { type, menuId } ) => {
	switch(type) {
		case 'TOGGLE_MENU' :
			return state == menuId ? false : menuId;
		case 'CLOSE_MENU' :
			return state = false;
		default :
			return state;
	}
};