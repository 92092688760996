import menu from 'shared/reducers/menu';
import user from 'shared/reducers/user';
import gem from 'shared/reducers/gem';
import gems from 'shared/reducers/gems';
import colors from 'shared/reducers/colors';
import pricing from 'shared/reducers/pricing';
import diamond from 'shared/reducers/diamond';
import display from 'shared/reducers/display';

// Reducers transform the previous store state to
// a new state based on an action that is dispatched.
// See: https://goo.gl/xhWezY

// The export object's keys correspond to the top-level keys
// of the state. The values are the reducer functions.
export default {
	menu,
	user,
	gem,
	gems,
	colors,
	pricing,
	diamond,
	display
}