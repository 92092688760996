import _ from 'underscore';
import moment from 'moment';
import React from 'react';
import Dropdown from 'react-dropdown';
import Draggable from 'react-draggable';
import Select from 'react-select';
import { browserHistory, hashHistory } from 'react-router';
import { StickyContainer, Sticky } from 'react-sticky';
import { isCordova } from '../../utilities';

//window.browserHistory = browserHistory;

import {
    getColorFromStore,
    getPriceInUserCurrency,
    getCurrencySymbol,
    getRetailMarkup
} from 'shared/utilities';

import Layout from 'shared/components/Layout';
import Gem from 'shared/components/Gem';
import Color from 'shared/components/Color';
import Panels from 'shared/components/color-calculator-panels';
import ResultWell from 'shared/components/ResultWell';

class ColorCalculatorLayout extends Layout {
    constructor() {
        super();

        this.state = {
            ...this.state,
            tab: _.keys( Panels )[ 0 ],
            isDrawerClosed: false,
            drawerHeight: 0,
            showRetailPrice: false,
            numMobileTaps: 0
        };

        this.setGemPricingTable = () => {
            let pricingSettings = this.props.pricing;

            this.props.dispatch( {
                type: 'SET_PRICING_STATE',
                props: {
                    ...this.props.pricing,
                    presetSelection: true,
                    presetActive: true,
                    selection: { ...this.props.gem },
                    section: 'PricingTable',
                    listType: 'Colored Gemstones'
                }
            } );

            if( isCordova() ) {
                hashHistory.push( '/pricing' );
            } else {
                browserHistory.push( '/pricing' )
            }

        };
    }

    componentWillReceiveProps( nextProps ) {
        // Re-fetch the price if the gem or its weight or grade change
        if(
            this.props.gem.id !== nextProps.gem.id ||
            ( this.props.gem.weight !== nextProps.gem.weight && nextProps.gem.weight > 0 ) ||
            Gem.getGrade( this.props.gem ) !== Gem.getGrade( nextProps.gem )
        ) {
            this.fetchPrice( {
                gemId: nextProps.gem.id,
                weight: nextProps.gem.weight,
                grade: Gem.getGrade( nextProps.gem )
            } );
        }
    }

    componentDidUpdate( prevProps, prevState ) {
        super.componentDidUpdate.apply( this, arguments );

        // Force the drawer to update its measurements and position when switching tabs
        if( prevState.tab !== this.state.tab ) {
            this.drawerDragEasingIsDisabled = true;

            this.forceUpdate( () => {
                if( this.state.isDrawerClosed ) {

                    this.drawerDrag.setState( { y: this.drawerDrag.props.bounds.top }, () =>
                        this.forceUpdate( () => this.drawerDragEasingIsDisabled = false )
                    );
                }
            } );
        }

        // When the state of the drawer shifts from being opened to closed or vice versa,
        // transition the drawer to be fully in one position or another
        if( this.drawerDrag && prevState.isDrawerClosed !== this.state.isDrawerClosed ) {
            if( this.state.isDrawerClosed ) {
                this.drawerDrag.setState( { y: this.drawerDrag.props.bounds.top } );
            } else {
                this.drawerDrag.setState( { y: 0 } );
            }
        }
    }

    fetchPrice( { gemId, weight, grade } ) {
        if( !weight || !grade ) {
            this.props.dispatch( {
                type: 'SET_GEM_PROPS',
                props: {
                    price: null
                }
            } );

            return;
        }

        this.props.dispatch( { type: 'FETCH_GEM_PRICE' } );

        // If the request takes less than `minRequestDuration`, then artificially delay
        // display of the result so there isn't a flashy/jerky effect
        const requestTime = new Date();
        const minRequestDuration = 1000; // in milliseconds

        if( Number.isInteger( grade ) ) {
            new Gem().getPrice( {
                gemId,
                weight,
                grade
            } ).then(
                price => {
                    const requestDuration = moment( requestTime ).diff( new Date() );
                    const timeToWait = minRequestDuration - requestDuration;
                    const doDispatchPrice = () => {
                        this.props.dispatch( {
                            type: 'FETCH_GEM_PRICE',
                            status: 'success',
                            price
                        } )
                        //document.activeElement.blur();
                    };

                    // If `timeToWait` is negative then `doDispatchPrice` will execute immediately
                    setTimeout( doDispatchPrice, timeToWait );
                }
            ).catch(
                priceError => this.props.dispatch( {
                    type: 'FETCH_GEM_PRICE',
                    status: 'error',
                    priceError
                } )
            );
        } else {
            const grades = [ Math.floor( grade ), Math.ceil( grade ) ];
            let pricePromises = [];

            _.each( grades, grade => {
                pricePromises.push( new Promise( ( resolve, reject ) => {
                    new Gem().getPrice( {
                        gemId,
                        weight,
                        grade,
                        range: true
                    } ).then( price => {
                        resolve( price );
                    } ).catch( priceError => {
                        this.props.dispatch( {
                            type: 'FETCH_GEM_PRICE',
                            status: 'error',
                            priceError
                        } );
                        reject();
                    } );
                } ) )
            } );

            Promise.all( pricePromises ).then( vals => {
                const requestDuration = moment( requestTime ).diff( new Date() );
                const timeToWait = minRequestDuration - requestDuration;

                const doDispatchPrice = () => {
                    if( Array.isArray( vals[ 0 ] ) ) {
                        const lowerRange = vals[ 0 ][ 0 ].price > vals[ 0 ][ 1 ].price ? vals[ 0 ][ 0 ].price : vals[ 0 ][ 1 ].price;
                        const upperRange = vals[ 1 ][ 0 ].price > vals[ 1 ][ 1 ].price ? vals[ 1 ][ 1 ].price : vals[ 1 ][ 0 ].price;
                        this.props.dispatch( {
                            type: 'FETCH_GEM_PRICE',
                            status: 'success',
                            price: ( lowerRange + upperRange ) / 2
                        } );
                    } else {
                        this.props.dispatch( {
                            type: 'FETCH_GEM_PRICE',
                            status: 'success',
                            price: ( vals[ 0 ] + vals[ 1 ] ) / 2
                        } );
                    }
                };

                setTimeout( doDispatchPrice, timeToWait );
            } );
        }
    }

    className() {
        return `
			${ this.props.gem.customGrade ? 'hasCustomGrade' : 'doesNotHaveCustomGrade' }
		`;
    }

    displayLayoutStateBar() {
        const {
                  gem,
                  pricing,
                  loggedInUser,
                  labels
              } = this.props;

        // Only gems that can be graded should
        // appear as options for the color calculator
        const gems = this.props.gems.filter( gem => gem.grades );

        const displayColorName = gem.color === 0 ?
            gem.customColorName :
            getColorFromStore( this.props.colors, gem.color ).name;

        const displayGrade = Gem.getGrade( gem );

        let priceInUserCurrency, currencySymbol, retailMarkup;

        if( gem.price ) {
            priceInUserCurrency = getPriceInUserCurrency( gem.price, pricing, loggedInUser );
            currencySymbol = getCurrencySymbol( pricing, loggedInUser );
            retailMarkup = getRetailMarkup( loggedInUser, 'colored_gemstones', gem.price );
        }

        const PriceResultWell = props => (
            <ResultWell
                valuePrefix={ currencySymbol }
                isLoading={ this.props.gem.isFetchingPrice }
                isClickable={ true }
                { ...props }
            />
        );

        return (
            <div className="layout-stateBar-container">
                <div className="colorCalculator-gemInfo">
                    <div className="colorCalulator-gemInput">
                        <div className="colorCalculator-stoneSearch">
                            <label className={ `colorCalculator-desktopSearch` }>
                                <span
                                    className="Dropdown-placeholder"
                                    style={ { color: 'white', opacity: 0.7 } }
                                    children={ this.props.gem.name || this.props.labels.selectGemstone }
                                />
                                <Select
                                    value={ this.props.gem.id }
                                    placeholder=""
                                    autofocus={ true }
                                    options={
                                        gems.map( gem => ( {
                                            value: gem.id,
                                            label: gem.name
                                        } ) )
                                    }
                                    optionClassName="needsclick"
                                    onChange={
                                        ( { value } ) => {
                                            document.activeElement.blur();
                                            this.props.dispatch( {
                                                type: 'SET_GEM_PROPS',
                                                props: Gem.getDefaultGemProps( {
                                                    gemId: value,
                                                    gems: this.props.gems,
                                                    colors: this.props.colors
                                                } )
                                            } );
                                        }
                                    }
                                />
                            </label>
                        </div>
                        { false ? (
                            <Dropdown
                                ref={ gemInput => this.gemInput = gemInput }
                                options={
                                    gems.map( gem => ( {
                                        label: gem.name,
                                        value: gem.id
                                    } ) ).filter( gem =>
                                        gem.value !== this.props.gem.id
                                    )
                                }
                                onChange={
                                    ( { value } ) => {
                                        this.props.dispatch( {
                                            type: 'SET_GEM_PROPS',
                                            props: Gem.getDefaultGemProps( {
                                                gemId: value,
                                                gems: this.props.gems,
                                                colors: this.props.colors
                                            } )
                                        } );
                                    }
                                }
                                placeholder={ this.props.gem.name || this.props.labels.selectGemstone }
                            />
                        ) : null }

                    </div>
                    <div
                        className="colorCalculator-gemInfo-color"
                        children={ displayColorName }
                    />
                </div>
                <div className="colorCalculator-results">
                    <ResultWell
                        label={ labels.carat }
                        value={ gem.weight ? gem.weight.toFixed( 3 ) : null }
                    />
                    <ResultWell
                        label={ labels.grade }
                        value={ displayGrade }
                    />
                    {
                        this.state.showRetailPrice ? (
                            <PriceResultWell
                                label={ labels.retailPricePerCarat }
                                value={ parseInt( priceInUserCurrency * retailMarkup ) }
                                onClick={ () => this.setState( { showRetailPrice: false } ) }
                            />
                        ) : (
                            <PriceResultWell
                                label={ labels.pricePerCarat }
                                value={ parseInt( priceInUserCurrency ) }
                                onClick={ () => this.setState( { showRetailPrice: true } ) }
                            />
                        )
                    }
                    {
                        this.state.showRetailPrice ? (
                            <PriceResultWell
                                label={ labels.retailPrice }
                                value={ parseInt( priceInUserCurrency * gem.weight * retailMarkup ) }
                                onClick={ () => this.setState( { showRetailPrice: false } ) }
                            />
                        ) : (
                            <PriceResultWell
                                label={ labels.price }
                                value={ parseInt( priceInUserCurrency * gem.weight ) }
                                onClick={ () => this.setState( { showRetailPrice: true } ) }
                            />
                        )
                    }
                </div>
            </div>
        );
    }

    displayLayout() {
        return this.props.gem.id ? (
            <div>
                <div className="colorCalculator-panels">
                    {
                        _.map( Panels, Panel =>
                            <div
                                key={ Panel.className }
                                className={ `
									colorCalculator-panel
									${ Panel.className }
								` }
                            >
                                <StickyContainer>
                                    <div className="row">
                                        <div className="small-12 columns">
                                            <h3
                                                className="colorCalculator-panel-title"
                                                children={ Panel.label }
                                            />
                                        </div>
                                    </div>

                                    <Sticky>
                                        <ul className="colorCalculator-drawer">
                                            <div className="row">
                                                {
                                                    Panel.drawerItems.map( drawerItem => {
                                                        const drawerItemValue = drawerItem.valueCallback( this.props.gem );

                                                        return drawerItemValue ? (
                                                            <li
                                                                key={ drawerItem.label }
                                                                className="colorCalculator-drawer-item"
                                                                children={ `${drawerItem.label}: ${drawerItemValue}` }
                                                            />
                                                        ) : (
                                                            null
                                                        )
                                                    } )
                                                }
                                            </div>
                                        </ul>
                                    </Sticky>

                                    <div className="row">
                                        <div className="small-12 columns">
                                            <div
                                                className="colorCalculator-panel-content"
                                                children={ <Panel/> }
                                            />
                                        </div>
                                    </div>
                                </StickyContainer>
                            </div>
                        )
                    }
                </div>
                <div
                    className="colorCalculator-actions"
                    children={
                        this.constructor.actionButtons.map(
                            actionButton => (
                                <div
                                    children={ actionButton.label }
                                    className={ `
											colorCalculator-action
											${actionButton.className || ''}
										` }
                                    onClick={ () => actionButton.action( this ) }
                                />
                            )
                        )
                    }
                />
            </div>
        ) : (
            <h3
                className="colorCalculator-selectMessage"
                children={ this.props.labels.selectGemstoneMessage }
            />
        );
    }

    displayMobileLayout() {
        const {
                  gem,
                  pricing,
                  loggedInUser,
                  labels
              } = this.props;

        const gems = [
            {
                id: 0,
                name: 'Select a gem',
                disabled: true
            }
        ].concat(
            this.props.gems.filter(
                // Only gems that can be graded should
                // appear as options for the color calculator
                gem => gem.grades
            )
        );

        const gemsNoFirst = this.props.gems.filter(
            // Only gems that can be graded should
            // appear as options for the color calculator
            gem => gem.grades
        );

        const displayColorName = gem.color === 0 ?
            gem.customColorName :
            getColorFromStore( this.props.colors, gem.color ).name;

        const displayGrade = Gem.getGrade( gem );

        let priceInUserCurrency, currencySymbol, retailMarkup;

        if( gem.price ) {
            priceInUserCurrency = getPriceInUserCurrency( gem.price, pricing, loggedInUser );
            currencySymbol = getCurrencySymbol( pricing, loggedInUser );
            retailMarkup = getRetailMarkup( loggedInUser, 'colored_gemstones', gem.price );
        }

        const PriceResultWell = props => (
            <ResultWell
                valuePrefix={ currencySymbol }
                isLoading={ this.props.gem.isFetchingPrice }
                isClickable={ true }
                loadingIndicatorProps={ {
                    segmentLength: 1,
                    segmentWidth: 1.5,
                    segments: 10,
                    spacing: 1
                } }
                { ...props }
            />
        );

        return this.props.gem.id ? (
            <StickyContainer>
                <Sticky>
                    <div className="colorCalculator-header">
                        <div className="row">
                            <div className="small-12 columns">
                                <div className="colorCalculator-header-wrapper">
                                    <div className="colorCalculator-gemInfo" style={ { flexGrow: 1 } }>
                                        <div className="colorCalculator-gemInput">
                                            <label className={ `colorCalculator-desktopSearch` }>
                                                <span
                                                    className="Dropdown-placeholder"
                                                    style={ { color: 'white', opacity: 0.7, fontSize: '10px', textTransform: 'uppercase' } }
                                                    children={ this.props.gem.name || this.props.labels.selectGemstone }
                                                />
                                                <Select
                                                    value={ this.props.gem.id }
                                                    placeholder=""
                                                    autofocus={ true }
                                                    options={
                                                        gemsNoFirst.map( gem => ( {
                                                            value: gem.id,
                                                            label: gem.name
                                                        } ) )
                                                    }
                                                    optionClassName="needsclick"
                                                    onChange={
                                                        ( { value } ) => {
                                                            document.activeElement.blur();
                                                            this.props.dispatch( {
                                                                type: 'SET_GEM_PROPS',
                                                                props: Gem.getDefaultGemProps( {
                                                                    gemId: value,
                                                                    gems: this.props.gems,
                                                                    colors: this.props.colors
                                                                } )
                                                            } );
                                                        }
                                                    }
                                                />
                                            </label>
                                            { false ? (
                                                <Dropdown
                                                    ref={ gemInput => this.gemInput = gemInput }
                                                    options={
                                                        gems.map( gem => ( {
                                                            label: gem.name,
                                                            value: gem.id
                                                        } ) ).filter( gem =>
                                                            gem.value !== this.props.gem.id
                                                        )
                                                    }
                                                    onChange={
                                                        ( { value } ) => {
                                                            this.props.dispatch( {
                                                                type: 'SET_GEM_PROPS',
                                                                props: Gem.getDefaultGemProps( {
                                                                    gemId: value,
                                                                    gems: this.props.gems,
                                                                    colors: this.props.colors
                                                                } )
                                                            } );
                                                        }
                                                    }
                                                    placeholder={ this.props.gem.name || this.props.labels.selectGemstone }
                                                />
                                            ) : null }
                                        </div>
                                        <div
                                            className="colorCalculator-gemInfo-color"
                                            children={ displayColorName }
                                        />
                                    </div>
                                    <div className="colorCalculator-results">
                                        <ResultWell
                                            label={ labels.carat }
                                            value={ gem.weight ? gem.weight.toFixed( 3 ) : null }
                                        />
                                        <ResultWell
                                            label={ labels.grade }
                                            value={ displayGrade }
                                        />
                                        {
                                            this.state.numMobileTaps % 4 === 0 ? (
                                                <PriceResultWell
                                                    label={ labels.price }
                                                    value={ parseInt( priceInUserCurrency * gem.weight ) }
                                                    onClick={ () => this.setState( { numMobileTaps: this.state.numMobileTaps + 1 } ) }
                                                />
                                            ) : (
                                                null
                                            )
                                        }
                                        {
                                            this.state.numMobileTaps % 4 === 1 ? (
                                                <PriceResultWell
                                                    label={ labels.retailPrice }
                                                    value={ parseInt( priceInUserCurrency * gem.weight * retailMarkup ) }
                                                    onClick={ () => this.setState( { numMobileTaps: this.state.numMobileTaps + 1 } ) }
                                                />
                                            ) : (
                                                null
                                            )
                                        }
                                        {
                                            this.state.numMobileTaps % 4 === 2 ? (
                                                <PriceResultWell
                                                    label={ labels.pricePerCarat }
                                                    value={ parseInt( priceInUserCurrency ) }
                                                    onClick={ () => this.setState( { numMobileTaps: this.state.numMobileTaps + 1 } ) }
                                                />
                                            ) : (
                                                null
                                            )
                                        }
                                        {
                                            this.state.numMobileTaps % 4 === 3 ? (
                                                <PriceResultWell
                                                    label={ labels.retailPricePerCarat }
                                                    value={ parseInt( priceInUserCurrency * retailMarkup ) }
                                                    onClick={ () => this.setState( { numMobileTaps: this.state.numMobileTaps + 1 } ) }
                                                />
                                            ) : (
                                                null
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Sticky>
                <div className="colorCalculator-panels">
                    <ul className="colorCalculator-tabs menu">
                        {
                            _.map( Panels, ( Panel, tab ) =>
                                <li
                                    className={ `
										colorCalculator-tab
										${ tab == this.state.tab ? 'active' : 'notActive' }
									` }
                                    key={ tab }
                                    onClick={ () => this.setState( { tab } ) }
                                >
                                    <span>{ Panel.label }</span>
                                </li>
                            )
                        }
                    </ul>
                    <Draggable
                        ref={ drawerDrag => this.drawerDrag = drawerDrag }
                        axis="y"
                        bounds={ {
                            left: 0,
                            top: this.drawer && this.drawer.clientHeight * -1 + 1,
                            right: 0,
                            bottom: 0
                        } }
                        onStart={ () => {
                            this.drawerDragEasingIsDisabled = true;

                            this.drawerDrag.draggingFromTop = this.drawerDrag.state.y < this.drawerDrag.props.bounds.top / 2;
                        } }
                        onDrag={ () => this.forceUpdate() }
                        onStop={ () => {
                            this.forceUpdate();

                            this.drawerDragEasingIsDisabled = false;

                            // Number of pixel the user has to drag for the drawer to end in the opposite state
                            const reversionTolerance = 20;

                            // Don't allow the drawer to be left in a partially open state
                            if( this.drawerDrag.draggingFromTop ) {
                                if( this.drawerDrag.state.y > this.drawerDrag.props.bounds.top + reversionTolerance ) {
                                    this.setState( { isDrawerClosed: false } );
                                } else {
                                    this.setState( { isDrawerClosed: true } );
                                }
                            } else {
                                if( this.drawerDrag.state.y < reversionTolerance * -1 ) {
                                    this.setState( { isDrawerClosed: true } );
                                } else {
                                    this.setState( { isDrawerClosed: false } );
                                }
                            }

                            this.forceUpdate();
                        } }
                    >
                        <ul
                            className={ `
								colorCalculator-drawer
								needsclick
								${ this.state.isDrawerClosed ? 'closed' : 'open' }
							` }
                            ref={ drawer => this.drawer = drawer }
                            style={ { transition: !this.drawerDragEasingIsDisabled && `transform 300ms ease` } }
                        >
                            {
                                Panels[ this.state.tab ].drawerItems.map( drawerItem =>
                                    drawerItem.valueCallback( this.props.gem ) ? (
                                        <li
                                            key={ drawerItem.label }
                                            className="colorCalculator-drawer-item"
                                            children={ `${drawerItem.label}: ${drawerItem.valueCallback( this.props.gem )}` }
                                        />
                                    ) : (
                                        null
                                    )
                                )
                            }

                            <div className="colorCalculator-drawer-handle"/>
                        </ul>
                    </Draggable>
                    <div
                        children={ React.createElement( Panels[ this.state.tab ] ) }
                        style={ {
                            marginTop: this.drawerDrag && this.drawerDrag.state.y,
                            transition: !this.drawerDragEasingIsDisabled && `margin-top 300ms ease`
                        } }
                        className={ `
							callout
							colorCalculator-panel
							${ Panels[ this.state.tab ].className }
						` }
                    />
                </div>
                <div
                    className="colorCalculator-actions"
                    children={
                        this.constructor.actionButtons.map(
                            actionButton => (
                                <div
                                    children={ actionButton.label }
                                    className={ `
											colorCalculator-action
											${actionButton.className || ''}
										` }
                                    onClick={ () => actionButton.action( this ) }
                                />
                            )
                        )
                    }
                />
            </StickyContainer>
        ) : (
            <div className="colorCalculator-stoneSearch">
                <label>
					<span
                        className="colorCalculator-stoneSearch-label"
                        children={ this.props.labels.mobileSelectGemstoneMessage }
                    />
                    <Select
                        value={ this.props.gem.id }
                        placeholder=""
                        autofocus={ true }
                        options={
                            gems.map( gem => ( {
                                value: gem.id,
                                label: gem.name
                            } ) )
                        }
                        optionClassName="needsclick"
                        onChange={
                            ( { value } ) => {
                                setTimeout( () => document.activeElement.blur(), 250 );
                                this.props.dispatch( {
                                    type: 'SET_GEM_PROPS',
                                    props: Gem.getDefaultGemProps( {
                                        gemId: value,
                                        gems: this.props.gems,
                                        colors: this.props.colors
                                    } )
                                } );
                            }
                        }
                    />
                </label>
            </div>
        )
    }
}

ColorCalculatorLayout.propTypes = {
    ...ColorCalculatorLayout.propTypes,
    gems: React.PropTypes.array,
    labels: React.PropTypes.object
};

ColorCalculatorLayout.defaultProps = {
    ...ColorCalculatorLayout.defaultProps,
    wrapperClass: 'colorCalculator',
    gems: [],
    labels: {
        ...ColorCalculatorLayout.defaultProps.labels,
        carat: 'Carat',
        grade: 'Grade',
        stone: 'Stone',
        price: 'Stone (WS)',
        retailPrice: 'Stone (R)',
        pricePerCarat: 'Price/Carat (WS)',
        retailPricePerCarat: 'Price/Carat (R)',
        selectGemstone: 'Select a gem',
        selectGemstoneMessage: 'Select a gem above to begin',
        mobileSelectGemstoneMessage: 'Search for a stone to begin'
    }
};

ColorCalculatorLayout.contextTypes = {
    store: React.PropTypes.object
};

ColorCalculatorLayout.actionButtons = [
    {
        label: 'View Pricing Table',
        action: colorCalculator => colorCalculator.setGemPricingTable()
    }
];

export default ColorCalculatorLayout;
