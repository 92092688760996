import React from "react";
import Layout from "shared/components/Layout";
import axios from "axios";
import { browserHistory } from "react-router";
class AdminDashboard extends Layout {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      selectedCSVFile: null,
      userFeedback: "",
      userCSVFeedback: "",
      feedbackVisible: false,
      userCSVFeedbackVisible: false,
      progress: 0,
      loading: false,
      CSVloading: false,
      selectedFileName:"",
      selectedCSVFileName:""
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleCSVFileChange = this.handleCSVFileChange.bind(this); // Handling CSV file changes
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCSVSubmit = this.handleCSVSubmit.bind(this); // Handling CSV submission
    this.triggerFileInput = this.triggerFileInput.bind(this);
    this.triggerCSVFileInput = this.triggerCSVFileInput.bind(this);
    this.setFeedback = this.setFeedback.bind(this);
    this.handleLogout=this.handleLogout.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    const isAuthenticated = localStorage.getItem("adminToken");
    if (!isAuthenticated) {
      return browserHistory.replace("/admin-login");
    }
  }

  componentWillUnmount() {
    clearTimeout(this.feedbackTimeout);
    clearTimeout(this.csvFeedbackTimeout);
  }

  handleFileChange(event) {
    const file = event.target.files[0];
    this.setState({
      selectedFile: event.target.files[0],
      selectedFileName: file.name,
    });
    setTimeout(this.handleSubmit, 3000);
  }

  handleCSVFileChange(event) {
    const file = event.target.files[0];
    this.setState({
      selectedCSVFile: event.target.files[0],
      selectedCSVFileName: file.name,
    });
    setTimeout(this.handleCSVSubmit, 3000); 
  }

  triggerFileInput() {
    this.fileInput.click();
  }

  triggerCSVFileInput() {
    this.csvFileInput.click();
  }

  handleSubmit() {
    const { selectedFile } = this.state;
    if (selectedFile === null) {
      this.setFeedback("userFeedback", "Please select a file.");
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    this.setState({ loading: true });
    axios
      .post("/import-prices", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data) {
          this.setState({ loading: false });
          this.setFeedback(
            "userFeedback",
            "Your File has been Uploaded Successfully!"
          );
        }
      })
      .catch((error) => {
        console.error("File upload failed", error);
        this.setState({
          loading: false,
        });
        this.setFeedback("userFeedback", "Something went wrong!");
      });
  }

  handleCSVSubmit() {
    const { selectedCSVFile } = this.state;
    if (selectedCSVFile === null) {
      this.setFeedback("userCSVFeedback", "Please select a CSV file.");

      return;
    }
    const formData = new FormData();
    formData.append("file", selectedCSVFile);
    this.setState({ CSVloading: true });
    axios
      .post("/import-gems", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data) {
          this.setState({ CSVloading: false });
          this.setFeedback(
            "userCSVFeedback",
            "Your CSV File has been Uploaded Successfully!"
          );
        }
      })
      .catch((error) => {
        console.log("CSV File upload failed", error);
        this.setState({
          CSVloading: false,
        });
        this.setFeedback(
          "userCSVFeedback",
          "Something went wrong with CSV upload!"
        );
      });
  }

  setFeedback(messageKey, message) {
    this.setState({ [messageKey]: message, [`${messageKey}Visible`]: true });
}

handleLogout(){
  localStorage.removeItem("adminToken");
  browserHistory.replace("/admin-login");
}

  displayLayout() {
    return (
      <div className="admin__dashboard">
        <div className="top-bar">
          <div className="row">
            <div className="logoContainer">
              <img
                src="/static/images/logo.png"
                alt="Gemguide Logo"
                className="home-logo"
              />
            </div>
            <div className="buttonConatiner">
              <button className="Logoutbutton" onClick={this.handleLogout}>Logout</button>
            </div>
          </div>
        </div>
        <div className="main">
          <div className="main__container">
            <div className="main__leftCard">
              <h2 className="main__leftCard--heading">
                Upload Gem Grades CSV File
              </h2>
              <p className="main__leftCard--text">
                Take control of your gem grading process by uploading a Gem
                Grades CSV file. This feature allows you to maintain precise and
                consistent grading standards across your inventory. It’s a quick
                and hassle-free way to manage your gemstone data.
              </p>
              <div className="main__UploadContainer">
                <input
                  type="file"
                  className="Upload__input"
                  onChange={this.handleCSVFileChange}
                  ref={(input) => {
                    this.csvFileInput = input;
                  }}
                />
                <img
                  src="static/images/csv.svg"
                  className="icon__image"
                  alt="Upload-CSV"
                  onClick={this.triggerCSVFileInput}
                />
                <p className="filename__text">{this.state.selectedCSVFileName}</p>
                <div className="Upload__btn">
                  Upload CSV
                </div>
                {this.state.CSVloading && (
                  <div className="loading__container">
                    {" "}
                    <i className="fa fa-solid fa-spinner fa-spin loader"></i>{" "}
                  </div>
                )}
                <div className="User__feedback">
                  <div className="User__feedback">
                    {this.state.userCSVFeedbackVisible && (
                      <p className="User__feedback--text">
                        {this.state.userCSVFeedback}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="main__rightCard">
              <h2 className="main__leftCard--heading">
                Upload Pricing.xml File
              </h2>
              <p className="main__leftCard--text">
                Easily update your pricing information with just a few clicks.
                Upload your Pricing.xml file to ensure your pricing tables are
                always accurate and up-to-date. Say goodbye to manual data entry
                and hello to efficiency.
              </p>
              <div className="main__UploadContainer">
                <input
                  type="file"
                  className="Upload__input"
                  onChange={this.handleFileChange}
                  ref={(input) => {
                    this.fileInput = input;
                  }}
                />
                <img
                  src="static/images/xml.svg"
                  className="icon__image"
                  alt="Upload-xml"
                  onClick={this.triggerFileInput}
                />
                <p className="filename__text">{this.state.selectedFileName}</p>
                <div className="Upload__btn">
                  Upload XML
                </div>

                {this.state.loading && (
                  <div className="loading__container">
                    {" "}
                    <i className="fa fa-solid fa-spinner fa-spin loader"></i>{" "}
                  </div>
                )}
                <div className="User__feedback">
                  {this.state.userFeedbackVisible && (
                    <p className="User__feedback--text">
                      {this.state.userFeedback}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdminDashboard.defaultProps = {
  wrapperClass: "adminDashboard",
};

export default AdminDashboard;
