import _ from 'underscore';
import DbComponent from 'shared/components/DbComponent';
import Color from 'shared/components/Color';
import {
    isServer,
    apiRequest,
    isApiRequest,
    getRequestingUser,
    savePricingLogs
} from 'shared/utilities';

class Gem extends DbComponent {
    constructor(props) {
        super();

        // Instantiate with "artificial" props for DB queries
        if(props) this.props = props;

        this.apiRequest = (method, data) =>
            apiRequest( 'Gem', method, data );

        if( isServer() ) {
            this.model = global.DB['Gem'];
        }
    }

    static getGrade( gem ) {
        let grade;

        if( gem.customGrade ) return gem.customGrade;

        try {
            grade = Number(
                gem.grades &&
                gem.hue &&
                gem.tone &&
                gem.saturation &&
                gem.grades[ gem.hue ] &&
                gem.grades[ gem.hue ][ gem.tone ] &&
                gem.grades[ gem.hue ][ gem.tone ][ gem.saturation ] ?
                    gem.grades[ gem.hue ][ gem.tone ][ gem.saturation ] : false
            );
        }
        catch( error ) {
            throw new Error( error );
        }

        // If there is a valid base grade, then transform it based on user selections
        if( grade ) {
            // Clarity
            let clarityAdjustment = 0;

            clarityAdjustment = clarityAdjustment + _.find( Gem.inclusions,
                inclusion => inclusion.id == gem.inclusions
            ).value;
            clarityAdjustment = clarityAdjustment + _.find( Gem.textures,
                texture => texture.id == gem.texture
            ).value;
            clarityAdjustment = clarityAdjustment + _.find( Gem.zonings,
                zoning => zoning.id == gem.zoning
            ).value;

            grade = grade + clarityAdjustment;

            // Brilliance
            let brillianceAdjustment = _.find( Gem.brillianceInterpolations,
                brillianceInterpolation => (
                    brillianceInterpolation.range[ 0 ] <= gem.brilliance &&
                    brillianceInterpolation.range[ 1 ] >= gem.brilliance
                )
            );

            grade = grade + ( brillianceAdjustment ? brillianceAdjustment.value : 0 );

            // Proportion
            let proportionAdjustment = 0;

            proportionAdjustment = proportionAdjustment + _.find( Gem.crownHeights,
                crownHeight => crownHeight.id == gem.crownHeight
            ).value;
            proportionAdjustment = proportionAdjustment + _.find( Gem.pavillionDepths,
                pavillionDepth => pavillionDepth.id == gem.pavillionDepth
            ).value;
            proportionAdjustment = proportionAdjustment + _.find( Gem.bulges,
                bulge => bulge.id == gem.bulge
            ).value;
            proportionAdjustment = proportionAdjustment + _.find( Gem.girdles,
                girdle => girdle.id == gem.girdle
            ).value;
            proportionAdjustment = proportionAdjustment + _.find( Gem.tableSizes,
                tableSize => tableSize.id == gem.tableSize
            ).value;
            proportionAdjustment = proportionAdjustment + _.find( Gem.symmetries,
                symmetry => symmetry.id == gem.symmetry
            ).value;

            // Proportion adjustment cannot deduct more than 1.5
            proportionAdjustment = proportionAdjustment < -1.5 ? -1.5 : proportionAdjustment;

            grade = grade + proportionAdjustment;

            // Polish
            let polishAdjustment = _.find( Gem.polishes,
                polish => polish.id == gem.polish
            ).value;

            grade = grade + polishAdjustment;

            return grade < 1 ? 1 : ( grade > 10 ? 10 : grade );
        }
    }

    static getDefaultGemProps( { gemId, gems, colors } ) {
        const gemInfo = _.find( gems, gem => gem.id == gemId );

        // The default hue is the hue that is the most red
        const hue = _.min( _.keys( gemInfo.grades ).map( hue => Number( hue ) ) );

        // The default tone is the lowest value allowed by the default hue
        const tone = _.min( _.keys( gemInfo.grades[ hue ] ).map( tone => Number( tone ) ) );

        // The default saturation is the lowest value allowed by the default hue and tone
        const saturation = _.min( _.keys( gemInfo.grades[ hue ][ tone ] ).map( saturation => Number( saturation ) ) );

        // The default weight is the global default weight
        const weight = Gem.defaultWeight;

        // The default color is "Other"
        const color = 0;

        // The default inclusion is the first in the list of possible gem inclusions
        const inclusions = Gem.inclusions[ 0 ].id;

        // The default texture is the first in the list of possible gem textures
        const texture = Gem.textures[ 0 ].id;

        // The default zoning is the first in the list of possible gem zonings
        const zoning = Gem.zonings[ 0 ].id;

        // Brilliance
        const brilliance = 100;
        const windowing = 0;
        const extinction = 0;

        // The default crown height is the first in the list of possible gem crown heights
        const crownHeight = Gem.crownHeights[ 0 ].id;

        // The default pavillion depth is the first in the list of possible gem pavillion depths
        const pavillionDepth = Gem.pavillionDepths[ 0 ].id;

        // The default bulge is the first in the list of possible gem bulges
        const bulge = Gem.bulges[ 0 ].id;

        // The default girdle is the first in the list of possible gem girdles
        const girdle = Gem.girdles[ 0 ].id;

        // The default table size is the first in the list of possible gem table sizes
        const tableSize = Gem.tableSizes[ 0 ].id;

        // The default symmetry is the first in the list of possible gem symmetries
        const symmetry = Gem.symmetries[ 0 ].id;

        // The default polish is the first in the list of possible gem polishes
        const polish = Gem.polishes[ 0 ].id;

        const customColorName = '';

        return {
            ...gemInfo,
            // color
            hue,
            tone,
            saturation,
            weight,
            color,
            // clarity
            inclusions,
            texture,
            zoning,
            // brilliance
            brilliance,
            windowing,
            extinction,
            // proportion
            crownHeight,
            pavillionDepth,
            bulge,
            girdle,
            tableSize,
            symmetry,
            // polish
            polish,
            customColorName
        };
    }

    getPrices() {
        const isThisApiRequest = isApiRequest( arguments );
        const requestingUser = getRequestingUser( arguments );

        let { gemId, grade, weight } = arguments[ 0 ];

        gemId = gemId || this.getProp( 'id' );

        if (!gemId) { throw 'gem id must be provided' }

        grade = parseInt( grade );

        if( isServer() ) {

            return new Promise( ( resolve, reject ) => {

                const doGetPrices = () => {
                    let order_by = ' ORDER BY "Prices"."grade", "Prices"."price" ASC'
                    let query = 'SELECT * FROM "Prices" where "Prices"."gem_id" = ?';
                    let replacements = [gemId];

                    if (grade) {
                        query = query + ' AND grade = ?';
                        replacements.push(grade)
                    }

                    if (weight) {
                        weight = Number.parseFloat(weight)

                        if (!weight) { throw 'weight must be provided' }

                        weight = (Math.trunc(weight * 100) / 100)

                        query = query + ' AND numrange("Prices"."min_weight"::numeric, "Prices"."max_weight"::numeric) @> ?::numeric'
                        replacements.push(weight)
                    }

                    query = query + order_by

                    global.DB.sequelize.query(query,
                        {
                            type: global.DB.Sequelize.QueryTypes.SELECT,
                            replacements: replacements,
                            model: global.DB['Price'],
                            mapToModel: true
                        }
                    ).then(prices => {
                        if (prices.length === 0) {
                            savePricingLogs(arguments[1].user, null, arguments[1].url, 200 , arguments[0])
                            global.DB.sequelize.query(
                                'SELECT min(min_weight), max(max_weight) FROM "Prices" WHERE gem_id = ?',
                                {
                                    type: global.DB.Sequelize.QueryTypes.SELECT,
                                    replacements: [gemId]

                                }
                            ).then(weights => {
                                resolve({
                                    code: 'invalid_weight',
                                    values: [weights[0].min, weights[0].max],
                                });
                            });

                        } else {
                            resolve( prices );
                        }

                    });
                }

                if( isThisApiRequest ) {
                    if( requestingUser ) {
                        doGetPrices();
                    } else {
                        reject( {
                            status: 403,
                            error: new Error( `Requester must be identified` )
                        } );
                    }
                } else {
                    doGetPrices();
                }
            } )
        } else {
            return this.apiRequest( 'getPrices', { gemId, grade, weight } );
        }
    }

    static getIdByName() {
        let { name } = arguments[ 0 ];

        if( isServer() ) {
            return new Promise( ( resolve, reject ) => {
                global.DB.Gem.findOne( {
                    raw: true,
                    attributes: [
                        'id',
                    ],
                    where: {
                        name
                    }
                } ).then( gem => {
                    if( gem ) {
                        resolve( gem.id )
                    } else {
                        resolve( false );
                    }
                } );
            } )
        } else {
            return this.apiRequest( 'getIdByName', { name } );
        }
    }

    getPricesRow() {
        const isThisApiRequest = isApiRequest( arguments );
        const requestingUser = getRequestingUser( arguments );

        let { gemId, grade, weight } = arguments[ 0 ];

        gemId = gemId || this.getProp( 'id' );

        if (!gemId) { throw 'gem id must be provided' }
        if (!weight) { throw 'weight must be provided' }

        weight = Number.parseFloat(weight)

        if (!weight) { throw 'weight must be a number' }

        weight = (Math.trunc(weight * 100) / 100)

        if( isServer() ) {
            return new Promise( ( resolve, reject ) => {

                const doGetPrices = () => {
                    global.DB.sequelize.query(
                        'SELECT grade, price FROM "Prices" where "Prices"."gem_id" = ? AND numrange("Prices"."min_weight"::numeric, "Prices"."max_weight"::numeric) @> ?::numeric ORDER BY "Prices"."grade", "Prices"."price" ASC',
                        {
                            type: global.DB.Sequelize.QueryTypes.SELECT,
                            replacements: [gemId, weight]
                        }
                    ).then(results => {

                        if (results.length === 0) {
                            global.DB.sequelize.query(
                                'SELECT min(min_weight), max(max_weight) FROM "Prices" WHERE gem_id = ?',
                                {
                                    type: global.DB.Sequelize.QueryTypes.SELECT,
                                    replacements: [gemId]
                                }
                            ).then(weights => {
                                resolve({
                                    code: 'invalid_weight',
                                    values: [weights[0].min, weights[0].max],
                                });
                            });

                        } else {
                            let prices = {}

                            _.each(results, result => {
                                if (typeof prices[result.grade] === 'undefined') {
                                    prices[result.grade] = []
                                }

                                prices[result.grade].push(result.price)
                            })

                            resolve( prices );
                        }

                    });
                }

                if( isThisApiRequest ) {
                    if( requestingUser ) {
                        doGetPrices();
                    } else {
                        reject( {
                            status: 403,
                            error: new Error( `Requester must be identified` )
                        } );
                    }
                } else {
                    doGetPrices();
                }
            } )
        } else {
            return this.apiRequest( 'getPricesRow', { gemId, grade, weight } );
        }
    }

    getPrice() {
        let { gemId, grade, weight, range } = arguments[ 0 ];
        const requestingUser = getRequestingUser( arguments );

        gemId = gemId || this.getProp( 'id' );

        if (!gemId) { throw 'gem id must be provided' }
        if (!weight) { throw 'weight must be provided' }
        if (!grade) { throw 'grade must be provided' }

        grade = parseInt( grade );

        if (grade === NaN) { throw 'grade must be an integer' }

        weight = Number.parseFloat(weight)

        if (weight === NaN) { throw 'weight must be a number' }

        weight = (Math.trunc(weight * 100) / 100)

        if( isServer() ) {
            return new Promise( ( resolve, reject ) =>
                this.getPrices.apply( this, arguments ).then( prices => {
                    if( prices.length ) {
                        if( prices.length > 1 ) {
                            resolve( range ? prices : (  prices[ 0 ].price +  prices[ 1 ].price ) / 2 );
                        } else {
                            const gem = new Gem({ id: gemId });
                            gem.getMeta({ key: 'info' }).then(info => {
                                global.trackRequest('Pricing Request', arguments[1], requestingUser, {
                                    gem: info.gPVariety,
                                    cut: null,
                                    weight: weight,
                                    color: null,
                                    clarity: null,
                                    arguments: { gemId, grade, weight }
                                });
                            })
                            resolve( prices[ 0 ].price );
                        }
                    } else {
                        resolve( null );
                    }
                } ).catch( error =>
                    reject( error )
                )
            );
        } else {
            return this.apiRequest( 'getPrice', arguments[ 0 ] );
        }
    }

    getMetaFields( id ) {
        if( isServer() ) {
            const { id } = arguments[ 0 ];

            if( !id ) throw `id property must be provided`;

            return new Promise( ( resolve, reject ) => {
                const gem = new Gem( { id } );

                gem.getMeta( { key: 'info' } ).then( info => {
                    gem.getMeta( { key: 'gem_shape_quality_price' } ).then( shapeQualityPrice => {
                        resolve( { info, shapeQualityPrice } );
                    } );
                } );
            } );
        }
        else {
            return this.apiRequest( 'getMetaFields', id );
        }
    }
}

Gem.defaultWeight = 1;

Gem.inclusions = [
    {
        id: 1,
        value: 0,
        label: 'Free of Inclusion'
    },
    {
        id: 2,
        value: -0.5,
        label: 'Lightly Included'
    },
    {
        id: 3,
        value: -1,
        label: 'Moderately Included'
    },
    {
        id: 4,
        value: -1.5,
        label: 'Highly Included'
    },
    {
        id: 5,
        value: -2,
        label: 'Excessively Included'
    }
];

Gem.textures = [
    {
        id: 1,
        value: 0,
        label: 'Transparent'
    },
    {
        id: 2,
        value: 0,
        label: 'Faint'
    },
    {
        id: 3,
        value: -0.5,
        label: 'Moderate'
    },
    {
        id: 4,
        value: -1,
        label: 'Strong'
    },
    {
        id: 5,
        value: -1.5,
        label: 'Prominent'
    }
];

Gem.zonings = [
    {
        id: 1,
        value: 0,
        label: 'Z-1'
    },
    {
        id: 2,
        value: -0.5,
        label: 'Z-2'
    },
    {
        id: 3,
        value: -1,
        label: 'Z-3'
    }
];

// Ranges are inclusive
// Brilliance is a percentage represented as an integer
Gem.brillianceInterpolations = [
    {
        range: [ 75, 100 ],
        value: 0
    },
    {
        range: [ 60, 74 ],
        value: -0.5
    },
    {
        range: [ 40, 59 ],
        value: -1
    },
    {
        range: [ 0, 39 ],
        value: -1.5
    }
];

Gem.crownHeights = [
    {
        id: 1,
        value: 0,
        label: 'Acceptable'
    },
    {
        id: 2,
        value: -0.5,
        label: 'Shallow'
    },
    {
        id: 3,
        value: -0.5,
        label: 'High'
    }
];

Gem.pavillionDepths = [
    {
        id: 1,
        value: 0,
        label: 'Acceptable'
    },
    {
        id: 2,
        value: -0.5,
        label: 'Shallow'
    },
    {
        id: 3,
        value: -0.5,
        label: 'Deep'
    }
];

Gem.bulges = [
    {
        id: 1,
        value: 0,
        label: 'Acceptable'
    },
    {
        id: 2,
        value: -0.5,
        label: 'Unacceptable'
    }
];

Gem.girdles = [
    {
        id: 1,
        value: 0,
        label: 'Acceptable'
    },
    {
        id: 2,
        value: -0.5,
        label: 'Unacceptable'
    }
];

Gem.tableSizes = [
    {
        id: 1,
        value: 0,
        label: 'Acceptable'
    },
    {
        id: 2,
        value: -0.5,
        label: 'Unacceptable'
    }
];

Gem.symmetries = [
    {
        id: 1,
        value: 0,
        label: 'EX'
    },
    {
        id: 2,
        value: 0,
        label: 'VG'
    },
    {
        id: 3,
        value: -0.5,
        label: 'G'
    },
    {
        id: 4,
        value: -1,
        label: 'F'
    },
    {
        id: 5,
        value: -1.5,
        label: 'P'
    }
];

Gem.polishes = [
    {
        id: 1,
        value: 0,
        label: 'EX'
    },
    {
        id: 2,
        value: 0,
        label: 'VG'
    },
    {
        id: 3,
        value: 0,
        label: 'G'
    },
    {
        id: 4,
        value: -0.5,
        label: 'F'
    },
    {
        id: 5,
        value: -1,
        label: 'P'
    }
];

export default Gem;
