import React from 'react';
import _ from 'underscore';
import { isServer, isApiRequest, getRequestingUser, apiRequest , savePricingLogs} from 'shared/utilities';

class Pearl extends React.Component {
	apiRequest(method, data) {
		return apiRequest( 'Pearl', method, data );
	}

	getPrices() {
		const isThisApiRequest = isApiRequest(arguments);
		const requestingUser = getRequestingUser(arguments);

		let { type } = arguments[0];

		if( isServer() ) {
			return new Promise( ( resolve, reject ) => {
				const doGetPrices = () => {
					global.DB.Price.findAll({
						where: {
							pearl_type: type
						}
					}).then( prices => {
						if( ! prices ){
							savePricingLogs(arguments[1].user, null, arguments[1].url, 200 , arguments[0])
							return resolve( null );
						} 

						return resolve( prices );
					}).catch( error =>
						reject({ status: 500, error })
					)
				};

				if( isThisApiRequest ) {
					if( requestingUser ) {
						doGetPrices();
					} else {
						reject({
							status: 403,
							error: new Error(`Requester must be identified`)
						});
					}
				} else {
					doGetPrices();
				}
			})
		} else {
			return this.apiRequest( 'getPrices', arguments[0] );
		}
	}
}

Pearl.types = [
	{
		id: 1,
		label: 'Tahitian Black Cultured Pearls - Round',
		importKey: 'Black Tahitian Cultured Round'
	},
	{
		id: 2,
		label: 'Tahitian Black Cultured Pearls - Drop',
		importKey: 'Blk. Tahitian Cul. Drop/Button'
	},
	{
		id: 3,
		label: 'Tahitian Black Cultured Pearls - Round Matched Pairs',
		importKey: 'Blk. Tahitian Cul. Round Pairs'
	},
	{
		id: 4,
		label: 'Akoya Cultured',
		importKey: 'Cultured Saltwater'
	},
	{
		id: 5,
		label: 'Mabe Pearls',
		importKey: 'Mabe'
	},
	{
		id: 6,
		label: 'South Sea Cultured Pearls - White',
		importKey: 'SOUTH SEA CULTURED'
	},
	{
		id: 7,
		label: 'South Sea Cultured Pearls - Golden-Round',
		importKey: 'South Sea Cultured-Golden Rd'
	}
]

export default Pearl;
