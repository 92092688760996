import React from 'react';
import _ from 'underscore';
import { isServer, isApiRequest, getRequestingUser, apiRequest , savePricingLogs} from 'shared/utilities';

class PearlStrand extends React.Component {
	apiRequest(method, data) {
		return apiRequest( 'PearlStrand', method, data );
	}

	getPrices() {
		const isThisApiRequest = isApiRequest(arguments);
		const requestingUser = getRequestingUser(arguments);

		let { type } = arguments[0];

		if( isServer() ) {
			return new Promise( ( resolve, reject ) => {
				const doGetPrices = () => {
					global.DB.Price.findAll({
						attributes: [
							'id',
							'price',
							'min_weight',
							'max_weight',
							'grade',
							'pearl_strand_type',
							'created_at',
							'updated_at'
						],
						where: {
							pearl_strand_type: type
						}
					}).then( prices => {
						if( ! prices ){
							savePricingLogs(arguments[1].user, null, arguments[1].url, 200 , arguments[0])
							return resolve( null );
						} 

						return resolve( prices );
					}).catch( error =>
						reject({ status: 500, error })
					)
				};

				if( isThisApiRequest ) {
					if( requestingUser ) {
						doGetPrices();
					} else {
						reject({
							status: 403,
							error: new Error(`Requester must be identified`)
						});
					}
				} else {
					doGetPrices();
				}
			})
		} else {
			return this.apiRequest( 'getPrices', arguments[0] );
		}
	}
}

PearlStrand.types = [
	{
		id: 1,
		label: 'Ayoka Cultured Pearl Strands',
		importKey: 'Akoya Cultured-16"'
	},
	{
		id: 2,
		label: 'Ayoka Cultured Pearl Strands - Graduated',
		importKey: 'Akoya Cultured-Graduated'
	},
	{
		id: 3,
		label: 'Baroque Cultured',
		importKey: 'Baroque Cultured'
	},
	{
		id: 4,
		label: 'Tahitian Black Cultured Pearl Graduated Strands',
		importKey: 'Black Tahitian Cultured-Round'
	},
	{
		id: 5,
		label: 'Chinese Freshwater Cultured Pearls (Off-Round to Round, White)',
		importKey: 'Chinese Freshwater Cultured'
	},
	{
		id: 6,
		label: 'Chinese Freshwater Cultured Pearls - Coins',
		importKey: 'Chinese Freshwater-Coins'
	},
	{
		id: 7,
		label: 'Chinese Freshwater Cultured Pearls - Edison-type',
		importKey: 'Chinese Freshwater-Edison Type'
	},
	{
		id: 8,
		label: 'Chinese Freshwater Bead-In Baroque Cultured Pearls - Fireballs',
		importKey: 'Chinese Freshwater-Fireballs'
	},
	{
		id: 9,
		label: 'Chinese Freshwater Cultured Pearls - Kasumi-like',
		importKey: 'Chinese Freshwater-Kasumi-Like'
	},
	{
		id: 10,
		label: 'Chinese Freshwater - Rd Colored',
		importKey: 'Chinese Freshwater-Rd Colored'
	},
	{
		id: 11,
		label: 'Chinese Freshwater - Soufflé',
		importKey: 'Chinese Freshwater-Souffle'
	},
	{
		id: 12,
		label: 'South Sea Cultured',
		importKey: 'South Sea Cultured'
	}
]

export default PearlStrand;
