import React from 'react';

class CurrencyExchange extends React.Component {

    render() {
        const { currency, exchangeRates } = this.props.pricing;
        const value = this.props.value;

        const exchange = ( val ) => {
            if( val == 0 ) return '–';
            return currency === 'USD' ? val : Math.round( exchangeRates[currency] * val );
        }

        if( typeof value === 'number' )
            return (
                <span className="CurrencyExchange">{ exchange( value ) }</span>
            );
        else if( typeof value === 'object' )
            return (
                <span className="CurrencyExchange">{ exchange( value[0] ) }{ value[1] ? `-${exchange( value[1] )}` : '' }</span>
            );
    }
}

export default CurrencyExchange;