import React from 'react';
import LoadingIndicator from 'react-loading-indicator';

class ResultWell extends React.Component {
	render() {
		const {
			className,
			label,
			value,
			valuePrefix,
			isLoading,
			loadingIndicatorProps,
			hideOnNullValue,
			isClickable,
			...childProps
		} = this.props;

		return value || ! hideOnNullValue ? (
			<div
				{ ...childProps }
				className={`
					resultWell
					${ isLoading ? 'loading' : 'notLoading' }
					${ value ? 'hasValue' : 'noValue' }
					${ isClickable ? 'isClickable' : 'isNotClickable' }
					${ className }
				`}
				data-label={label}
			>
				{
					valuePrefix ? (
						<span
							className="resultWell-valuePrefix"
							children={valuePrefix}
						/>
					) : (
						null
					)
				}

				{
					isLoading ? (
						<LoadingIndicator
							{ ...this.constructor.defaultProps.loadingIndicatorProps }
							{ ...loadingIndicatorProps }
						/>
					) : (
						value ? value : <span className="resultWell-valuePlaceholder" />
					)
				}
			</div>
		) : (
			null
		);
	}
}

ResultWell.propTypes = {
	label: React.PropTypes.string.isRequired,
	value: React.PropTypes.oneOfType([
		React.PropTypes.string,
		React.PropTypes.number
	]),
	valuePrefix: React.PropTypes.string,
	isLoading: React.PropTypes.bool,
	loadingIndicatorProps: React.PropTypes.object.isRequired,
	hideOnNullValue: React.PropTypes.bool,
	isClickable: React.PropTypes.bool
};

ResultWell.defaultProps = {
	isLoading: false,
	loadingIndicatorProps: {
		segmentLength: 3,
		segmentWidth: 2,
		segments: 12,
		spacing: 1.5,
		color: {
			alpha: 0.8,
			blue: 255,
			green: 255,
			red: 255
		}
	},
	hideOnNullValue: false,
	isClickable: false
}

export default ResultWell;
