import _ from 'underscore';
import $ from 'jquery';
import React from 'react';
import Draggable from 'react-draggable';
import {
	sectorValues,
	absoluteHueToSector,
	munsellToAngle,
	angleToRadians,
	angleToCssHue,
	giaSaturationToCssSaturation,
	giaToneToCssLightness,
	munsellToCssHue,
	saturationRange,
	toneRange
} from 'shared/utilities';

class ColorSectorPicker extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dragging: false,
			// While dragging the drag handle, reflects the sector that is closest to the handle.
			closestSectorToHandle: null
		};

		this.dragHandleDidMove = this.dragHandleDidMove.bind(this);
		this.dragHandleDidRest = this.dragHandleDidRest.bind(this);
	}

	componentDidMount() {
		this.calculatePossibleSectorNames( this.props );
		this.drawColorWheel( this.props );
		this.positionIndicator( absoluteHueToSector( this.props.hue ) );
		this.alignDragHandle();
	}

	componentWillReceiveProps( nextProps ) {
		if( this.props.hues !== nextProps.hues ) {
			this.calculatePossibleSectorNames( nextProps );
			this.drawColorWheel( nextProps );
		}

		if(
			absoluteHueToSector( this.props.hue ) !== absoluteHueToSector( nextProps.hue ) ||
			this.props.tone !== nextProps.tone ||
			this.props.saturation !== nextProps.saturation
		) {
			this.positionIndicator( absoluteHueToSector( nextProps.hue ), nextProps.tone, nextProps.saturation );
		}
	}

	componentDidUpdate() {
		if( ! this.state.dragging ) {
			this.alignDragHandle();
		}
	}

	dragHandleDidMove() {
		this.state.dragging ? null : this.setState({ dragging: true });

		const closestSectorToHandle = this.getClosestSectorToHandle();

		this.props.onChange( closestSectorToHandle );

		// Reposition the indicator to the closest sector
		this.setState( { closestSectorToHandle }, this.positionIndicator );
	}

	getClosestSectorToHandle() {
		// Determine the positions of all possible hue sectors for this gem
		const sectorPositions = _.object(
			this.possibleSectorNames.map( sectorName => [
				sectorName,
				this.calculateSectorPosition(sectorName)
			])
		);

		// Determine the position of the center of the
		// drag handle relative to the parent element
		const dragHandlePosition = {
			top: this.dragHandle.offsetTop + this.dragHandleDraggable.state.y,
			left: this.dragHandle.offsetLeft + this.dragHandleDraggable.state.x
		};

		// Determine which sector the drag handle is closest to
		const closestSectorToHandle = _.reduce( sectorPositions,
			( closestSector, sectorPosition, sectorName ) => {
				let delta = {
					x: Math.abs( dragHandlePosition.left - sectorPosition.left ),
					y: Math.abs( dragHandlePosition.top - sectorPosition.top )
				};

				let distance = Math.sqrt( Math.pow( delta.x, 2 ) + Math.pow( delta.y, 2 ) );

				let thisSector = { sectorName, sectorPosition, distance };

				return closestSector && closestSector.distance <= distance ? closestSector : thisSector;
			}, null
		);

		return closestSectorToHandle;
	}

	dragHandleDidRest() {
		this.setState({ dragging: false });

		// Determine the name of the sector encompassing the current hue
		const prevSectorName = absoluteHueToSector( this.props.hue )

		// If the closest sector has changed, call onAfterChange
		if( prevSectorName != this.state.closestSectorToHandle ) {
			this.props.onAfterChange( this.state.closestSectorToHandle );

			return false;
		}
	}

	// Calculate all of the sectors that contain the hues passed in `hues` prop
	calculatePossibleSectorNames({ hues }) {
		this.possibleSectorNames = hues.reduce( ( sectors, hue ) => (
			_.union( sectors, [absoluteHueToSector(hue)] )
		), []);
	}

	// Calculates the point on the circle where the indicator should be centered
	// Returns the left and top displacements relative to the parent element
	calculateSectorPosition(sectorName) {
		// The range of hue values the encompasses
		const sectorRange = sectorValues[sectorName];

		// The median hue for the sector
		const sectorHue = ( sectorRange[0] + sectorRange[1] ) / 2;

		// The angle on the color wheel for the sector hue
		const sectorAngle = munsellToAngle( sectorHue );

		// The radius of the color wheel
		const radius = $( this.colorForeground ).width() / 2;

		// Calculate the point on the circle at the hue's angle
		return {
			left: radius + radius * Math.cos( angleToRadians(sectorAngle) ),
			top: radius + radius * Math.sin( angleToRadians(sectorAngle) ) * -1
		};
	}

	// Positions the indicator at the specified sector
	positionIndicator(sectorName, tone, saturation) {
		sectorName = sectorName || this.state.closestSectorToHandle.sectorName;
		tone = tone || this.props.tone;
		saturation = saturation || this.props.saturation;

		// The range of hue values the sector encompasses
		const sectorRange = sectorValues[sectorName];

		// The median hue for the sector
		const sectorHue = ( sectorRange[0] + sectorRange[1] ) / 2;

		return this.setState({
			indicatorStyles: {
				...this.calculateSectorPosition(sectorName),
				background: `hsl(
					${munsellToCssHue(sectorHue)},
					${giaSaturationToCssSaturation(saturation)}%,
					${giaToneToCssLightness(tone)}%
				)`
			}
		});
	}

	// Positions the drag handle so that it is directly on top of the hue indicator
	alignDragHandle() {
		this.dragHandleDraggable.setState({ x: 0, y: 0 });

		$( this.dragHandle ).css({
			top: `${this.indicator.offsetTop - this.indicator.offsetHeight / 2}px`,
			left: `${this.indicator.offsetLeft - this.indicator.offsetWidth / 2}px`
		});
	}

	drawColorWheel({ tone, saturation, hues }) {
		const context = this.colorBackground.getContext( '2d' );
		const x = this.colorBackground.width / 2;
		const y = this.colorBackground.height / 2;
		const radius = x;

		// Angle that each drawing arc encompasses
		const arcWidth = 2;

		// Clear the canvas
		context.clearRect(0, 0, this.colorBackground.width, this.colorBackground.height);

		// Create the background that shows disabled colors
		context.fillStyle = 'gray';
		context.beginPath();
		context.arc( x, y, radius, 0, Math.PI * 2 );
		context.fill();

		// Draw each sector
		this.possibleSectorNames.forEach( sector => {
			for(
				let angle = munsellToAngle( sectorValues[sector][0] ) + arcWidth;
				angle <= munsellToAngle( sectorValues[sector][1] );
				angle += 1
			) {
				// Shift color drawing arc to so that sectors are centered in the circle axes
				// const drawShift = munsellToAngle(5);

				let startAngleRadians = angleToRadians( angle - arcWidth ) * -1;
				let endAngleRadians = angleToRadians( angle ) * -1;

				context.beginPath();
				context.moveTo(x, y);
				context.arc(x, y, radius, startAngleRadians, endAngleRadians, true);
				context.closePath();

				let gradient = context.createRadialGradient(x, y, 0, x, y, radius);

				gradient.addColorStop( 0, `
					hsl(${angleToCssHue(angle)},
					${giaSaturationToCssSaturation(saturation)}%,
					${giaToneToCssLightness(tone)}%)
				`);
				gradient.addColorStop( 1, `
					hsl(${angleToCssHue(angle)},
					${giaSaturationToCssSaturation(saturation)}%,
					${giaToneToCssLightness(tone)}%)
				`);
				context.fillStyle = gradient;
				context.fill();
			}
		})
	}

	render() {
		return (
			<div className="colorSectorPicker">
				<div className="colorSectorPicker-wrapper">
					<div
						className="colorSectorPicker-background"
						data-sector-name={ absoluteHueToSector(this.props.hue) }
					>
						<canvas
							ref={ colorBackground => this.colorBackground = colorBackground }
							width={500}
							height={500}
						/>
					</div>
					<div
						className="colorSectorPicker-foreground"
						ref={ colorForeground => this.colorForeground = colorForeground }
					>
						<div
							className="colorSectorPicker-indicator"
							ref={ indicator => this.indicator = indicator }
							style={ this.state.indicatorStyles }
						/>
						<Draggable
							ref={ dragHandleDraggable => this.dragHandleDraggable = dragHandleDraggable }
							onDrag={ _.throttle( this.dragHandleDidMove, 100 ) }
							onStop={ this.dragHandleDidRest }
						>						
							<div
								className="colorSectorPicker-dragHandle"
								ref={ dragHandle => this.dragHandle = dragHandle }
								style={ this.state.dragHandleStyles }
							/>
						</Draggable>
					</div>
				</div>
			</div>
		);
	}
}

ColorSectorPicker.propTypes = {
	// Optional
	saturation: ( props, propName, componentName ) => {
		if(
			props[propName] &&
			! _.isNumber( props[propName] ) ||
			props[propName] < saturationRange[0] ||
			props[propName] > saturationRange[1]
		) {
			return new Error(`Invalid prop ${propName} supplied to ${componentName}. Must be a valid GIA Saturation value (number between 1 and 6).`);
		}
	},
	// Optional
	tone: ( props, propName, componentName ) => {
		if(
			props[propName] &&
			! _.isNumber( props[propName] ) ||
			props[propName] < toneRange[0] ||
			props[propName] > toneRange[1]
		) {
			return new Error(`Invalid prop ${propName} supplied to ${componentName}. Must be a valid GIA Tone value (number between 2 and 8).`);
		}
	},
	// All of the possible hues this stone can have
	hues: ( props, propName, componentName ) => {
		if(
			! props[propName].length ||
			props[propName].length < 1 ||
			props[propName].reduce( ((isInvalid, hue) => isInvalid || hue < 0 || hue > 100), false )
		) {
			return new Error(`Invalid prop ${propName} supplied to ${componentName}. Must be an array of at least one number between 0 and 100.`);
		}
	},
	hue: React.PropTypes.number,
	// Called when the drag handle is dragged closer to a new sector
	// Passed an object describing the new sector
	onChange: React.PropTypes.func,
	// Called when the drag handle is dragged and released closer to a new sector
	// Passed an object describing the new sector
	onAfterChange: React.PropTypes.func
};

ColorSectorPicker.defaultProps = {
	saturation: 8,
	tone: 6,
	hues: [ 0, 100 ],
	onChange: new Function(),
	onAfterChange: new Function()
}

export default ColorSectorPicker;
