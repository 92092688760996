import React from 'react';
import RCSlider from 'rc-slider';
import $ from 'jquery';

class Slider extends RCSlider {
	constructor(props) {
		super(props);

		this.drawTrackGradient = this.drawTrackGradient.bind(this);
	}

	componentDidMount() {
		if( this.props.trackGradient ) {
			window.addEventListener( 'resize', this.drawTrackGradient );

			// Force update after mount so that the canvas
			// element can access the size of the slider
			this.forceUpdate( this.drawTrackGradient );
		}
	}

	componentDidUpdate() {
		this.drawTrackGradient();
	}

	drawTrackGradient() {
		const { trackGradient } = this.props;

		if( trackGradient ) {
			// Draw the gradient after the canvas element has been mounted
			const context = this.gradientCanvas.getContext( '2d' );

			const gradient = context.createLinearGradient( 0, 0, this.gradientCanvas.offsetWidth, 0 );

			gradient.addColorStop( 0, trackGradient[0] );
			gradient.addColorStop( 1, trackGradient[1] );

			context.fillStyle = gradient;
			context.fillRect( 0, 0, this.gradientCanvas.offsetWidth, this.gradientCanvas.offsetHeight );
		}
	}

	render() {
		return (
			<div className={`
				slider
				${ this.props.trackGradient ? 'hasTrackGradient' : 'doesNotHaveTrackGradient' }
			`}>
				<RCSlider
					{...this.props}
					ref={ slider => this.slider = slider }
				/>
				{
					this.props.trackGradient && this.slider ? (
						<canvas
							className="slider-trackGradient"
							ref={ gradientCanvas => this.gradientCanvas = gradientCanvas }
							width={ $( this.slider.refs.slider ).find('.rc-slider-rail').width() }
							height={ $( this.slider.refs.slider ).find('.rc-slider-rail').height() }
						/>
					) : (
						null
					)
				}
			</div>
		);
	}
}

class Handle extends React.Component {
	render() {
		const { vertical, offset, value } = this.props;

		const style = vertical ? { bottom: `${offset}%` } : { left: `${offset}%` };

		return (
			<div
				style={style}
				className="slider-handle--value"
				data-value={value}
			/>
		)
	}
}

Slider.propTypes = {
	...Slider.propTypes,
	// An array of two valid css color expressions
	trackGradient: React.PropTypes.array
};

Slider.defaultProps = {
	...Slider.defaultProps,
	handle: <Handle />
}

export default Slider;
