import React from 'react';
import {absoluteUrl, setClientKey, setOAuthVerifier, getClientKey, getOAuthVerifier, clearOAuth, clearClientKey} from 'shared/utilities';
import Layout from 'shared/components/Layout';
import User from 'shared/components/User';
import { getQueryVariable } from "../../utilities";
import LoadingIndicator from 'react-loading-indicator';


class ApiLoginLayout extends Layout {
    constructor() {
        super();

        this.state = {
            failure: false,
            client_key: null,
            oauth_verifier: null
        }
    }


    displayOAuthAuthorizationUI() {
        const api = window.location.pathname === '/api-login'  ? 1 : 0;
        const client_key = getQueryVariable( 'client_key' ) || getClientKey() || '';
        
        setClientKey(client_key)

        new User().getOAuthAuthorizeUrl( api, client_key ).then( oAuthAuthorizeUrl => {
            const windowFeatures = `
				height=500,
				width=400,
				location=no
			`;

            const onFailure = () => window.location.search = window.location.search ? `${window.location.search}&failure=1` : `?failure=1`;

            if( oAuthAuthorizeUrl ) {
                window.location.href = oAuthAuthorizeUrl;
            } else {
                clearOAuth();
                onFailure();
            }
        });
    }

    componentDidMount() {
        super.componentDidMount.apply( this, arguments );

        const isApi = window.location.pathname === '/api_authorize'  ? 1 : 0;

        if( isApi ) {
            const client_key = getQueryVariable( 'client_key' ) || getClientKey() || '';

            if( !client_key ) {
                console.log("[ApiLoginLayout][componentDidMount][ERROR] No client_key", this.state)

                clearOAuth();            

                this.setState( { failure: true, client_key: null, oauth_verifier: null } )
            } else {
                setClientKey(client_key)

                this.setState( { failure: false, client_key: client_key, oauth_verifier: null } )
                
                new User().getApiFailureRedirect( client_key ).then( failureRedirect => {
                    if( !failureRedirect ) {
                        console.log("[ApiLoginLayout][componentDidMount][ERROR] failure redirect not found redirection to /", this.state)

                        window.location.href = '/';
                    } else {

                        new User().getOAuthAuthorizeUrl( true, client_key ).then( oAuthAuthorizeUrl => {
                            if( oAuthAuthorizeUrl ) {
                                console.log("[ApiLoginLayout][componentDidMount][getOAuthAuthorizeUrl][INFO] redirect to oAuthAuthorizeURL", this.state, client_key, oAuthAuthorizeUrl)

                                window.location.href = oAuthAuthorizeUrl;
                            } else {
                                console.log("[ApiLoginLayout][componentDidMount][getOAuthAuthorizeUrl][INFO] redirect to failure url", this.state, client_key, failureRedirect)
                                
                                clearOAuth();
                                
                                window.location.href = failureRedirect;
                            }
                        } );
                    }
                } );
            }
        } else {
            const client_key = getQueryVariable( 'client_key' ) || this.state.client_key || getClientKey() || '';
            const oauth_verifier = getQueryVariable( 'oauth_verifier' ) || this.state.oauth_verifier || getOAuthVerifier() ||  '';

            this.setState({ failure: false, client_key: client_key, oauth_verifier: oauth_verifier })

            setClientKey(client_key)

            setOAuthVerifier(oauth_verifier)

            console.log("[ApiLoginLayout][componentDidMount][getOAuthAuthorizeUrl][INFO] get client_key and oauth_verifier", this.state, getClientKey(), getOAuthVerifier(), client_key, oauth_verifier)

            if( !client_key || !oauth_verifier ) {
                console.log("[ApiLoginLayout][componentDidMount][getOAuthAuthorizeUrl][ERROR] client_key and oauth_verifier are undefined", this.state, client_key, oauth_verifier)

                this.setState( { failure: true, client_key: client_key, oauth_verifier: oauth_verifier } )
            }
            
            console.log("[ApiLoginLayout][componentDidMount][getOAuthAuthorizeUrl][INFO]", this.state, client_key, oauth_verifier)

        }
    }

    displayLayout() {
        const errorMessageParam = new URL( window.location.href ).searchParams.get('error_message');
        //const errorMessage = errorMessageParam || this.props.labels.unauthorizedMessage;

        return (
            <div className="row">
                <div className="small-centered small-12 medium-8 large-6 columns">
                    <div className="hide-for-medium">
                        <div className="globalHeader-logo">
                            <img
                                src={ absoluteUrl('/static/images/logo.png') }
                                alt="Gemguide Logo"
                            />
                        </div>
                    </div>
                    <div className="login-wrapper" style={ {
                        display: 'flex',
                        height: '60vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                    } }>
                        {
                            this.state.failure ? (
                                <div
                                    className="animated fadeInUp login-failureMessage"
                                    children={ `There was a problem authorizing the user.` }
                                    style={ { position: 'static' } }
                                />
                            ) : (
                                <LoadingIndicator segmentWidth={ 8 } segmentLength={ 10 } spacing={ 10 }/>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

ApiLoginLayout.defaultProps = {
    ...ApiLoginLayout.defaultProps,
    wrapperClass: 'login',
    showMobileNav: false,
    labels: {
        serverErrorMessage: `Something went wrong. Please try again. Contact support if the problem persists.`,
        unauthorizedMessage: `You are not authorized to access this application.`,
        forgotPassword: 'Forgot Password?',
        signup: 'Not a Subscriber?'
    }
};

export default ApiLoginLayout;
