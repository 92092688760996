import React from 'react';
import { connect } from 'react-redux';
import Slider from 'shared/components/Slider';
import Panel from 'shared/components/color-calculator-panels/Panel';

class BrilliancePanel extends Panel {
	displayPanel() {
		return (
			<div className={`${this.constructor.className}-wrapper`}>
				<div className="brilliance panel-block">
					<label>
						<span
							className="label"
							children={this.props.labels.brilliance}
						/>
						<Slider
							step={5}
							min={5}
							max={100}
							value={ this.props.gem.brilliance }
							onChange={ brilliance => this.props.dispatch({ type: 'SET_GEM_PROPS', props: { brilliance } }) }
						/>
					</label>
				</div>
				<div className="windowing panel-block">
					<label>
						<span
							className="label"
							children={this.props.labels.windowing}
						/>
						<Slider
							step={5}
							min={0}
							max={95}
							value={ this.props.gem.windowing }
							onChange={ windowing => this.props.dispatch({ type: 'SET_GEM_PROPS', props: { windowing } }) }
						/>
					</label>
				</div>
				<div className="extinction panel-block">
					<label>
						<span
							className="label"
							children={this.props.labels.extinction}
						/>
						<Slider
							step={5}
							min={0}
							max={95}
							value={ this.props.gem.extinction }
							onChange={ extinction => this.props.dispatch({ type: 'SET_GEM_PROPS', props: { extinction } }) }
						/>
					</label>
				</div>
			</div>
		);
	}
}

BrilliancePanel.label = 'Brilliance';
BrilliancePanel.className = 'brilliance';

BrilliancePanel.defaultProps = {
	...BrilliancePanel.defaultProps,
	labels: {
		brilliance: 'Brilliance',
		windowing: 'Windowing',
		extinction: 'Extinction'
	}
};

BrilliancePanel.drawerItems = [
	{
		label: 'Brilliance',
		valueCallback: gem => `${gem.brilliance}%`
	},
	{
		label: 'Windowing',
		valueCallback: gem => `${gem.windowing}%`
	},
	{
		label: 'Extinction',
		valueCallback: gem => `${gem.extinction}%`
	}
];

export default connect( ({ gem }) => ({ gem }) )(BrilliancePanel);
