import React from 'react';
import _ from 'underscore';
import { isServer, isApiRequest, getRequestingUser, apiRequest , savePricingLogs} from 'shared/utilities';

class ColoredDiamond extends React.Component {
	apiRequest(method, data) {
		return apiRequest( 'ColoredDiamond', method, data );
	}

	getPrices() {
		const isThisApiRequest = isApiRequest(arguments);
		const requestingUser = getRequestingUser(arguments);
		let { type } = arguments[0];
		if( isServer() ) {
			return new Promise( ( resolve, reject ) => {
				const doGetPrices = () => {
					global.DB.Price.findAll({
						attributes: [
							'id',
							'price',
							'min_weight',
							'max_weight',
							'diamond_clarity',
							'colored_diamond_type',
							'created_at',
							'updated_at',
						],
						where: {
							colored_diamond_type: type
						}
					}).then( prices => {

						if( ! prices ){
							savePricingLogs(arguments[1].user, null, arguments[1].url, 200 , arguments[0])
							return resolve( null );
						} 

						return resolve( prices );
					}).catch( error =>
						reject({ status: 500, error })
					)
				};

				if( isThisApiRequest ) {
					if( requestingUser ) {
						doGetPrices();
					} else {
						reject({
							status: 403,
							error: new Error(`Requester must be identified`)
						});
					}
				} else {
					doGetPrices();
				}
			})
		} else {
			return this.apiRequest( 'getPrices', arguments[0] );
		}
	}
}

ColoredDiamond.types = [
	{
		id: 5,
		label: 'Fancy Intense Yellow',
		importKey: 'Fancy Intense Yellow'
	},
	{
		id: 8,
		label: 'Fancy Light Yellow',
		importKey: 'Fancy Light Yellow'
	},
	{
		id: 9,
		label: 'Fancy Light Brown',
		importKey: 'Fancy Light Brown'
	},
	{
		id: 10,
		label: 'Fancy Brown',
		importKey: 'Fancy Brown'
	},
	{
		id: 12,
		label: 'Fancy Yellow',
		importKey: 'Fancy Yellow'
	}
];

export default ColoredDiamond;

// ColoredDiamond.types = [
// 	{
// 		id: 1,
// 		label: 'Fancy Brownish Pink',
// 		importKey: 'Fancy Brownish Pink'
// 	},
// 	{
// 		id: 2,
// 		label: 'Fancy Brownish Yellow',
// 		importKey: 'Fancy Brownish Yellow'
// 	},
// 	{
// 		id: 3,
// 		label: 'Fancy Intense Brownish Yellow',
// 		importKey: 'Fancy Intense Brownish Yellow'
// 	},
// 	{
// 		id: 4,
// 		label: 'Fancy Intense Pink',
// 		importKey: 'Fancy Intense Pink'
// 	},
// 	{
// 		id: 5,
// 		label: 'Fancy Intense Yellow',
// 		importKey: 'Fancy Intense Yellow'
// 	},
// 	{
// 		id: 6,
// 		label: 'Fancy Light Brownish Pink',
// 		importKey: 'Fancy Light Brownish Pink'
// 	},
// 	{
// 		id: 7,
// 		label: 'Fancy Light Pink',
// 		importKey: 'Fancy Light Pink'
// 	},
// 	{
// 		id: 8,
// 		label: 'Fancy Light Yellow',
// 		importKey: 'Fancy Light Yellow'
// 	},
// 	{
// 		id: 9,
// 		label: 'Fancy Light Brown',
// 		importKey: 'Fancy Light Brown'
// 	},
// 	{
// 		id: 10,
// 		label: 'Fancy Brown',
// 		importKey: 'Fancy Brown'
// 	},
// 	{
// 		id: 11,
// 		label: 'Fancy Pink',
// 		importKey: 'Fancy Pink'
// 	},
// 	{
// 		id: 12,
// 		label: 'Fancy Yellow',
// 		importKey: 'Fancy Yellow'
// 	},
// 	{
// 		id: 13,
// 		label: 'Synthetic Yellow',
// 		importKey: 'Synthetic Yellow'
// 	},
// 	{
// 		id: 14,
// 		label: 'Treated Color Intense Blue',
// 		importKey: 'Treated Color Intense Blue'
// 	},
// 	{
// 		id: 15,
// 		label: 'Treated Color Intense Pink',
// 		importKey: 'Treated Color Intense Pink'
// 	},
// 	{
// 		id: 16,
// 		label: 'Treated Color Intense Yellow',
// 		importKey: 'Treated Color Intense Yellow'
// 	}
// ];
