// Parses settings between the client and server.
// Use this in isomorphic logic

// import path from 'path';
// import appHome from '../../app-home.js';

import { isServer } from 'shared/utilities';

export default (key) => {
	if( isServer() ) {
		var value = global.nconf.get(key);

		console.log("[Settings][INFO] Get:", key, value)

		return value
	} else {
		return window.SETTINGS ? window.SETTINGS[key] : null;
	}
}