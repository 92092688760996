import React from 'react';
import _ from 'underscore';
import { isServer, isApiRequest, getRequestingUser, apiRequest,savePricingLogs } from 'shared/utilities';

//import Sequelize from 'sequelize';

class Diamond extends React.Component {
    apiRequest( method, data ) {
        return apiRequest( 'Diamond', method, data );
    }

    getPrice() {
        const isThisApiRequest = isApiRequest( arguments );
        const requestingUser = getRequestingUser( arguments );
        const diamond = arguments[ 0 ];

        Diamond.priceInfluencers.some( priceInfluencer => {
            if( _.isUndefined( arguments[ 0 ][ priceInfluencer ] ) ) throw `${priceInfluencer} must be defined`
        } );

        Diamond.priceInfluencers.forEach( priceInfluencer => {
            // Sanitize inputs
            diamond[ priceInfluencer ] = Number( diamond[ priceInfluencer ] )
        } );

        let { weight, clarity, color, shape, cutQuality, fluorescence, isFetchingPrice, price, priceError } = diamond;

        weight = Number.parseFloat(weight)

        if (!weight) { throw 'weight must be provided' }

        weight = weight.toFixed(2)

        if( isServer() ) {
            return new Promise( ( resolve, reject ) => {
                const doGetPrice = () => {
                    let query = 'SELECT * FROM "Prices" where "Prices".diamond_clarity = ? AND diamond_color = ? AND diamond_shape = ? AND numrange("Prices"."min_weight"::numeric, "Prices"."max_weight"::numeric) @> ?::numeric ORDER BY "Prices"."price" ASC LIMIT 1';

                    global.DB.sequelize.query(query,
                        {
                            type: global.DB.Sequelize.QueryTypes.SELECT,
                            replacements: [clarity, color, shape, weight],
                            model: global.DB['Price'],
                            mapToModel: true
                        }
                    ).then(prices => {
                        let willGetPriceForTransformations;

                        if (prices.length === 0) {
                            const mColor = _.findWhere( Diamond.colors, { label: 'M' } );
                            const diamondColor = _.findWhere( Diamond.colors, { id: color } );

                            // If there was no price found and the color has a extrapolation factor
                            if( diamondColor.extrapolationFactor ) {
                                // Extrapolate the price from the diamond's value if it were color M
                                willGetPriceForTransformations = new Promise( resolve =>
                                    global.DB.sequelize.query(query,
                                        {
                                            type: global.DB.Sequelize.QueryTypes.SELECT,
                                            replacements: [clarity, mColor.id, shape, weight],
                                            model: global.DB['Price'],
                                            mapToModel: true
                                        }
                                    ).then(prices => {
                                        console.log('2', prices)

                                        if (prices.length === 0) {
                                            resolve( null )
                                        } else {
                                            resolve( prices[0].price * diamondColor.extrapolationFactor )
                                        }
                                    })
                                );
                            }
                            // If there was no price found and the color is between D and M
                            else {
                                return resolve( null );
                            }
                        } else {
                            willGetPriceForTransformations = Promise.resolve( prices[0].price )
                        }

                        // Price from the database is the price per carat
                        return willGetPriceForTransformations.then( price => {

                            // Apply the weight adjustment (if it is defined)
                            const shapeInfo = _.findWhere( Diamond.shapes, { id: shape } );

                            if( shapeInfo && shapeInfo.weightAdjustments ) {
                                const weightAdjustment = _.find( shapeInfo.weightAdjustments, weightAdjustment =>
                                    weightAdjustment.minWeight <= weight &&
                                    weightAdjustment.maxWeight >= weight
                                );

                                if( weightAdjustment ) price = price * weightAdjustment.factor;
                            }

                            // Apply all of the static transformations (deductions)
                            [
                                {
                                    staticProperty: 'cutQualities',
                                    property: 'cutQuality'
                                },
                                {

                                    staticProperty: 'fluorescences',
                                    property: 'fluorescence'
                                }
                            ].forEach( ( { staticProperty, property } ) => {
                                const transform = _.findWhere( Diamond[ staticProperty ], { id: diamond[ property ] } ).transform || ( value => value );

                                price = transform( price, diamond );
                            } );

                            // Calculate the total gem price
                            price = price * weight;

                            // Send mixpanel event
                            global.trackRequest('Pricing Request', arguments[1], requestingUser, {
                                gem: 'diamond',
                                cut: Diamond.shapes.find(item => item.id === diamond.shape).label,
                                weight: weight,
                                color: Diamond.colors.find(item => item.id === diamond.color).label,
                                clarity: Diamond.clarities.find(item => item.id === diamond.clarity).label,
                                fluorescences: Diamond.fluorescences.find(item => item.id === diamond.fluorescence).label,
                                arguments: {
                                    color,
                                    clarity,
                                    cutQuality,
                                    isFetchingPrice,
                                    price: 0,
                                    shape,
                                    weight
                                }
                            });

                            return resolve( price );
                        } );
                    } ).catch( error =>
                        reject( { status: 500, error } )
                    )
                };

                if( isThisApiRequest ) {
                    if( requestingUser ) {
                        doGetPrice();
                    } else {
                        reject( {
                            status: 403,
                            error: new Error( `Requester must be identified` )
                        } );
                    }
                } else {
                    doGetPrice();
                }
            } )
        } else {
            return this.apiRequest( 'getPrice', arguments[ 0 ] );
        }
    }

    getPrices() {
        const isThisApiRequest = isApiRequest( arguments );
        const requestingUser = getRequestingUser( arguments );
        const diamond = arguments[ 0 ];

        let { shape } = diamond;

        if( isServer() ) {
            return new Promise( ( resolve, reject ) => {
                const doGetPrices = () => {
                    global.DB.Price.findAll( {
                        attributes: [
                            'id',
                            'price',
                            'min_weight',
                            'max_weight',
                            'diamond_clarity',
                            'diamond_color',
                            'diamond_shape',
                            'created_at',
                            'updated_at'
                        ],
                        where: {
                            diamond_shape: shape
                        }
                    } ).then( prices => {
                        if( ! prices ){
							savePricingLogs(arguments[1].user, null, arguments[1].url, 200 , arguments[0])
							return resolve( null );
						} 
                        return resolve( prices );
                    } ).catch( error =>
                        reject( { status: 500, error } )
                    )
                };

                if( isThisApiRequest ) {
                    if( requestingUser ) {
                        doGetPrices();
                    } else {
                        reject( {
                            status: 403,
                            error: new Error( `Requester must be identified` )
                        } );
                    }
                } else {
                    doGetPrices();
                }
            } )
        } else {
            return this.apiRequest( 'getPrices', arguments[ 0 ] );
        }
    }

    buildDiamondPrices() {
        if( isServer() ) {
            return new Promise( ( resolve, reject ) => {
                let sizes  = {},
                    ranges = [];

                let diamond_colors = {};
                _.each( Diamond.colors, color => {
                    diamond_colors[ String( color.id ) ] = color.label;
                } );

                const data = arguments[ 0 ];

                _.each( data, price => {
                    let minWeightString   = price.min_weight >= 10 ? price.min_weight.toFixed( 2 ) : price.min_weight.toFixed( 3 ),
                        maxWeightString   = price.max_weight >= 10 ? price.max_weight.toFixed( 2 ) : price.max_weight.toFixed( 3 ),
                        weightRangeString = minWeightString + ' - ' + maxWeightString;

                    if( ranges.indexOf( weightRangeString ) === -1 ) ranges.push( weightRangeString );

                    if( _.has( sizes, weightRangeString ) )
                        if( _.has( sizes[ weightRangeString ], diamond_colors[ String( price.diamond_color ) ] ) )
                            sizes[ weightRangeString ][ diamond_colors[ String( price.diamond_color ) ] ][ String( price.diamond_clarity ) ] = price.price || '-';
                        else {
                            sizes[ weightRangeString ][ diamond_colors[ String( price.diamond_color ) ] ] = {};
                            sizes[ weightRangeString ][ diamond_colors[ String( price.diamond_color ) ] ][ String( price.diamond_clarity ) ] = price.price || '-';
                        }
                    else {
                        sizes[ weightRangeString ] = {};
                        sizes[ weightRangeString ][ diamond_colors[ String( price.diamond_color ) ] ] = {};
                        sizes[ weightRangeString ][ diamond_colors[ String( price.diamond_color ) ] ][ String( price.diamond_clarity ) ] = price.price || '-';
                    }
                } );

                _.each( sizes, size => {
                    _.each( diamond_colors, color => {
                        if( size[ color ] && Object.keys( size[ color ] ).length < 10 )
                            for( let i = 0; i < 10; i++ ) {
                                if( !size[ color ][ i ] )
                                    size[ color ][ i ] = 0;
                            }
                    } );
                } );

                let sortedData = {};
                let keys = Object.keys( sizes ).sort( ( a, b ) => {
                    return parseFloat( a ) - parseFloat( b );
                } );

                _.each( keys, key => {
                    let innerSortedData = {};
                    _.each( Object.keys( sizes[ key ] ).sort(), innerKey => {
                        innerSortedData[ innerKey ] = sizes[ key ][ innerKey ];
                    } );
                    sortedData[ key ] = innerSortedData;
                } );

                sizes = sortedData;

                ranges = ranges.sort( ( a, b ) => {
                    return parseFloat( a ) - parseFloat( b );
                } );

                resolve( sizes );
            } );
        } else {
            return this.apiRequest( 'buildDiamondPrices', arguments[ 0 ] );
        }
    }

    getPriceAndAdjacentPrices() { //, color, clarity
        if( isServer() ) {
            const diamond = arguments[ 0 ];
            let { shape } = diamond;
            return new Promise( ( resolve, reject ) => {
                this.getPrices( { shape } ).then( data => {
                    let prices = [];
                    let output = true;
                    _.each( data[ 0 ], price => {
                        if( output ) {
                            output = false;
                        }
                        prices.push( price );
                    } );
                    this.buildDiamondPrices( { data: prices } ).then( prices => {
                        resolve( prices );
                    } );
                } );
            } );
        } else {
            return this.apiRequest( 'getPriceAndAdjacentPrices', arguments[ 0 ] );
        }
    }

    getAdjacentPrices() {
        const isThisApiRequest = isApiRequest( arguments );
        const requestingUser = getRequestingUser( arguments );
        const diamond = arguments[ 0 ];

        let { shape, weight, color, clarity } = diamond;

        if( isServer() ) {
            return new Promise( ( resolve, reject ) => {

                if( [ 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M' ].indexOf( color ) === -1 ) {
                    resolve( 'invalid_color' );
                }

                if( [ 'IF/FL', 'VVS1', 'VVS2', 'VS1', 'VS2', 'SI1', 'SI2', 'I1', 'I2', 'I3' ].indexOf( clarity ) === -1 ) {
                    resolve( 'invalid_clarity' );
                }

                const doGetPrices = () => {
                    global.DB.Price.findAll( {
                        raw: true,
                        attributes: [
                            'id',
                            'price',
                            'min_weight',
                            'max_weight',
                            'diamond_clarity',
                            'diamond_color',
                            'diamond_shape',
                            'created_at',
                            'updated_at'
                        ],
                        where: {
                            diamond_shape: shape
                        }
                    } ).then( prices => {
                        if( ! prices ){
							savePricingLogs(arguments[1].user, null, arguments[1].url, 200 , arguments[0])
							return resolve( null );
						} 

                        let sizes  = {},
                            ranges = [];

                        let diamond_clarities = {};
                        _.each( Diamond.clarities, clarity => {
                            diamond_clarities[ clarity.label ] = clarity.id;
                        } );

                        let diamond_colors = {};
                        _.each( Diamond.colors, color => {
                            diamond_colors[ String( color.id ) ] = color.label;
                        } );

                        _.each( prices, price => {
                            let minWeightString   = price.min_weight >= 10 ? price.min_weight.toFixed( 2 ) : price.min_weight.toFixed( 3 ),
                                maxWeightString   = price.max_weight >= 10 ? price.max_weight.toFixed( 2 ) : price.max_weight.toFixed( 3 ),
                                weightRangeString = minWeightString + ' - ' + maxWeightString;

                            if( ranges.indexOf( weightRangeString ) === -1 ) ranges.push( weightRangeString );

                            if( _.has( sizes, weightRangeString ) )
                                if( _.has( sizes[ weightRangeString ], diamond_colors[ String( price.diamond_color ) ] ) )
                                    sizes[ weightRangeString ][ diamond_colors[ String( price.diamond_color ) ] ][ String( price.diamond_clarity ) ] = price.price || '-';
                                else {
                                    sizes[ weightRangeString ][ diamond_colors[ String( price.diamond_color ) ] ] = {};
                                    sizes[ weightRangeString ][ diamond_colors[ String( price.diamond_color ) ] ][ String( price.diamond_clarity ) ] = price.price || '-';
                                }
                            else {
                                sizes[ weightRangeString ] = {};
                                sizes[ weightRangeString ][ diamond_colors[ String( price.diamond_color ) ] ] = {};
                                sizes[ weightRangeString ][ diamond_colors[ String( price.diamond_color ) ] ][ String( price.diamond_clarity ) ] = price.price || '-';
                            }
                        } );

                        _.each( sizes, size => {
                            _.each( diamond_colors, color => {
                                if( size[ color ] && Object.keys( size[ color ] ).length < 10 )
                                    for( let i = 0; i < 10; i++ ) {
                                        if( !size[ color ][ i ] )
                                            size[ color ][ i ] = 0;
                                    }
                            } );
                        } );

                        let sortedData = {};
                        let keys = Object.keys( sizes ).sort( ( a, b ) => {
                            return parseFloat( a ) - parseFloat( b );
                        } );

                        _.each( keys, key => {
                            let innerSortedData = {};
                            _.each( Object.keys( sizes[ key ] ).sort(), innerKey => {
                                innerSortedData[ innerKey ] = sizes[ key ][ innerKey ];
                            } );
                            sortedData[ key ] = innerSortedData;
                        } );

                        sizes = sortedData;

                        let lowestValue  = 99999999999,
                            highestValue = 0;

                        _.each( sizes, ( values, key ) => {
                            let parts = key.split( '-' );
                            if( parseFloat( parts[ 0 ] ) < lowestValue ) {
                                lowestValue = parseFloat( parts[ 0 ] );
                            }
                            if( parseFloat( parts[ 1 ] ) > highestValue ) {
                                highestValue = parseFloat( parts[ 1 ] );
                            }
                        } );

                        if( weight > highestValue || weight < lowestValue ) {
                            resolve( {
                                code: 'invalid_weight',
                                values: [ lowestValue, highestValue ]
                            } );
                        }

                        ranges = ranges.sort( ( a, b ) => {
                            return parseFloat( a ) - parseFloat( b );
                        } );

                        let activeRange = '';

                        _.each( sizes, ( prices, range ) => {
                            if( parseFloat( range ) <= parseFloat( weight ) ) {
                                activeRange = range;
                            }
                        } );

                        const activeTable = sizes[ activeRange ];

                        let clarityKey = [];

                        _.each( diamond_clarities, ( index, clarity ) => {
                            clarityKey.push( clarity );
                        } );

                        // let rowZero = [],
                        //     rowOne  = [],
                        //     rowTwo  = [];

                        let prevColor = '',
                            nextColor = '';

                        let prevClarity = '',
                            nextClarity = '';

                        //const centerValue = activeTable[ color ][ diamond_clarities[ clarity ] ];

                        for( let i = 0; i < 10; i++ ) {
                            if( diamond_colors[ i ] === color ) {
                                if( i === 0 ) {
                                    prevColor = false;
                                    nextColor = diamond_colors[ i + 1 ];
                                } else if( ( i === ( diamond_colors.length - 1 ) || diamond_colors[ i ] === 'M' ) ) {
                                    prevColor = diamond_colors[ i - 1 ];
                                    nextColor = false;
                                } else {
                                    prevColor = diamond_colors[ i - 1 ];
                                    nextColor = diamond_colors[ i + 1 ];
                                }
                            }
                        }

                        for( let i = 0; i < clarityKey.length; i++ ) {
                            if( clarity === clarityKey[ i ] ) {
                                if( i === 0 ) {
                                    prevClarity = false;
                                    nextClarity = clarityKey[ i + 1 ];
                                } else if( i === ( clarityKey.length - 1 ) ) {
                                    prevClarity = clarityKey[ i - 1 ];
                                    nextClarity = false;
                                } else {
                                    prevClarity = clarityKey[ i - 1 ];
                                    nextClarity = clarityKey[ i + 1 ];
                                }
                            }
                        }

                        // let rowZero = [],
                        //     rowOne = [],
                        //     rowTwo = [];

                        let rowZero = [
                            prevColor ? activeTable[ prevColor ][ diamond_clarities[ prevClarity ] ] || '-' : '-',
                            prevColor ? activeTable[ prevColor ][ diamond_clarities[ clarity ] ] || '-' : '-',
                            prevColor ? activeTable[ prevColor ][ diamond_clarities[ nextClarity ] ] || '-' : '-'
                        ];

                        let rowOne = [
                            activeTable[ color ][ diamond_clarities[ prevClarity ] ] || '-',
                            activeTable[ color ][ diamond_clarities[ clarity ] ] || '-',
                            activeTable[ color ][ diamond_clarities[ nextClarity ] ] || '-'
                        ];

                        let rowTwo = [
                            nextColor ? activeTable[ nextColor ][ diamond_clarities[ prevClarity ] ] || '-' : '-',
                            nextColor ? activeTable[ nextColor ][ diamond_clarities[ clarity ] ] || '-' : '-',
                            nextColor ? activeTable[ nextColor ][ diamond_clarities[ nextClarity ] ] || '-' : '-'
                        ];

                        let rows = [ rowZero, rowOne, rowTwo ];

                        // _.each( rows, ( row, rowIndex ) => {
                        //     _.each( row, ( column, columnIndex ) => {
                        //         if( !column ) {
                        //             rows[rowIndex][columnIndex] = '-';
                        //         }
                        //     } );
                        // } );

                        // if( color === 'D' || color === 'M' ) {
                        //     if( color === 'D' ) {
                        //         console.log( 'color = D' );
                        //
                        //         rowZero = [ '-', '-', '-' ];
                        //
                        //         let rowOneZero = '-',
                        //             rowOneTwo  = '-';
                        //
                        //         console.log( 'prevClarity = ', prevClarity );
                        //         console.log( 'nextClarity = ', nextClarity );
                        //
                        //         if( prevClarity ) {
                        //             rowOneZero = activeTable[ color ][ diamond_clarities[ prevClarity ] ];
                        //         }
                        //
                        //         if( nextClarity ) {
                        //             rowOneTwo = activeTable[ color ][ diamond_clarities[ nextClarity ] ];
                        //         }
                        //
                        //         rowOne = [ rowOneZero, centerValue, rowOneTwo ];
                        //
                        //         let rowTwoZero = '-',
                        //             rowTwoOne  = activeTable[ nextColor ][ diamond_clarities[ clarity ] ],
                        //             rowTwoTwo  = activeTable[ nextColor ][ diamond_clarities[ nextClarity ] ];
                        //
                        //         rowTwo = [ rowTwoZero, rowTwoOne, rowTwoTwo ];
                        //     } else {
                        //         console.log( 'color = M' );
                        //         rowTwo = [ '-', '-', '-' ];
                        //
                        //         let rowOneZero = '-',
                        //             rowOneTwo  = '-';
                        //
                        //         if( prevClarity ) {
                        //             rowOneZero = activeTable[ color ][ diamond_clarities[ prevClarity ] ];
                        //         }
                        //
                        //         if( nextClarity ) {
                        //             rowOneTwo = activeTable[ color ][ diamond_clarities[ nextClarity ] ];
                        //         }
                        //
                        //         rowOne = [ rowOneZero, centerValue, rowOneTwo ];
                        //     }
                        // } else {
                        //     let rowZeroZero = '-',
                        //         rowZeroOne  = '-',
                        //         rowZeroTwo  = '-';
                        //
                        //     let rowOneZero = '-',
                        //         rowOneOne  = centerValue,
                        //         rowOneTwo  = '-';
                        //
                        //     let rowTwoZero = '-',
                        //         rowTwoOne  = '-',
                        //         rowTwoTwo  = '-';
                        //
                        //     if( prevClarity ) {
                        //         rowZeroZero = activeTable[ prevColor ][ diamond_clarities[ prevClarity ] ];
                        //         rowOneZero = activeTable[ color ][ diamond_clarities[ prevClarity ] ];
                        //         rowTwoZero = activeTable[ nextColor ][ diamond_clarities[ prevClarity ] ];
                        //     }
                        //
                        //     if( nextClarity ) {
                        //         rowZeroTwo = activeTable[ prevColor ][ diamond_clarities[ nextClarity ] ];
                        //         rowOneTwo = activeTable[ color ][ diamond_clarities[ nextClarity ] ];
                        //         rowTwoTwo = activeTable[ nextColor ][ diamond_clarities[ nextClarity ] ];
                        //     }
                        //
                        //     rowZeroOne = activeTable[ prevColor ][ diamond_clarities[ clarity ] ];
                        //     rowTwoOne = activeTable[ nextColor ][ diamond_clarities[ clarity ] ];
                        //
                        //     rowZero = [ rowZeroZero, rowZeroOne, rowZeroTwo ];
                        //     rowOne = [ rowOneZero, rowOneOne, rowOneTwo ];
                        //     rowTwo = [ rowTwoZero, rowTwoOne, rowTwoTwo ];
                        // }

                        resolve( rows );
                    } ).catch( error => {
                        console.log( 'error', error );
                        reject( { status: 500, error } )
                    } )
                };

                if( isThisApiRequest ) {
                    if( requestingUser ) {
                        doGetPrices();
                    } else {
                        reject( {
                            status: 403,
                            error: new Error( `Requester must be identified` )
                        } );
                    }
                } else {
                    doGetPrices();
                }
            } )
        } else {
            return this.apiRequest( 'getAdjacentPrices', arguments[ 0 ] );
        }
    }

    getDiamondNotes( id ) {
        if( isServer() ) {
            const { id } = arguments[ 0 ];

            if( !id && id !== 0 ) throw `id property must be provided`;

            return new Promise( ( resolve, reject ) => {
                global.DB.Meta.findOne( {
                    where: {
                        key: 'info',
                        diamond_shape: id
                    }
                } ).then( info => {
                    if( info ) {
                        let json = JSON.parse( info.value );
                        resolve( json.notes ? json.notes : false );
                    }
                    else {
                        resolve( false );
                    }
                } );
            } );
        }
        else {
            return this.apiRequest( 'getDiamondNotes', id );
        }
    }

    static getIdByName() {
        const { name } = arguments[ 0 ];
        //let { shape, size, color, clarity } = diamond;

        if( isServer() ) {
            return new Promise( ( resolve, reject ) => {
                _.each( Diamond.shapes, shape => {
                    if( shape.label === name ) {
                        resolve( shape.id );
                    }
                } );
                resolve( false );
            } )
        } else {
            return this.apiRequest( 'getIdByName', { name } );
        }
    }
}

Diamond.shapes = [
    {
        label: 'Natural',
        id: 20,
        isTitle:true
    },
    {
        label: 'Baguette',
        tableLabel: 'Baguette <span style="text-transform:lowercase">(mm)</span>',
        id: 0,
        importKey: 'Baguette Cut'
    },
    {
        label: 'Cushion',
        id: 1,
        importKey: 'Cushion Cut',
        weightAdjustments: [
            { minWeight: 0.6, maxWeight: 0.69, factor: 1.05 },
            { minWeight: 0.85, maxWeight: 0.89, factor: 1.06 },
            { minWeight: 0.95, maxWeight: 0.99, factor: 1.07 },
            { minWeight: 1.2, maxWeight: 1.49, factor: 1.1 },
            { minWeight: 1.7, maxWeight: 1.99, factor: 1.1 },
            { minWeight: 2.5, maxWeight: 2.99, factor: 1.1 },
            { minWeight: 3.5, maxWeight: 3.99, factor: 1.1 },
            { minWeight: 4.5, maxWeight: 4.99, factor: 1.1 },
            { minWeight: 5.5, maxWeight: 5.99, factor: 1.1 },
            { minWeight: 6, maxWeight: 6.99, factor: 1.15 },
            { minWeight: 7, maxWeight: 7.99, factor: 1.25 },
            { minWeight: 8, maxWeight: 8.99, factor: 1.3 },
            { minWeight: 9, maxWeight: 9.99, factor: 1.35 },
            { minWeight: 10, maxWeight: 14.99, factor: 1.5 },
            ]
    },
    {
        label: 'Emerald',
        id: 2,
        importKey: 'Emerald Shaped Cut',
        weightAdjustments: [
            { minWeight: 0.6, maxWeight: 0.69, factor: 1.05 },
            { minWeight: 0.85, maxWeight: 0.89, factor: 1.06 },
            { minWeight: 0.95, maxWeight: 0.99, factor: 1.07 },
            { minWeight: 1.2, maxWeight: 1.49, factor: 1.1 },
            { minWeight: 1.7, maxWeight: 1.99, factor: 1.1 },
            { minWeight: 2.5, maxWeight: 2.99, factor: 1.1 },
            { minWeight: 3.5, maxWeight: 3.99, factor: 1.1 },
            { minWeight: 4.5, maxWeight: 4.99, factor: 1.1 },
            { minWeight: 5.5, maxWeight: 5.99, factor: 1.1 },
            { minWeight: 6, maxWeight: 6.99, factor: 1.15 },
            { minWeight: 7, maxWeight: 7.99, factor: 1.25 },
            { minWeight: 8, maxWeight: 8.99, factor: 1.3 },
            { minWeight: 9, maxWeight: 9.99, factor: 1.35 },
            { minWeight: 10, maxWeight: 14.99, factor: 1.5 },
            ]
    },
    {
        label: 'Heart',
        id: 3,
        importKey: 'Heart Shaped Cut',
        weightAdjustments: [
            { minWeight: 0.6, maxWeight: 0.69, factor: 1.05 },
            { minWeight: 0.85, maxWeight: 0.89, factor: 1.06 },
            { minWeight: 0.95, maxWeight: 0.99, factor: 1.07 },
            { minWeight: 1.2, maxWeight: 1.49, factor: 1.1 },
            { minWeight: 1.7, maxWeight: 1.99, factor: 1.1 },
            { minWeight: 2.5, maxWeight: 2.99, factor: 1.1 },
            { minWeight: 3.5, maxWeight: 3.99, factor: 1.1 },
            { minWeight: 4.5, maxWeight: 4.99, factor: 1.1 },
            { minWeight: 5.5, maxWeight: 5.99, factor: 1.1 },
            { minWeight: 6, maxWeight: 6.99, factor: 1.15 },
            { minWeight: 7, maxWeight: 7.99, factor: 1.25 },
            { minWeight: 8, maxWeight: 8.99, factor: 1.3 },
            { minWeight: 9, maxWeight: 9.99, factor: 1.35 },
            { minWeight: 10, maxWeight: 14.99, factor: 1.5 },
            ]
    },
    {
        label: 'Ideal',
        id: 4,
        importKey: 'Ideal Cut',
        weightAdjustments: [
            {
                minWeight: 0.6,
                maxWeight: 0.69,
                factor: 1.1
            },
            {
                minWeight: 0.85,
                maxWeight: 0.89,
                factor: 1.1
            },
            {
                minWeight: 0.95,
                maxWeight: 0.99,
                factor: 1.2
            },
            {
                minWeight: 1.2,
                maxWeight: 1.49,
                factor: 1.12
            },
            {
                minWeight: 1.7,
                maxWeight: 1.99,
                factor: 1.2
            },
            {
                minWeight: 2.5,
                maxWeight: 2.99,
                factor: 1.12
            },
            {
                minWeight: 3.5,
                maxWeight: 3.99,
                factor: 1.12
            },
            {
                minWeight: 4.5,
                maxWeight: 4.99,
                factor: 1.12
            },
            {
                minWeight: 5.5,
                maxWeight: 5.99,
                factor: 1.12
            },
            {
                minWeight: 6,
                maxWeight: 6.99,
                factor: 1.15
            },
            {
                minWeight: 7,
                maxWeight: 7.99,
                factor: 1.25
            },
            {
                minWeight: 8,
                maxWeight: 8.99,
                factor: 1.3
            },
            {
                minWeight: 9,
                maxWeight: 9.99,
                factor: 1.35
            },
            {
                minWeight: 10,
                maxWeight: 14.99,
                factor: 1.5
            }
        ]
    },
    {
        label: 'Marquise',
        id: 5,
        importKey: 'Marquise Shaped Cut',
        weightAdjustments: [
            { minWeight: 0.6, maxWeight: 0.69, factor: 1.05 },
            { minWeight: 0.85, maxWeight: 0.89, factor: 1.06 },
            { minWeight: 0.95, maxWeight: 0.99, factor: 1.07 },
            { minWeight: 1.2, maxWeight: 1.49, factor: 1.1 },
            { minWeight: 1.7, maxWeight: 1.99, factor: 1.1 },
            { minWeight: 2.5, maxWeight: 2.99, factor: 1.1 },
            { minWeight: 3.5, maxWeight: 3.99, factor: 1.1 },
            { minWeight: 4.5, maxWeight: 4.99, factor: 1.1 },
            { minWeight: 5.5, maxWeight: 5.99, factor: 1.1 },
            { minWeight: 6, maxWeight: 6.99, factor: 1.15 },
            { minWeight: 7, maxWeight: 7.99, factor: 1.25 },
            { minWeight: 8, maxWeight: 8.99, factor: 1.3 },
            { minWeight: 9, maxWeight: 9.99, factor: 1.35 },
            { minWeight: 10, maxWeight: 14.99, factor: 1.5 },
            ]
    },
    {
        label: 'Melee',
        id: 6,
        importKey: 'Single Cut'
    },
    {
        label: 'Old European',
        id: 7,
        importKey: 'Old European Cut'
    },
    {
        label: 'Oval',
        id: 8,
        importKey: 'Oval Shaped Cut',
        weightAdjustments: [
            { minWeight: 0.6, maxWeight: 0.69, factor: 1.05 },
            { minWeight: 0.85, maxWeight: 0.89, factor: 1.06 },
            { minWeight: 0.95, maxWeight: 0.99, factor: 1.07 },
            { minWeight: 1.2, maxWeight: 1.49, factor: 1.1 },
            { minWeight: 1.7, maxWeight: 1.99, factor: 1.1 },
            { minWeight: 2.5, maxWeight: 2.99, factor: 1.1 },
            { minWeight: 3.5, maxWeight: 3.99, factor: 1.1 },
            { minWeight: 4.5, maxWeight: 4.99, factor: 1.1 },
            { minWeight: 5.5, maxWeight: 5.99, factor: 1.1 },
            { minWeight: 6, maxWeight: 6.99, factor: 1.15 },
            { minWeight: 7, maxWeight: 7.99, factor: 1.25 },
            { minWeight: 8, maxWeight: 8.99, factor: 1.3 },
            { minWeight: 9, maxWeight: 9.99, factor: 1.35 },
            { minWeight: 10, maxWeight: 14.99, factor: 1.5 },
            ]
    },
    {
        label: 'Pear',
        id: 9,
        importKey: 'Pear Shaped Cut',
        weightAdjustments: [
            {
                minWeight: 0.85,
                maxWeight: 0.89,
                factor: 1.05
            },
            {
                minWeight: 0.95,
                maxWeight: 0.99,
                factor: 1.1
            },
            {
                minWeight: 1.2,
                maxWeight: 1.49,
                factor: 1.05
            },
            {
                minWeight: 1.7,
                maxWeight: 1.99,
                factor: 1.2
            },
            {
                minWeight: 2.5,
                maxWeight: 2.99,
                factor: 1.2
            },
            {
                minWeight: 3.5,
                maxWeight: 3.99,
                factor: 1.06
            },
            {
                minWeight: 4.5,
                maxWeight: 4.99,
                factor: 1.1
            },
            {
                minWeight: 5.5,
                maxWeight: 5.99,
                factor: 1.03
            },
            {
                minWeight: 6,
                maxWeight: 6.99,
                factor: 1.2
            },
            {
                minWeight: 7,
                maxWeight: 7.99,
                factor: 1.25
            },
            {
                minWeight: 8,
                maxWeight: 8.99,
                factor: 1.3
            },
            {
                minWeight: 9,
                maxWeight: 9.99,
                factor: 1.35
            },
            {
                minWeight: 10,
                maxWeight: 14.99,
                factor: 1.45
            }
        ]
    },
    {
        label: 'Princess',
        id: 10,
        importKey: 'Princess Cut',
        weightAdjustments: [
            { minWeight: 0.6, maxWeight: 0.69, factor: 1.05 },
            { minWeight: 0.85, maxWeight: 0.89, factor: 1.06 },
            { minWeight: 0.95, maxWeight: 0.99, factor: 1.07 },
            { minWeight: 1.2, maxWeight: 1.49, factor: 1.1 },
            { minWeight: 1.7, maxWeight: 1.99, factor: 1.1 },
            { minWeight: 2.5, maxWeight: 2.99, factor: 1.1 },
            { minWeight: 3.5, maxWeight: 3.99, factor: 1.1 },
            { minWeight: 4.5, maxWeight: 4.99, factor: 1.1 },
            { minWeight: 5.5, maxWeight: 5.99, factor: 1.1 },
            { minWeight: 6, maxWeight: 6.99, factor: 1.15 },
            { minWeight: 7, maxWeight: 7.99, factor: 1.25 },
            { minWeight: 8, maxWeight: 8.99, factor: 1.3 },
            { minWeight: 9, maxWeight: 9.99, factor: 1.35 },
            { minWeight: 10, maxWeight: 14.99, factor: 1.5 },
            ]
    },
    {
        label: 'Radiant',
        id: 11,
        importKey: 'Radiant Shaped Cut',
        weightAdjustments: [
            { minWeight: 0.6, maxWeight: 0.69, factor: 1.05 },
            { minWeight: 0.85, maxWeight: 0.89, factor: 1.06 },
            { minWeight: 0.95, maxWeight: 0.99, factor: 1.07 },
            { minWeight: 1.2, maxWeight: 1.49, factor: 1.1 },
            { minWeight: 1.7, maxWeight: 1.99, factor: 1.1 },
            { minWeight: 2.5, maxWeight: 2.99, factor: 1.1 },
            { minWeight: 3.5, maxWeight: 3.99, factor: 1.1 },
            { minWeight: 4.5, maxWeight: 4.99, factor: 1.1 },
            { minWeight: 5.5, maxWeight: 5.99, factor: 1.1 },
            { minWeight: 6, maxWeight: 6.99, factor: 1.15 },
            { minWeight: 7, maxWeight: 7.99, factor: 1.25 },
            { minWeight: 8, maxWeight: 8.99, factor: 1.3 },
            { minWeight: 9, maxWeight: 9.99, factor: 1.35 },
            { minWeight: 10, maxWeight: 14.99, factor: 1.5 },
            ]
    },
    {
        label: 'Round',
        id: 12,
        importKey: 'Round Brilliant Cut',
        default: true,
        weightAdjustments: [
            { minWeight: 0.6, maxWeight: 0.69, factor: 1.05 },
            { minWeight: 0.85, maxWeight: 0.89, factor: 1.1 },
            { minWeight: 0.95, maxWeight: 0.99, factor: 1.1 },
            { minWeight: 1.2, maxWeight: 1.49, factor: 1.1 },
            { minWeight: 1.7, maxWeight: 1.99, factor: 1.1 },
            { minWeight: 2.5, maxWeight: 2.99, factor: 1.1 },
            { minWeight: 3.5, maxWeight: 3.99, factor: 1.06 },
            { minWeight: 4.5, maxWeight: 4.99, factor: 1.05 },
            { minWeight: 5.5, maxWeight: 5.99, factor: 1.04 },
            { minWeight: 6, maxWeight: 6.99, factor: 1.15 },
            { minWeight: 7, maxWeight: 7.99, factor: 1.25 },
            { minWeight: 8, maxWeight: 8.99, factor: 1.3 },
            { minWeight: 9, maxWeight: 9.99, factor: 1.35 },
            { minWeight: 10, maxWeight: 14.99, factor: 1.5 },
            ]
    },
    {
        label: 'Triangle',
        id: 13,
        importKey: 'Triangle Brilliant Cut'
    },
    {
        label: 'Lab Grown',
        id:21,
        isTitle:true
    },
    {
        label: 'Lab Grown Round',
        id: 14,
        importKey: 'Lab-Grown Round'
    },
];



Diamond.colors = Array.prototype.map.call(
    'defghijklmnopqrstuvwxyz',
    ( letter, id ) => {
        let color = { label: letter.toUpperCase(), id };

        // The extrapolation factor is what the price per carat if the diamond
        // had the color value M is multiplied by because usually prices are
        // not specifically defined below that color quality
        if( letter.match( /n|o|p|q|r/ ) ) color.extrapolationFactor = 0.9;
        if( letter.match( /s|t|u|v|w|x|y|z/ ) ) color.extrapolationFactor = 0.85;

        return color;
    }
);

Diamond.clarities = [
    {
        label: 'IF/FL',
        id: 0
    },
    {
        label: 'VVS1',
        id: 1
    },
    {
        label: 'VVS2',
        id: 2
    },
    {
        label: 'VS1',
        id: 3
    },
    {
        label: 'VS2',
        id: 4
    },
    {
        label: 'SI1',
        id: 5
    },
    {
        label: 'SI2',
        id: 6
    },
    {
        label: 'I1',
        id: 7
    },
    {
        label: 'I2',
        id: 8
    },
    {
        label: 'I3',
        id: 9
    }
];

Diamond.cutQualities = [
    {
        label: 'Ideal',
        id: 5,
        transform: value => value * 1.06
    },
    {
        label: 'Excellent',
        id: 4,
        transform: value => value, // no effect
        default: true
    },
    {
        label: 'Very Good',
        id: 3,
        transform: value => value * 0.945
    },
    {
        label: 'Good',
        id: 2,
        transform: value => value * 0.855
    },
    {
        label: 'Fair',
        id: 1,
        transform: value => value * 0.8
    },
    {
        label: 'Poor',
        id: 0,
        transform: value => value * 0.675
    }
];

// Percentages are given as presented in the gemguide tables
Diamond.getAverageFluorescenceTransform = ( ...args ) => (
    1 + args.reduce( ( percentageTotal, percentage ) => Number( percentageTotal ) + percentage ) / args.length / 100
)

Diamond.fluorescences = [
    {
        label: 'Very Strong',
        id: 0,
        transform: ( value, diamond ) => {
            switch( diamond.color ) {
                case 0 : // D
                case 1 : // E
                case 2 : // F
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -20, -30 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -20, -30 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -20, -25 );
                    }
                case 3 : // G
                case 4 : // H
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -15, -25 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -15, -25 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -15, -20 );
                    }
                case 5 : // I
                case 6 : // J
                case 7 : // K
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -15, -25 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -13, -22 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -10, -20 );
                    }
                case 8 : // L
                case 9 : // M
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -10, -20 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -10, -15 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -7, -10 );
                    }
                case 10 : // N
                case 11 : // O
                case 12 : // P
                case 13 : // Q
                case 14 : // R
                    return value * 0.9;
                case 10 : // S
                case 10 : // T
                case 10 : // U
                case 10 : // V
                case 10 : // W
                case 10 : // X
                case 10 : // Y
                case 10 : // Z
                    return value * 0.85;
            }
        }
    },
    {
        label: 'Strong',
        id: 1,
        transform: ( value, diamond ) => {
            switch( diamond.color ) {
                case 0 : // D
                case 1 : // E
                case 2 : // F
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -10, -20 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -10, -20 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -10, -15 );
                    }
                case 3 : // G
                case 4 : // H
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -10, -18 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -10, -18 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -8, -15 );
                    }
                case 5 : // I
                case 6 : // J
                case 7 : // K
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -8, -15 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -8, -15 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -8, -12 );
                    }
                case 8 : // L
                case 9 : // M
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -5, -12 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -3, -10 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -2, -7 );
                    }
                case 10 : // N
                case 11 : // O
                case 12 : // P
                case 13 : // Q
                case 14 : // R
                    return value * 0.9;
                case 10 : // S
                case 10 : // T
                case 10 : // U
                case 10 : // V
                case 10 : // W
                case 10 : // X
                case 10 : // Y
                case 10 : // Z
                    return value * 0.85;
            }
        }
    },
    {
        label: 'Medium',
        id: 2,
        transform: ( value, diamond ) => {
            switch( diamond.color ) {
                case 0 : // D
                case 1 : // E
                case 2 : // F
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -5, -15 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -5, -12 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -4, -10 );
                    }
                case 3 : // G
                case 4 : // H
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -5, -13 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -5, -13 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -4, -8 );
                    }
                case 5 : // I
                case 6 : // J
                case 7 : // K
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -3, -10 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -3, -10 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -3, -7 );
                    }
                case 8 : // L
                case 9 : // M
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -2, -7 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -2, -7 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( 0, -3 );
                    }
                case 10 : // N
                case 11 : // O
                case 12 : // P
                case 13 : // Q
                case 14 : // R
                    return value * 0.9;
                case 10 : // S
                case 10 : // T
                case 10 : // U
                case 10 : // V
                case 10 : // W
                case 10 : // X
                case 10 : // Y
                case 10 : // Z
                    return value * 0.85;
            }
        }
    },
    {
        label: 'Faint',
        id: 3,
        transform: ( value, diamond ) => {
            switch( diamond.color ) {
                case 0 : // D
                case 1 : // E
                case 2 : // F
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -3, -6 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -3, -6 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -2, -4 );
                    }
                case 3 : // G
                case 4 : // H
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( -2, -4 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( -2, -4 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( -1, -3 );
                    }
                case 5 : // I
                case 6 : // J
                case 7 : // K
                    return value * Diamond.getAverageFluorescenceTransform( 0, -3 );
                case 8 : // L
                case 9 : // M
                    switch( diamond.clarity ) {
                        case 0 : // IF/FL
                        case 1 : // VVS1
                        case 2 : // VVS2
                            return value * Diamond.getAverageFluorescenceTransform( 0, -4 );
                        case 3 : // VS1
                        case 4 : // VS2
                            return value * Diamond.getAverageFluorescenceTransform( 0, -3 );
                        case 5 : // SI1
                        case 6 : // SI2
                        case 7 : // I1
                        case 8 : // I2
                        case 9 : // I3
                            return value * Diamond.getAverageFluorescenceTransform( 0, -2 );
                    }
                case 10 : // N
                case 11 : // O
                case 12 : // P
                case 13 : // Q
                case 14 : // R
                    return value * 0.9;
                case 10 : // S
                case 10 : // T
                case 10 : // U
                case 10 : // V
                case 10 : // W
                case 10 : // X
                case 10 : // Y
                case 10 : // Z
                    return value * 0.85;
            }
        }
    },
    {
        label: 'None',
        id: 4,
        transform: value => value, // No effect
        default: true
    }
];

Diamond.priceInfluencers = [ 'clarity', 'color', 'cutQuality', 'fluorescence', 'shape', 'weight' ];

export default Diamond;
