// Tracks display variables

export default ( state={}, { type, isMobile, isTablet } ) => {
	switch(type) {
		case 'SET_MOBILE' :
			return {
				...state,
				isMobile: Boolean( isMobile ),
				isTablet: Boolean( isTablet )
			};

		default :
			return state;
	}
};