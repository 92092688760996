import React from 'react';
import _ from 'underscore';
import { StickyContainer, Sticky } from 'react-sticky';
import { Link } from 'react-router';
import Dropdown from 'react-dropdown';

import { history } from 'shared/routes';
import { isClient, isMobile, isTablet, isCordova, getDevice } from 'shared/utilities';

class Layout extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            isInitialized: false,
            isReady: false
        };

        this.defaultInitializers = {};

        this.componentDidResize = this.componentDidResize.bind( this );

        if( isClient() ) {
            document.addEventListener( 'deviceready', () => {
                window.open = cordova.InAppBrowser.open || false;
                window.navigator.splashscreen ? window.navigator.splashscreen.hide() : null;
            } );
        }
    }

    // Hash of initializers that, when all are resolved,
    // will set the state of `isInitialized` to `true`
    // and set the resulting value to the corresponding
    // key in the layout state
    getInitializers() {
        return new Object();
    }

    componentDidMount() {
        this.props.dispatch && this.props.dispatch( {
            type: 'SET_MOBILE',
            isMobile: isMobile(),
            isTablet: isTablet()
        } );

        window.addEventListener( 'resize', this.componentDidResize );

        const initializers = {
            ...this.defaultInitializers,
            ...this.getInitializers()
        };

        // Extract all of the initializer promises into an array
        Promise.all( _.values( initializers ) ).then( results =>
            // Store the results of the initializer promises in the layout state
            this.setState(
                {
                    ... _.object( _.keys( initializers ), results ),
                    isInitialized: true,
                    isReady: true
                },
                this.componentDidInitialize
            )
        );
    }

    componentDidInitialize() {
    }

    componentWillUpdate() {
    }

    componentDidUpdate() {
    }

    componentDidResize() {
        this.props.dispatch && this.props.dispatch( {
            type: 'SET_MOBILE',
            isMobile: isMobile(),
            isTablet: isTablet()
        } );
    }

    // What displays after the layout is initialized
    displayLayout() {
        return null;
    }

    // What displays after the layout is initialized if mobile
    displayMobileLayout() {
        return null;
    }

    // Sticky bar under global header that displays state information
    displayLayoutStateBar() {
        return null;
    }

    // What displays before the layout is initialized
    displayLoadingIndicator() {
        return null;
    }

    className() {
        return '';
    }

    render() {
        const globalNavItem = _.findWhere( this.props.globalNavItems, { value: this.props.route.path } );
        const mobileLayout = this.displayMobileLayout();

        const getLayout = layout => {
            if( this.props.wrapperClass == 'diamondCalculator' )
                if( this.props.display.isTablet )
                    return mobileLayout;
                else
                    return layout.displayLayout();
            else if( this.props.display.isMobile && mobileLayout )
                return mobileLayout;
            else
                return layout.displayLayout();
        };

        if( this.state.isInitialized ) {
            return (
                <div className={ `
					layout
					${ this.props.wrapperClass }
					${ this.props.display.isMobile ? 'isMobile' : 'isNotMobile' }
					${ this.props.display.isTablet ? 'isTablet' : 'isNotTablet' }
					${ isCordova() && getDevice() || '' }
					${ this.className() }
				` }>
                    {
                        !this.props.display.isMobile && this.props.showGlobalHeader ? (
                            <header className="globalHeader">
                                <div className="expanded row">
                                    <div className="small-12 columns">
                                        <div className="globalHeader-container">
                                            <div className="globalHeader-left">
                                                <div className="globalHeader-logo">
                                                    <Link to="/">
                                                        <img
                                                            src="/static/images/logo.png"
                                                            alt="Gemguide Logo"
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="globalHeader-right">
                                                {
                                                    this.props.loggedInUser ? (
                                                        <a
                                                            href="/logout"
                                                            children={ this.props.labels.logout }
                                                        />
                                                    ) : (
                                                        <div>
                                                            <a
                                                                href="/login"
                                                                children={ this.props.labels.login }
                                                            />
                                                            <a
                                                                href="/join"
                                                                children={ this.props.labels.join }
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                        ) : (
                            null
                        )
                    }

                    {
                        !this.props.display.isMobile && this.props.globalNavItems && globalNavItem ? (
                            <div className="globalNav">
                                <div className="row expanded">
                                    <div className="small-12 columns">
                                        <Dropdown
                                            ref={ globalNav => this.globalNav = globalNav }
                                            options={ this.props.globalNavItems.filter( item =>
                                                item.value !== this.props.route.path && !item.mobileOnly
                                            ) }
                                            onChange={ selection => {
                                                if( selection.value === 'https://www.gemguide.com/news-archive' ) {
                                                    window.location.href = 'https://www.gemguide.com/news-archive';
                                                } else {
                                                    history.push( selection.value )
                                                }
                                            } }
                                            placeholder={ globalNavItem.label }
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    <StickyContainer>
                        {
                            !this.props.display.isMobile && this.displayLayoutStateBar() ? (
                                <div className="layout-stateBar-wrapper">
                                    <Sticky>
                                        <div className="layout-stateBar">
                                            <div className="row">
                                                <div className="small-12 columns">
                                                    { this.displayLayoutStateBar() }
                                                </div>
                                            </div>
                                        </div>
                                    </Sticky>
                                </div>
                            ) : (
                                null
                            )
                        }

                        {
                            !this.props.display.isMobile && this.constructor.label ? (
                                <div className="layout-label">
                                    <div className="row expanded">
                                        <div
                                            className="small-12 columns"
                                            children={ this.constructor.label }
                                        />
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }

                        <div
                            className="layout-content"
                            children={ getLayout( this ) }
                        />

                        {
                            this.props.display.isMobile && this.props.showMobileNav ? (
                                <nav className="globalNav--mobile">
                                    {
                                        this.props.globalNavItems.map( globalNavItem => {
                                            if( globalNavItem.label === 'News/Archives' ) {
                                                return (
                                                    <a
                                                        className={ `
                                                globalNav-item
                                                fa ${globalNavItem.icon}
                                                ${ globalNavItem.value === this.props.route.path ? 'active' : 'notActive' }
                                            ` }
                                                        title={ globalNavItem.label }
                                                        href={ globalNavItem.value }
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Link
                                                        className={ `
                                                globalNav-item
                                                fa ${globalNavItem.icon}
                                                ${ globalNavItem.value === this.props.route.path ? 'active' : 'notActive' }
                                            ` }
                                                        title={ globalNavItem.label }
                                                        to={ globalNavItem.value }
                                                        key={ globalNavItem.value }
                                                        onClick={ ( e ) => {
                                                            if( globalNavItem.handleReset )
                                                                globalNavItem.handleReset( this, e );
                                                        } }
                                                    />
                                                );
                                            }

                                        } )
                                    }
                                </nav>
                            ) : (
                                null
                            )
                        }
                    </StickyContainer>
                </div>
            );
        } else {
            return this.displayLoadingIndicator();
        }
    }
}

Layout.propTypes = {
    wrapperClass: React.PropTypes.string.isRequired,
    showGlobalHeader: React.PropTypes.bool,
    showMobileNav: React.PropTypes.bool,
    globalNavItems: React.PropTypes.array, // an array of props for a Link element
    labels: React.PropTypes.object
};

Layout.defaultProps = {
    globalNavItems: [
        {
            label: 'Gem Calculator',
            value: '/color',
            icon: 'fa-pie-chart'
        },
        {
            label: 'Diamond Calculator',
            value: '/calculator',
            icon: 'fa-diamond'
        },
        {
            label: 'Pricing Tables',
            value: '/pricing',
            icon: 'fa-table',
            handleReset: ( LayoutComponent, event ) => {
                if( window.location.pathname === '/pricing' ) {
                    let pricingSettings = LayoutComponent.props.pricing;
                    if( LayoutComponent.props.pricing.section !== 'GemListSelector' ) {
                        event.preventDefault();
                        pricingSettings[ 'section' ] = 'GemListSelector';
                        LayoutComponent.props.dispatch( {
                            type: 'SET_PRICING_STATE',
                            props: pricingSettings
                        } );
                    }
                }
            }
        },
        {
            label: 'News/Archives',
            value: 'https://www.gemguide.com/news-archive',
            icon: 'fa-newspaper-o'
        },
        {
            label: 'Settings',
            value: '/settings',
            icon: 'fa-cog'
        }
    ],
    showGlobalHeader: true,
    labels: {
        logout: 'Log Out',
        login: 'Log In',
        join: 'Join'
    },
    showMobileNav: true
};

export default Layout;
