import React from 'react';
import { connect } from 'react-redux';
import { Router, hashHistory, browserHistory } from 'react-router';

import { isServer, isCordova, absoluteUrl, getClientKey, getOAuthVerifier, clearClientKey, clearOAuthVerifier, clearOAuth } from 'shared/utilities';
import Route from 'shared/classes/route'; // Route with custom middleware
import layouts from 'shared/components/layouts';
import User from 'shared/components/User';
import { setClientKey, setOAuthVerifier } from '../utilities';

// This is where the app routes are defined

// If no auth param is specified, the default authentication
// strategy is used.

// If auth is set to false, then requests for this route will not
// require authorization

export const history = isCordova() ? hashHistory : browserHistory;

export default ( loggedInUser ) => {
    Route.defaultProps = { ...Route.defaultProps, loggedInUser };

    return (
        <Router>
            <Route
                path="/"
                components={
                    connect(
                        ( { user, display } ) => ( {
                            display,
                            loggedInUser: user
                        } )
                    )(
                        layouts.Home
                    )
                }
            />
            <Route
                path="/login"
                auth={ false }
                noLoggedIn={ true }
                component={
                    connect(
                        ( { display } ) => ( {
                            display
                        } )
                    )(
                        layouts.Login
                    )
                }
            />
            <Route
                path="/api-login"
                auth={ false }
                onEnter={ 
                    ( nextState, replace, resolve ) => {
                        console.log("[ROUTES][onEnter][INFO] api-login", nextState, replace, resolve)

                        if (!isServer()) {
                            console.log("[ROUTES][onEnter][INFO] api-login - setting client_key cookie", nextState.location.query.client_key)

                            setClientKey(nextState.location.query.client_key)                        
                        }

                        resolve();
                    }
                }
                noLoggedIn={ true }
                component={
                    connect(
                        ( { display } ) => ( {
                            display
                        } )
                    )(
                        layouts.Login
                    )
                }
            />
            <Route
                path="/color"
                component={
                    connect(
                        ( { user, display, gem, gems, colors, pricing } ) => ( {
                            display,
                            loggedInUser: user,
                            gem,
                            gems,
                            colors,
                            pricing
                        } )
                    )(
                        layouts.ColorCalculator
                    )
                }
            />
            <Route
                path="/pricing"
                component={
                    connect(
                        ( { user, display, gem, gems, pricing, diamond } ) => ( {
                            display,
                            loggedInUser: user,
                            gem,
                            gems,
                            pricing,
                            diamond
                        } )
                    )(
                        layouts.Pricing
                    )
                }
            />
            <Route
                path="/calculator"
                component={
                    connect(
                        ( { user, display, diamond, pricing } ) => ( {
                            display,
                            loggedInUser: user,
                            diamond,
                            pricing
                        } )
                    )(
                        layouts.DiamondCalculator
                    )
                }
            />
            <Route
                path="/settings"
                component={
                    connect(
                        ( { user, display, pricing } ) => ( {
                            display,
                            loggedInUser: user,
                            pricing
                        } )
                    )(
                        layouts.Settings
                    )
                }
            />
            <Route
                path="/oauth_auth_endpoint"
                auth={ false }
                onEnter={
                    ( nextState, replace, resolve ) => {
                        if( nextState.location.query.success ) resolve();

                        const oAuthVerifier = nextState.location.query.oauth_verifier || '';
                        
                        if( isServer() ) {
                            const publicToken = nextState.location.query.oauth_token;

                            global.API.OAuth.set_oauth_verifier_for_oauth_token(oAuthVerifier, publicToken);
                            
                            new User().getOAuthAccessToken( { publicToken, oAuthVerifier } )
                                .then( credentials => {
                                    global.OAUTH_AUTH_TOKENS[ oAuthVerifier ] = credentials;

                                    resolve();
                                } );
                        } else {
                            setOAuthVerifier(oAuthVerifier)

                            const xhr = new XMLHttpRequest();

                            xhr.open( 'POST', absoluteUrl( 'login' ), true ); // HERE IT IS
                            xhr.setRequestHeader( `Content-Type`, `application/x-www-form-urlencoded; charset=UTF-8` );
                            xhr.send( `apikey=${oAuthVerifier}` );

                            xhr.onload = () => {
                                const isNotPopup = !window.opener;

                                if( xhr.status === 200 ) {
                                    if( isNotPopup ) {
                                        if( isCordova() ) {
                                            window.location.search = '?success=1';
                                        } else {
                                            window.location.href = '/';
                                        }
                                    } else {
                                        window.opener.location.href = '/';
                                    }
                                } else if( xhr.status === 401 ) {
                                    if( isNotPopup ) {
                                        if( isCordova() ) {
                                            window.location.search = '?success=0';
                                        } else {
                                            window.location.href = absoluteUrl( `/login?unauthorized=true${ xhr.responseText ? `&error_message=${xhr.responseText}` : '' }` );
                                        }
                                    } else {
                                        window.opener.location.search = `?unauthorized=true${ xhr.responseText ? `&error_message=${xhr.responseText}` : '' }`;
                                    }
                                } else {
                                    if( isNotPopup ) {
                                        if( isCordova() ) {
                                            window.location.search = '?success=0';
                                        } else {
                                            window.location.href = absoluteUrl( '/login?server_error=true' );
                                        }
                                    } else {
                                        window.opener.location.search = '?server_error=true';
                                    }
                                }

                                if( !isNotPopup ) {
                                    window.close();
                                }
                            }
                        }
                    }
                }
            />
            <Route
                path="/api_authorize"
                auth={ false }
                component={
                    connect(
                        ( { display } ) => ( {
                            display
                        } )
                    )(
                        layouts.ApiLogin
                    )
                }
                onEnter={ 
                    ( nextState, replace, resolve ) => {
                        console.log("[ROUTES][onEnter][INFO] api_authorize", nextState, replace, resolve)

                        if (!isServer()) {
                            console.log("[ROUTES][onEnter][INFO] api_authorize - setting client_key cookie", nextState.location.query.client_key)

                            setClientKey(nextState.location.query.client_key)                                                
                        }
                        resolve();
                    }
                }

            />
            <Route
                path="/api_oauth_auth_endpoint"
                auth={ false }
                component={
                    connect(
                        ( { display } ) => ( {
                            display
                        } )
                    )(
                        layouts.ApiLogin
                    )
                }
                onEnter={
                    ( nextState, replace, resolve ) => {
                        if( nextState.location.query.success ) resolve();

                        var oauth_verifier = nextState.location.query.oauth_verifier || '';
                        var client_key = nextState.location.query.client_key  || '';

                        if (!isServer()) {  
                            if (!client_key) {
                               var client_key = getClientKey(); 
                            }
                            if (oauth_verifier) {
                                setOAuthVerifier(oauth_verifier);
                            }
                        }

                        console.log("[ApiLoginLayout][onEnter][INFO] Server", isServer(), nextState, client_key, oauth_verifier)

                        if( isServer() ) {
                            const publicToken = nextState.location.query.oauth_token;

                            if (client_key) {
                                global.API.OAuth.set_client_key_for_oauth_token(client_key, publicToken)
                                global.API.OAuth.set_client_key_for_oauth_token(client_key, oauth_verifier)
                            }

                            global.API.OAuth.set_client_key_for_oauth_verifier(client_key, oauth_verifier)

                            console.log("[ApiLoginLayout][onEnter][SERVER][INFO]", nextState, client_key, oauth_verifier, publicToken)

                            global.API.OAuth.set_client_key_for_oauth_token(client_key, publicToken)
                            global.API.OAuth.set_oauth_token_for_oauth_verifier(client_key, publicToken)

                            if( publicToken ) {
                                new User().getOAuthAccessToken( {
                                    publicToken,
                                    oAuthVerifier: oauth_verifier,
                                    isAPI: true
                                } )
                                    .then( credentials => {
                                        global.OAUTH_AUTH_TOKENS[ oauth_verifier ] = credentials;
                                        resolve();
                                    } );
                            } else {
                                resolve();
                            }
                        } else {
                            var client_key = client_key || getClientKey() || '';
                            var oauth_verifier = oauth_verifier || nextState.location.query.oauth_verifier || getOAuthVerifier();

                            console.log("[ApiLoginLayout][onEnter][APP][INFO] client_key", client_key, getClientKey())

                            console.log("[ApiLoginLayout][onEnter][APP][INFO] oauth_verifier", oauth_verifier, getOAuthVerifier())
                            
                            if (client_key) {   
                                setClientKey(client_key);
                            }
                            if (oauth_verifier) {
                                setOAuthVerifier(oauth_verifier)
                            }
                            
                            if( client_key && oauth_verifier ) {
                                const xhr = new XMLHttpRequest();

                                xhr.open( 'POST', absoluteUrl( 'api-login' ), true ); // TODO: HERE IT IS, THE LOGIN URL
                                xhr.setRequestHeader( `Content-Type`, `application/x-www-form-urlencoded; charset=UTF-8` );
                                xhr.send( `apikey=${oauth_verifier}` );

                                xhr.onload = () => {
                                    const isNotPopup = !window.opener;

                                    console.log("[ApiLoginLayout][onEnter][APP][INFO]", nextState, client_key, oauth_verifier)

                                    console.log("[ApiLoginLayout][onEnter][APP][INFO] XHR Starting...", xhr.status, nextState, client_key, oauth_verifier)

                                    new User().getApiCallbackUrl( client_key, oauth_verifier ).then( callback => {
                                        new User().getApiFailureRedirect( client_key ).then( failureRedirect => {
                                            let failRedirect = failureRedirect ? failureRedirect : '/';
                                        
                                            console.log("[ApiLoginLayout][onEnter][APP][INFO] XHR Completed", xhr.status, nextState, client_key, oauth_verifier)

                                            if( xhr.status === 200 ) {
                                                if( isNotPopup ) {
                                                    if( isCordova() ) {
                                                        window.location.search = '?success=1';
                                                    } else {
                                                        window.location.href = callback;
                                                    }
                                                } else {
                                                    window.opener.location.href = callback;
                                                }
                                            } else if( xhr.status === 401 ) {
                                                if( isNotPopup ) {
                                                    if( isCordova() ) {
                                                        window.location.search = '?success=0';
                                                    } else {
                                                        window.location.href = failRedirect;
                                                    }
                                                } else {
                                                    window.opener.location.search = failRedirect;
                                                }
                                            } else {
                                                if( isNotPopup ) {
                                                    if( isCordova() ) {
                                                        window.location.search = '?success=0';
                                                    } else {
                                                        window.location.href = failRedirect;
                                                    }
                                                } else {
                                                    window.opener.location.search = failRedirect;
                                                }
                                            }

                                            if( !isNotPopup ) {
                                                window.close();
                                            }
                                        } );
                                    } );
                                }
                            } else if( client_key ) {
                                console.log("[ApiLoginLayout][onEnter][APP][INFO] oauth_verifier not present", client_key, oauth_verifier)

                                new User().getApiFailureRedirect( client_key ).then( failureRedirect => {
                                    window.location.href = failureRedirect ? failureRedirect : '/';
                                } );
                            } else {
                                console.log("[ApiLoginLayout][onEnter][APP][INFO] client_key and oauth_verifier are not present", nextState, client_key, oauth_verifier)

                                resolve();
                                //window.location.href = absoluteUrl( '/' );
                            }
                        }
                    }
                }
            />
            <Route 
                  path="/admin-login"
                  auth={false}
                  components={
                    connect(
                        ( { user, display } ) => ( {
                            display,
                        } )
                    )(
                        layouts.AdminLogin
                    )
                }
                 />
            <Route
               auth={false}
                path="/admindashboard"
                components={
                    connect(
                        ( {display } ) => ( {
                            display
                        } )
                    )(
                        layouts.AdminDashboard
                    )
                }
            />
            <Route
                path="/*"
                status={ 404 }
                component={ layouts.PageNotFound }
            />
        </Router>
    );
};
