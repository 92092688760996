import Login from 'shared/components/layouts/Login';
import ApiLogin from 'shared/components/layouts/ApiLogin';
import Home from 'shared/components/layouts/Home';
import ColorCalculator from 'shared/components/layouts/ColorCalculator';
import Pricing from 'shared/components/layouts/Pricing';
import DiamondCalculator from 'shared/components/layouts/DiamondCalculator';
import Settings from 'shared/components/layouts/Settings';
import AdminDashboard from "shared/components/layouts/AdminDashboard";
import AdminLogin from 'shared/components/layouts/AdminLogin';

export default {
	Login,
	Home,
	ColorCalculator,
	Pricing,
	DiamondCalculator,
	Settings,
    ApiLogin,
	AdminLogin,
	AdminDashboard,
};
