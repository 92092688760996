import React from 'react';
import LoadingButton from 'react-progress-button';
import {absoluteUrl, getClientKey, setClientKey, clearOAuth} from 'shared/utilities';
import settings from 'shared/settings';
import Layout from 'shared/components/Layout';
import User from 'shared/components/User';
import { getQueryVariable } from "../../utilities";

class LoginLayout extends Layout {
	displayOAuthAuthorizationUI() {
		const api = window.location.pathname === '/api-login'  ? 1 : 0;
		const client_key = getQueryVariable( 'client_key' ) || getClientKey() ||	'';
		const onFailure = () => window.location.search = window.location.search ? `${window.location.search}&failure=1` : `?failure=1`;

		setClientKey(client_key);

		var authCallback = new User().getOAuthAuthorizeUrl( api, client_key );

		if (authCallback) {
			authCallback.then( oAuthAuthorizeUrl => {
				const windowFeatures = `
					height=500,
					width=400,
					location=no
				`;

				const onFailure = () => window.location.search = window.location.search ? `${window.location.search}&failure=1` : `?failure=1`;

				if( oAuthAuthorizeUrl ) {
					window.location.href = oAuthAuthorizeUrl;
				} else {
					clearOAuth();
					onFailure();
				}
			});
		} else {
			console.log('getOAuthAuthorizeUrl returned nil');

			clearOAuth();
			onFailure();
		}
	}

	componentDidMount() {
		super.componentDidMount.apply( this, arguments )

		window.getRememberMe = ( () => this.rememberMe.checked ).bind( this );
	}

	displayLayout() {
		const errorMessageParam = new URL( window.location.href ).searchParams.get('error_message');
		const errorMessage = errorMessageParam || this.props.labels.unauthorizedMessage;

		return (
			<div className="row">
				<div className="small-centered small-12 medium-8 large-6 columns">
					<div className="hide-for-medium">
						<div className="globalHeader-logo">
							<img
								src={ absoluteUrl('/static/images/logo.png') }
								alt="Gemguide Logo"
							/>
						</div>
					</div>

					<div className="login-wrapper">
						{
							this.props.location.query.server_error ? (
								<div
									className="animated fadeInUp login-failureMessage"
									children={ this.props.labels.serverErrorMessage }
								/>
							) : (
								null
							)
						}
						{
							this.props.location.query.unauthorized ? (
								<div
									className="animated fadeInUp login-failureMessage"
									children={ errorMessage }
								/>
							) : (
								null
							)
						}

						<LoadingButton
							ref={ submitButton => this.submitButton = submitButton }
							onClick={ () => this.submitButton.loading() || this.displayOAuthAuthorizationUI() }
							children="Sign In"
						/>

						<div className="small-collapse">
							<div className="small-12 medium-6 medium-push-6 columns text-right">
								{
									settings('FORGOT_PASSWORD_URL') ? (
										<a
											href={settings('FORGOT_PASSWORD_URL')}
											children={this.props.labels.forgotPassword}
										/>
									) : (
										null
									)
								}
								<br/>
								{
									settings('SIGNUP_URL') ? (
										<a
											href={settings('SIGNUP_URL')}
											children={this.props.labels.signup}
										/>
									) : (
										null
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

LoginLayout.defaultProps = {
	...LoginLayout.defaultProps,
	wrapperClass: 'login',
	showMobileNav: false,
	labels: {
		serverErrorMessage: `Something went wrong. Please try again. Contact support if the problem persists.`,
		unauthorizedMessage: `You are not authorized to access this application.`,
		forgotPassword: 'Forgot Password?',
		signup: 'Not a Subscriber?'
	}
};

export default LoginLayout;
