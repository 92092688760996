import { browserHistory } from 'react-router'
import axios from 'axios';
import React from 'react';
import ProgressButton from "react-progress-button";
import Layout from 'shared/components/Layout';
class AdminLogin extends Layout {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null,
      buttonState: "",
      loading:false
  };
  this.handleInputChange = this.handleInputChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange (event){
    const { name, value } = event.target;
    this.setState({
        [name]: value
    });
}

 handleSubmit (event) {
  event.preventDefault();
  const { email, password } = this.state;
  if(!email && !password) {
    this.setState({ error:"Please enter email and password!",loading:false });
    return;
  }
  // Perform the API call
  this.setState({loading:true});
  try {
    axios.post('/admin/login',{
      email,
      password
    }).then((response) => {
      if(response.data.token){
        localStorage.setItem('adminToken',response.data.token);
        setTimeout(()=>{
          this.setState({loading:false});
          browserHistory.push('/admindashboard');
        },3000);
      }
    }).catch((error) => {
      // console.log("The error got from api is:",error.response.data.message);
      this.setState({ error: error.response.data.message,loading: false });
    })
  } catch (error) {
      // Handle other errors, e.g., network errors
      // console.log("the error :",error);
      this.setState({ error: 'An error occurred.',loading: false });
  }
}


  displayLayout() {
    return (
    <div className='admin__login'>
        <div className='login__left'>
         <h2 className='login__heading'>Sign In</h2>
         <h3 className='login__description'>Please sign in to continue</h3>
        <form onSubmit={this.handleSubmit}>
         <input className='login__input' name="email" type="text" placeholder="Enter Username" value={this.state.email} onChange={this.handleInputChange} />
         <input className='login__input' name="password" type="password" placeholder="Enter Password" value={this.state.password} onChange={this.handleInputChange} />
         {this.state.loading ?  <i className="fa fa-solid fa-spinner fa-spin loader"></i> : this.state.error && <div className="error-message">{this.state.error}</div>}
         <div className='login__container'>
         <button className='login__btn' type='submit' >Sign In</button>
         </div>
         </form>
        </div>
        <div className='login__right'>
        <img
            src="/static/images/Banner.jpg"
            alt="Login Image"
            className="login-image"
          />
        </div>
    </div>
    );
  }
}

AdminLogin.defaultProps = {
  wrapperClass: 'adminLogin',
};

export default AdminLogin;
