// Tracks diamond calculator input globally

import _ from 'underscore';
import Diamond from 'shared/components/Diamond';

export default ( state, { type, props, status, price, priceError } ) => {
	let defaultOption;

	const defaultState = {
		weight: 0,
		price: 0,
		isFetchingPrice: false,
		priceError: null,
		shape: ( defaultOption = _.findWhere( Diamond.shapes, { default: true } ) ) && defaultOption.id || Diamond.shapes[0].id,
		color: ( defaultOption = _.findWhere( Diamond.colors, { default: true } ) ) && defaultOption.id || Diamond.colors[0].id,
		clarity: ( defaultOption = _.findWhere( Diamond.clarities, { default: true } ) ) && defaultOption.id || Diamond.clarities[0].id,
		cutQuality: ( defaultOption = _.findWhere( Diamond.cutQualities, { default: true } ) ) && defaultOption.id || Diamond.cutQualities[1].id,
		fluorescence: ( defaultOption = _.findWhere( Diamond.fluorescences, { default: true } ) ) && defaultOption.id || Diamond.fluorescences[0].id
	};

	state = state || defaultState;

	switch(type) {
		case 'RESET_DIAMOND_PROPS' :
			return {
				...state,
				...defaultState
			};

		case 'SET_DIAMOND_PROPS' :
			// Sanitize props
			if( props.weight === '.' ) props.weight = 0;
			if( props.shape ) props.shape = parseInt( props.shape );
			if( props.color ) props.color = parseInt( props.color );
			if( props.clarity ) props.clarity = parseInt( props.clarity );
			if( props.cutQuality ) props.cutQuality = parseInt( props.cutQuality );
			if( props.fluorescence ) props.fluorescence = parseInt( props.fluorescence );

			// If there are multiple decmal points, don't make any change
			if( props.weight && props.weight.match && props.weight.match(/.*\..*\./) ) props.weight = state.weight;

			if( typeof props.weight !== 'undefined' ) props.weight = Number(props.weight);

			return {
				...state,
				...props
			}

		case 'FETCH_DIAMOND_PRICE' :
			switch(status) {
				case 'error' :
					return {
						...state,
						isFetchingPrice: false,
						priceError
					};

				case 'success' :
					return {
						...state,
						isFetchingPrice: false,
						priceError: null,
						price
					};

				default :
					return {
						...state,
						isFetchingPrice: true
					};
			}

		default :
			return state;
	}
};