import _ from 'underscore';
import React from 'react';
import { connect } from 'react-redux';
import Gem from 'shared/components/Gem';
import Panel from 'shared/components/color-calculator-panels/Panel';

class ClarityPanel extends Panel {
	displayPanel() {
		return (
			<div className="clarity-wrapper">
				<div className="panel-group panel-block">
					<div className="inclusions panel-group-field">
						<label>
							<span
								className="label"
								children={this.props.labels.inclusions}
							/>
							<div className="panel-group-input">
								{
									this.props.display.isMobile ? (
										<div
											className="inclusions-choices"
											children={
												Gem.inclusions.map( inclusion => (
													<div
														className={`
															inclusions-selector-choice
															${ this.props.gem.inclusions == inclusion.id ? 'active' : 'notActive' }
														`}
														onClick={
															() => this.props.dispatch({
																type: 'SET_GEM_PROPS',
																props: { inclusions: inclusion.id }
															})
														}
														children={inclusion.label}
													/>
												))
											}
										/>
									) : (
										<select
											ref={ inclusionsSelector => this.inclusionsSelector = inclusionsSelector }
											value={ this.props.gem.inclusions }
											onChange={
												() => this.props.dispatch({
													type: 'SET_GEM_PROPS',
													props: { inclusions: this.inclusionsSelector.value }
												})
											}
											children={
												Gem.inclusions.map( inclusion => (
													<option
														value={inclusion.id}
														children={inclusion.label}
													/>
												))
											}
										/>
									)
								}
							</div>
						</label>
					</div>
					<div className="texture panel-group-field">
						<label>
							<span
								className="label"
								children={this.props.labels.texture}
							/>
							<div className="panel-group-input">
								{
									this.props.display.isMobile ? (
										<div
											className="texture-choices"
											children={
												Gem.textures.map( texture => (
													<div
														className={`
															texture-selector-choice
															${ this.props.gem.texture == texture.id ? 'active' : 'notActive' }
														`}
														onClick={
															() => this.props.dispatch({
																type: 'SET_GEM_PROPS',
																props: { texture: texture.id }
															})
														}
														children={texture.label}
													/>
												))
											}
										/>
									) : (
										<select
											ref={ textureSelector => this.textureSelector = textureSelector }
											value={ this.props.gem.texture }
											onChange={
												() => this.props.dispatch({
													type: 'SET_GEM_PROPS',
													props: { texture: this.textureSelector.value }
												})
											}
											children={
												Gem.textures.map( texture => (
													<option
														value={texture.id}
														children={texture.label}
													/>
												))
											}
										/>
									)
								}
							</div>
						</label>
					</div>
				</div>

				<div className="panel-block">
					<label className="zonings">
						<span
							className="label"
							children={this.props.labels.zoning}
						/>
						<div
							className="button-group"
							children={
								Gem.zonings.map( zoning =>
									<div
										children={zoning.label}
										className={`
											button
											zoning
											${ zoning.id == this.props.gem.zoning ? 'active' : 'notActive' }
										`}
										onClick={
											() => {
												this.props.dispatch({
													type: 'SET_GEM_PROPS',
													props: { zoning: zoning.id }
												})
											}
										}
									/>
								)
							}
						/>
					</label>
				</div>
			</div>
		);
	}
}

ClarityPanel.label = 'Clarity';
ClarityPanel.className = 'clarity';

ClarityPanel.defaultProps = {
	...ClarityPanel.defaultProps,
	labels: {
		inclusions: 'Inclusions',
		texture: 'Texture',
		zoning: 'Color Zoning'
	}
};

ClarityPanel.drawerItems = [
	{
		label: 'Inclusions',
		valueCallback: gem => _.find( Gem.inclusions, inclusion => gem.inclusions == inclusion.id ).label
	},
	{
		label: 'Texture',
		valueCallback: gem => _.find( Gem.textures, texture => gem.texture == texture.id ).label
	},
	{
		label: 'Zoning',
		valueCallback: gem => _.find( Gem.zonings, zoning => gem.zoning == zoning.id ).label
	}
];

export default connect( ({ gem, display }) => ({ gem, display }) )(ClarityPanel);
