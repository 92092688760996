import React from 'react';

class Panel extends React.Component {
	displayPanel() {
		return null;
	}

	render() {
		return this.displayPanel();
	}
}

Panel.label = '';
Panel.className = '';

Panel.propTypes = {
	labels: React.PropTypes.object
};

Panel.defaultProps = {
	labels: {}
}

// Array of objects with a `hue` property of type string and `valueCallback` property of type `function`
Panel.drawerItems = [];

export default Panel;