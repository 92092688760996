import React from 'react';
import ReactDOM from 'react-dom';
import useScroll from 'react-router-scroll';
import { Provider } from 'react-redux';
import { applyRouterMiddleware, Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import Title from 'react-document-title';

import { documentTitle } from 'shared/utilities';
import configureStore from 'shared/configure-store';
import createSharedRoutes from 'shared/routes';

// Hack in support for Function.name for browsers that don't support it.
if ( Function.prototype.name === undefined && Object.defineProperty !== undefined ) {
    Object.defineProperty(Function.prototype, 'name', {
        get: function() {
            var funcNameRegex = /function\s([^(]{1,})\(/;
            var results = (funcNameRegex).exec((this).toString());
            return (results && results.length > 1) ? results[1].trim() : "";
        },
        set: function(value) {}
    });
}

// Create store with the initial state supplied by the server
const store = configureStore( window.BOOTSTRAP_CLIENT_STATE );

// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore( browserHistory, store );

// Configure scroll positions between routes
// You can return:
// = a falsy value to suppress the scroll update
// = a position array such as [0, 100] to scroll to that position
// = a truthy value to get normal scroll behavior
const scrollConfig = useScroll( ( prevProps, nextProps ) => {
	// if staying on the same route, don't update scroll
	if( prevProps && prevProps.routes[1].path == nextProps.routes[1].path )
		return false;

	return true;
} );

// Generate shared routes
const sharedRoutes = createSharedRoutes( store.getState().user );

// Grab the element into which react will be inserted
const rootElement = document.getElementById('root');

// Create a global reference to the root element for event binding
window.ROOT_ELEMENT = rootElement;

// Render React to the DOM as a child of the element #root
ReactDOM.render(
	<Title title={ documentTitle() }>
		<Provider store={store}>
			<Router
				history={history}
				render={applyRouterMiddleware(scrollConfig)}
				routes={sharedRoutes}
			/>
		</Provider>
	</Title>,
	rootElement
);
