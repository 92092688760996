import _ from 'underscore';
import React from 'react';
import { connect } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
import User from 'shared/components/User';

class GemList extends React.Component {

    constructor( props ) {
        super( props );

        this.state = {
            ...this.state,
            searchTerm: ''
        }

        this.handleFormSubmit = event => {
            event.preventDefault();
        };

        this.handleQueryChange = term => {
            this.setState( { searchTerm: term } );
        };

        this.alphabetizedSections = results => {
            let sections = {};
            _.each( results, result => {
                let firstLetter = result.name.charAt(0).toUpperCase();
                if( _.has( sections, firstLetter ) )
                    sections[firstLetter].push( result );
                else {
                    sections[firstLetter] = [];
                    sections[firstLetter].push( result );
                }
            } );

            return sections;
        };

        this.setGem = gem => {
            let pricingSettings = this.props.pricing;
            pricingSettings.section = 'PricingTable';
            pricingSettings.presetActive = false;
            pricingSettings.selection = this.props.pricing.listType === 'Diamonds' ?
                                        { shape: gem.id } : { ...gem };

            this.props.dispatch({
                type: 'SET_PRICING_STATE',
                props: pricingSettings
            });

            if( this.props.pricing.listType === 'Colored Gemstones' ) {
                new User( { id: this.props.userId } ).updateTopGems( gem );
            }

            this.setState( { searchTerm: '' } );
        }


        this.updateTopGems = () => {
            new User( { id: this.props.userId } ).getTopGems().then( topGems => {
                this.setState( { topGems } );
            } );
        };

        if( this.props.pricing.listType === 'Colored Gemstones' )
            this.updateTopGems();


        this.backToGemListSelector = ()=> {
            let pricingSettings = this.props.pricing;
            pricingSettings.section = 'GemListSelector';

            this.props.dispatch( {
                type: 'SET_PRICING_STATE',
                props: pricingSettings,
            } );

            this.setState( { searchTerm: '' } );
        }

    }

    componentWillReceiveProps( nextProps ) {
        if( this.props.pricing.listType === 'Colored Gemstones' )
            this.updateTopGems();
    }

    render() {
        const KEYS_TO_FILTERS = ['name'];
        const filteredGems = this.props.gems.filter( createFilter( this.state.searchTerm, KEYS_TO_FILTERS ) );
        const alphaResults = this.alphabetizedSections( filteredGems );

        let sections = [];

        _.each( alphaResults, ( value, key ) => {
            let sectionResults = [];
            _.each( value , gem => {
                if (gem.name === "Lab Grown") return;
                sectionResults.push(
                    <li className="gemList-section-gems-list-item"
                        key={ gem.id }
                        onClick={ () => this.setGem( gem ) }>
                        <div className="gemList-section-gems-list-item-name">{ gem.name }</div>
                    </li>
                )
            } );
            sections.push(
                <div className="gemList-section" key={ key }>
                    <div className="gemList-section-key">{ key }</div>
                    <div className="gemList-section-gems">
                        <ul className="gemList-section-gems-list">
                            { sectionResults }
                        </ul>
                    </div>
                </div>
            );
        });

        sections = _.sortBy( sections, section => section.key );

        let topGems = null;
        if( this.state.topGems && this.state.topGems.length ) {
            let topGemResults = [];
            _.each( this.state.topGems, ( gem, index ) => {
                topGemResults.push(
                    <li className="gemList-section-gems-list-item"
                        key={ gem.data.id }
                        onClick={ () => this.setGem( gem.data ) }>
                        <div className="gemList-section-gems-list-item-name">{ gem.data.name }</div>
                    </li>
                );
            } );
            topGems = (
                <div className="gemList-section gemList-section--topGems">
                    <div className="gemList-section-key">Top Gems</div>
                    <div className="gemList-section-gems">
                        <ul className="gemList-section-gems-list">
                            { topGemResults }
                        </ul>
                    </div>
                </div>
            );
        }

        return (
            <div className={`gemList gemList--type-${this.props.pricing.listType}
                            ${ this.state.searchTerm || this.props.pricing.listType !== 'Colored Gemstones' ? 'hideTopGems' : '' }`}>
                <div className="gemList-search">
                    <form onSubmit={ this.handleFormSubmit } className="gemList-search-form">
                        <label className="gemList-search-form-label">
                            <SearchInput placeholder="Filter by stone name or alphabet"
                                         className="gemList-search-form-label-input"
                                         value={ this.state.searchTerm }
                                         onChange={ this.handleQueryChange } />
                            <button className="gemList-search-form-label-submit" type="submit">
                                <span className="fa fa-search"></span>
                            </button>
                        </label>
                    </form>
                </div>
                <div className="gemList-results">
                    { !sections.length ? (
                        <div className="gemList-results-noResults">No Results</div>
                    ) : null }
                    <div className="gemList-results-inner">
                        <button className="gemList-results-inner-backBtn" onClick={this.backToGemListSelector}>
                            <span className="fa fa-chevron-left"></span>
                        </button>
                        { topGems }
                        { sections }
                    </div>
                </div>
            </div>
        );
    }
}

GemList.propTypes = {
    gems: React.PropTypes.array
};

GemList.defaultProps = {
    ...GemList.defaultProps,
    wrapperClass: 'login',
    gems: []
};


export default connect()(GemList);
