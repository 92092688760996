import { Route } from 'react-router';
import settings from 'shared/settings';

Route.defaultProps = {
	...Route.defaultProps,
	status: 200,
	scripts: [
		'node_modules/fastclick/lib/fastclick.js'
	],
	// define auth checks for client and server
	onEnter: (nextState, replace, resolve) => {
		const { auth, loggedInUser, noLoggedIn } = nextState.routes[1];

		// If the environment disables user logins
		if( settings('NO_AUTH') ) {
			return resolve();
		}
		
		// Otherwise user logins are enabled
		else {
			// If the request requires that the user is logged in
			if( auth !== false ) {
				// Check if the user is logged in
				if( loggedInUser ) {
					resolve();
				}

				// Otherwise the user must log in
				else {
					replace('/login'); resolve();
				}
			}

			// Otherwise the request is publicly accessible
			else {
				// Check if the user is attempting to access a page marked
				// as not accessible to users while logged in
				if( noLoggedIn && loggedInUser ) {
					replace('/');
				}

				// Otherwise the page can be viewed while logged in
				resolve();
			}
		}
	}
};

export default Route;
