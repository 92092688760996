import _ from 'underscore';
import React from 'react';
import { connect } from 'react-redux';
import Gem from 'shared/components/Gem';
import Panel from 'shared/components/color-calculator-panels/Panel';

class PolishPanel extends Panel {
	displayPanel() {
		return (
			<div className={`${this.constructor.className}-wrapper`}>
				<div className="panel-block">
					<label className="polish">
						<span
							className="label"
							children={this.props.labels.polish}
						/>
						<div
							className="button-group"
							children={
								Gem.polishes.map( polish =>
									<div
										children={polish.label}
										className={`
											button
											square
											polish
											${ polish.id == this.props.gem.polish ? 'active' : 'notActive' }
										`}
										onClick={
											() => {
												this.props.dispatch({
													type: 'SET_GEM_PROPS',
													props: { polish: polish.id }
												})
											}
										}
									/>
								)
							}
						/>
					</label>
				</div>
			</div>
		);
	}
}

PolishPanel.label = 'Polish';
PolishPanel.className = 'polish';

PolishPanel.defaultProps = {
	...PolishPanel.defaultProps,
	labels: {
		polish: 'Polish'
	}
};

PolishPanel.drawerItems = [
	{
		label: 'Polish',
		valueCallback: gem => _.find( Gem.polishes, polish => gem.polish == polish.id ).label
	}
];

export default connect( ({ gem }) => ({ gem }) )(PolishPanel);
